import { FormatIndentDecreaseOutlined } from '@mui/icons-material'
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@mui/material'
import { DialogCloseSaveButtons } from 'components/Common/DialogActionButtons/DialogCloseSaveButtons'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { ProjectController } from 'controllers/Project/ProjectController'
import { useAppController } from 'customHooks/useAppController'
import { useClientStorage } from 'customHooks/useClientStorage'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ShowException } from 'store/Application/appActions'
import { useAppSelector } from 'store/configureStore'
import { BomItemMenuItem } from './BomItemMenuItem'

type Props = {
  bomItemPointer: BomItemPointer
  onClose: () => void
}

export const AssemblyFlattenMenuItem = (props: Props) => {
  const { controller, loading } = useAppController(
    () => new ProjectController()
  )

  const assemblyIsIndividualParts = useAppSelector((state) => {
    return state.project.assemblyHeaders[props.bomItemPointer.id]?.hasGeometry
  })

  const { t } = useTranslation()

  const [confirmOpen, setConfirmOpen] = useState(false)

  if (assemblyIsIndividualParts) {
    return null
  }

  function ConfirmDialog() {
    const [convertToIndividualParts, setConvertToIndividualParts] =
      useClientStorage('flatten-assembly-individual-parts', false)

    const individualPartAssemblyBatchSize = useAppSelector((state) => {
      return (
        Object.values(state.project.assemblyHeaders).find((x) => x.hasGeometry)
          ?.financial?.quantity || 0
      )
    })

    return (
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>
          <Typography variant="h6">
            {t('project:flatten-assembly', 'flatten assembly')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <LocalizedTypography
            translationKey="project:flatten-assembly-dialog-desc"
            variant="body2"
          >
            Are you sure you want to flatten this assembly? This action cannot
            be undone.
          </LocalizedTypography>
          <p style={{ textAlign: 'center' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={convertToIndividualParts}
                  onChange={(e) =>
                    setConvertToIndividualParts(e.target.checked)
                  }
                  color="primary"
                  size="small"
                />
              }
              label={
                <LocalizedTypography
                  translationKey="project:flatten-assembly-individual-parts"
                  variant="body2"
                >
                  move to individual parts
                </LocalizedTypography>
              }
            />
            {
              <Typography
                variant={'body2'}
                color="CaptionText"
                component="p"
                fontStyle="italic"
                style={{ marginTop: '1em' }}
              >
                {convertToIndividualParts &&
                  individualPartAssemblyBatchSize > 1 &&
                  t(
                    'project:flatten-assembly-individual-parts-warning',
                    'when the batch size of individual parts is greater than one, flattening the assembly may cause variations in the quantities of parts.'
                  )}
              </Typography>
            }
          </p>
        </DialogContent>
        <DialogActions>
          <DialogCloseSaveButtons
            onSaveButtonClicked={async () => {
              try {
                await controller.FlattenAssembly({
                  assemblyId: props.bomItemPointer.id,
                  convertToIndividualParts,
                })
                setConfirmOpen(false)
                props.onClose()
              } catch (err) {
                ShowException('Flatten Assembly', err)
              }
            }}
            onCloseButtonClicked={() => {
              setConfirmOpen(false)
              props.onClose()
            }}
            saving={loading['FlattenAssembly']}
          />
        </DialogActions>
      </Dialog>
    )
  }

  async function handleClick() {
    try {
      setConfirmOpen(true)
    } catch (err) {
      ShowException('Flatten Assembly', err)
    }
  }

  return (
    <>
      <BomItemMenuItem
        onClick={handleClick}
        onClose={props.onClose}
        loading={loading['FlattenAssembly']}
        icon={<FormatIndentDecreaseOutlined />}
        primaryTextTranslationKey="project:flatten-assembly"
        secondaryTextTranslationKey="project:flatten-assembly-desc"
        disableIfNotEditable
      />
      <ConfirmDialog />
    </>
  )
}
