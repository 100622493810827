import { RootAppState, useAppSelector } from 'store/configureStore'

export const useOrganizationContext = () => {
  const partyId = useAppSelector(
    (state: RootAppState) => state.user?.organizationContext?.id
  )
  const isBuyingParty = useAppSelector(
    (state: RootAppState) => state.user?.organizationContext?.isBuyingParty
  )

  return {
    partyId,
    isBuyingParty,
  }
}

export const useOrganizationAndProjectContext = () => {
  const partyId =
    useAppSelector(
      (state: RootAppState) => state.user?.organizationContext?.id
    ) || ''

  const isBuyingParty = useAppSelector(
    (state: RootAppState) => state.user?.organizationContext?.isBuyingParty
  )

  const projectId =
    useAppSelector((state: RootAppState) => state.project?.activeProject?.id) ||
    ''

  return {
    partyId,
    projectId,
    isBuyingParty,
  }
}

export const useIsLoading = (networkAction: string) => {
  const { partyId: organizationId, projectId } =
    useOrganizationAndProjectContext()

  return useAppSelector((state: RootAppState) => ({
    isLoading: state.app.currentNetworkAction === networkAction,
    networkLabel: networkAction,
    organizationId,
    projectId,
  }))
}

export const useProjectOperationPending = () => {
  const operationPending = useAppSelector(
    (state: RootAppState) => state.project?.operationPending
  )

  return operationPending
}
