import React, { ElementType } from 'react'
import { AppModule } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppModule } from './useAppModule'

type Props = React.PropsWithChildren<{
  module: AppModule
  /**
   * if true, the children will have the prop disabled set to true if the module is not available
   */
  disabled?: boolean
  /**
   * to help with styling, if the module is not available a fallback component will be rendered instead of the children
   */
  fallbackComponent?: ElementType<unknown, keyof JSX.IntrinsicElements>
}>

export function ShowOnModule(props: Props) {
  const hasModule = useAppModule(props.module)

  if (hasModule) {
    return <>{props.children}</>
  }

  if (!props.disabled && !props.fallbackComponent) {
    return null
  }

  if (props.fallbackComponent) {
    return (
      <>
        {React.Children.map(props.children as React.ReactElement, () =>
          React.createElement(props.fallbackComponent)
        )}
      </>
    )
  }

  return (
    <>
      {React.Children.map(
        props.children as React.ReactElement,
        (child: React.ReactElement) =>
          React.cloneElement(child, { disabled: true })
      )}
    </>
  )
}
