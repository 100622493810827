/* eslint-disable react/prop-types */

/**
 * Component that enables autosave feature of react-final-form
 * based on https://codesandbox.io/s/5w4yrpyo7k
 */

import React, { useState, useEffect, useRef } from 'react'
import { FormSpy, FormSpyRenderProps } from 'react-final-form'
import { isEqual } from 'lodash'
import { FormApi } from 'final-form'
import _ from 'lodash'
import { objectDiff } from 'utils/objectDiff'

type Props<T> = {
  debounce: number
  saveWithErrors?: boolean
  save: (newValues: T, form: FormApi<T>) => PromiseLike<T>
  showSubmitting?: boolean
  saveDiff?: boolean
} & FormSpyRenderProps<T>

function AutoSave<T>(props: Props<T>) {
  const [newValues, setNewValues] = useState(props.values)
  const [submitting, setSubmitting] = useState(false)
  const [savedValues, setSavedValues] = useState(undefined)

  const timeout = useRef<NodeJS.Timeout>(undefined)

  const save = async () => {
    if (submitting || isEqual(savedValues, newValues)) {
      return
    }
    if (!props.saveWithErrors && Object.keys(props.errors)?.length > 0) {
      return
    }

    setSubmitting(true)

    try {
      if (props.saveDiff) {
        const delta = objectDiff(newValues, savedValues)
        Object.keys(delta).forEach((x) => {
          if (!props.dirtyFields[x]) {
            delete delta[x]
          }
        })
        if (_.isEmpty(delta)) {
          setSavedValues(newValues)
          return Promise.resolve()
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await props.save(delta as any, props.form)
      } else {
        await props.save(newValues, props.form)
      }

      setSavedValues(newValues)
    } catch (err) {
      console.error(err)
      setNewValues(savedValues)
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    setSavedValues(props.values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setNewValues(props.values)
  }, [props.values])

  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }

    timeout.current = setTimeout(save, props.debounce)
  })

  if (!props.showSubmitting) return null

  return submitting && <div className="submitting">submitting</div>
}

export function AutoSaveWrapper(props) {
  return (
    <FormSpy
      {...props}
      showSubmitting={props.showSubmitting}
      subscription={{ values: true, dirtyFields: true, errors: true }}
      component={AutoSave}
    />
  )
}
