import { HomeWorkOutlined } from '@mui/icons-material'
import { CircularProgress, IconButton, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const HomePriceButtonOutlined = (props: {
  isManuallySet: boolean
  handleResetToDefaults: () => void
  isLoading: boolean
  translationKey?: string
  /**
   * make it true to not show the reset button (e.g. when the project is not editable)
   */
  hidden: boolean
}) => {
  const { t } = useTranslation()

  if (!props.isManuallySet || props.hidden) {
    return null
  }

  if (props.isLoading) {
    return <CircularProgress size={16} />
  }

  return (
    <Tooltip title={props.translationKey || t('project:reset-to-default')}>
      <IconButton
        onClick={props.handleResetToDefaults}
        color="default"
        size="small"
        disabled={props.hidden}
      >
        <HomeWorkOutlined
          className="reset-button"
          style={{ cursor: 'pointer', width: '20px' }}
        />
      </IconButton>
    </Tooltip>
  )
}
