import { BaseController } from 'controllers/BaseController'
import { OnRequestChangeCallback } from 'services/APIs/BaseAPI'
import { SalesPersonDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { InternalAPI } from 'services/APIs/InternalAPI/InternalAPI'
import { GetCurrentProjectId, GetPartyId } from 'store/storeUtils'

interface ISalesRepAPI {
  GetSalesRepresentants: () => Promise<Array<SalesPersonDto>>
  SetSalesRepresentant: (userId: string) => Promise<void>
}

class SalesRepAPI extends InternalAPI implements ISalesRepAPI {
  constructor(
    partyId: string,
    projectId: string,
    private onRequestChange: OnRequestChangeCallback
  ) {
    super(`/api/parties/${partyId}/projects/${projectId}`)
  }

  public async GetSalesRepresentants() {
    return await this.GetAsync<Array<SalesPersonDto>>({
      id: 'get-sales-person',
      relativePath: '/salesperson',
      onRequestChange: this.onRequestChange,
    })
  }

  public async SetSalesRepresentant(userId: string) {
    return await this.PostAsync<void>({
      id: 'set-sales-person',
      relativePath: '/salesperson',
      data: {
        UserId: userId,
      },
      onRequestChange: this.onRequestChange,
    })
  }
}

export class SalesRepresentativeController extends BaseController<ISalesRepAPI> {
  constructor() {
    const partyId = GetPartyId()
    const projectId = GetCurrentProjectId()

    super((r) => new SalesRepAPI(partyId, projectId, r))
  }

  public async GetSalesRepresentants() {
    try {
      const resp = await this.api.GetSalesRepresentants()
      return resp.sort((a, b) =>
        (a.firstName || '' + a.lastName).trim() <
        (b.firstName || '' + b.lastName).trim()
          ? -1
          : 1
      )
    } catch (ex) {
      throw this.HandleError(ex)
    }
  }

  public async SetSalesRepresentant(userId: string) {
    try {
      return await this.api.SetSalesRepresentant(userId)
    } catch (ex) {
      throw this.HandleError(ex)
    }
  }
}
