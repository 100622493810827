import { Button, Typography } from '@mui/material'

import { SaveButton } from 'components/Common/Button/SaveButton'
import { MoneyPerQuantityInput } from 'components/Common/Input/MoneyPerQuantityInput'
import { QuantityField } from 'components/Common/UnitEditor/QuantityField'
import ValueLabel from 'components/Common/ValueLabel/ValueLabel'
import { useLocalizedWorkingStepLabel } from 'components/Localization/useLocalizedWorkingStepLabel'
import { useArticleIdFilter } from 'features/BillOfMaterials/BoMItemFilter/Components/ArticleIdFilter/useArticleIdFilter'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { MoneyString } from 'model/Money'
import { QuantityString } from 'model/Quantity'
import { TimeString } from 'model/TimeString'
import { useTranslation } from 'react-i18next'
import { PriceSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'
import { useProjectPriceSummaryDetailsStyles } from '../ProjectFinancialDetails.styles'
import { HomePriceButtonOutlined } from './HomePriceButtonOutlined'
import { useProjectPriceSummaryDetails } from './useProjectPriceSummaryDetails'

const getLoadingKey = (operation: string, priceSummary: PriceSummaryDto) => {
  return `${operation}-${priceSummary.workingStep.resource.id}-${priceSummary.workingStep.primaryWorkingStep}-${priceSummary.workingStep.secondaryWorkingStep}`
}

const EditableFields = ({
  changedPriceSummary,
  handleChange,
  handleResetToDefaults,
  isDiscountRatioDisabled,
  isDiscountValueDisabled,
  isSurchargeRatioDisabled,
  isSurchargeValueDisabled,
  validationErrors,
  loading,
  disabled,
}: {
  changedPriceSummary: PriceSummaryDto
  handleChange
  handleResetToDefaults: (
    valueType: 'discount' | 'surcharge',
    priceSummary: PriceSummaryDto
  ) => Promise<void>
  isDiscountRatioDisabled: boolean
  isDiscountValueDisabled: boolean
  isSurchargeRatioDisabled: boolean
  isSurchargeValueDisabled: boolean
  validationErrors: Record<string, string>
  loading: Record<string, boolean>
  disabled?: boolean
}) => {
  const { t } = useTranslation()

  if (!changedPriceSummary) {
    return null
  }

  return (
    <>
      <QuantityField
        value={changedPriceSummary.surchargeRatio}
        name="surchargeRatio"
        disabled={isSurchargeRatioDisabled || disabled}
        error={Boolean(validationErrors['surchargeRatio'])}
        // helperText={validationErrors['surchargeRatio']}
        style={{
          gridArea: 'surchp',
        }}
        label={
          <span>
            {t('common:surcharge')}
            <HomePriceButtonOutlined
              handleResetToDefaults={() =>
                handleResetToDefaults('surcharge', changedPriceSummary)
              }
              isLoading={
                loading[getLoadingKey('delete-surcharge', changedPriceSummary)]
              }
              isManuallySet={changedPriceSummary.isSurchargeManuallySet}
              hidden={disabled}
            />
          </span>
        }
        onChange={(value) => handleChange('surchargeRatio', value)}
        textAlign="right"
      />
      <QuantityField
        value={changedPriceSummary.surchargeValue}
        name="surchargeValue"
        disabled={isSurchargeValueDisabled || disabled}
        error={Boolean(validationErrors['surchargeValue'])}
        // helperText={validationErrors['surchargeValue']}
        style={{
          gridArea: 'surchv',
        }}
        label={
          <span>
            {t('common:surcharge')}
            <HomePriceButtonOutlined
              handleResetToDefaults={() =>
                handleResetToDefaults('surcharge', changedPriceSummary)
              }
              isLoading={
                loading[getLoadingKey('delete-surcharge', changedPriceSummary)]
              }
              isManuallySet={changedPriceSummary.isSurchargeManuallySet}
              hidden={disabled}
            />
          </span>
        }
        onChange={(value) => handleChange('surchargeValue', value)}
        textAlign="right"
      />

      <QuantityField
        value={changedPriceSummary.discountRatio}
        name="discountRatio"
        showMinus
        disabled={isDiscountRatioDisabled || disabled}
        error={Boolean(validationErrors['discountRatio'])}
        style={{
          gridArea: 'discp',
        }}
        // helperText={validationErrors['discountRatio']}
        label={
          <span>
            {t('common:discount')}
            <HomePriceButtonOutlined
              handleResetToDefaults={() =>
                handleResetToDefaults('discount', changedPriceSummary)
              }
              isLoading={
                loading[getLoadingKey('delete-discount', changedPriceSummary)]
              }
              isManuallySet={changedPriceSummary.isDiscountManuallySet}
              hidden={disabled}
            />
          </span>
        }
        onChange={(value) => handleChange('discountRatio', value)}
        textAlign="right"
      />
      <QuantityField
        style={{
          gridArea: 'discv',
        }}
        value={changedPriceSummary.discountValue}
        name="discountValue"
        disabled={isDiscountValueDisabled || disabled}
        allowNegative={false}
        showMinus
        error={Boolean(validationErrors['discountValue'])}
        // helperText={validationErrors['discountValue']}
        label={
          <span>
            {t('common:discount')}
            <HomePriceButtonOutlined
              handleResetToDefaults={() =>
                handleResetToDefaults('discount', changedPriceSummary)
              }
              isLoading={
                loading[getLoadingKey('delete-discount', changedPriceSummary)]
              }
              isManuallySet={changedPriceSummary.isDiscountManuallySet}
              hidden={disabled}
            />
          </span>
        }
        onChange={(value) => handleChange('discountValue', value)}
        textAlign="right"
      />
    </>
  )
}

const ReadOnlyFields = ({
  changedPriceSummary,
}: {
  changedPriceSummary: PriceSummaryDto
}) => {
  const { t } = useTranslation()

  if (!changedPriceSummary) {
    return null
  }

  return (
    <>
      <ValueLabel
        valueVariant="body2"
        value={QuantityString(changedPriceSummary.surchargeRatio)}
        label={t('common:surcharge')}
        valueLabelRootStyles={{
          whiteSpace: 'pre-line',
          gridArea: 'surchp',
        }}
      />
      <ValueLabel
        valueVariant="body2"
        value={MoneyString(changedPriceSummary.surchargeValue)}
        label={t('common:surcharge')}
        valueLabelRootStyles={{
          whiteSpace: 'pre-line',
          gridArea: 'surchv',
        }}
      />

      <ValueLabel
        valueVariant="body2"
        value={QuantityString(changedPriceSummary.discountRatio)}
        label={t('common:discount')}
        valueLabelRootStyles={{
          whiteSpace: 'pre-line',
          gridArea: 'discp',
        }}
      />
      <ValueLabel
        valueVariant="body2"
        value={MoneyString(changedPriceSummary.discountValue)}
        label={t('common:discount')}
        valueLabelRootStyles={{
          whiteSpace: 'pre-line',
          gridArea: 'discv',
        }}
      />
    </>
  )
}

export const ProjectWorkingStepPriceSummary = (props: {
  priceSummary: PriceSummaryDto
}) => {
  const { classes } = useProjectPriceSummaryDetailsStyles()
  const getLocalizedWorkingStep = useLocalizedWorkingStepLabel()
  const { t } = useTranslation()

  const isEditable = useAppSelector(
    projectSelectors.ProjectEditableStateSelector
  )

  const {
    changedPriceSummary,
    handleChange,
    handleSubmit,
    changed,
    handleReset,
    saving,
    handleResetToDefaults,
    validationErrors,
    isDiscountRatioDisabled,
    isDiscountValueDisabled,
    isSurchargeRatioDisabled,
    isSurchargeValueDisabled,
    loading,
  } = useProjectPriceSummaryDetails(props.priceSummary)

  const { articleIdFilter } = useArticleIdFilter()

  if (!props.priceSummary?.isWorkingStep) {
    return null
  }

  if (articleIdFilter.active) {
    return null
  }

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit}
      onReset={handleReset}
    >
      <div
        id={
          props.priceSummary.materialId ||
          props.priceSummary.workingStep?.resource?.id
        }
        className={classes.priceSummaryGrid}
      >
        <div className={classes.priceSummaryDescription}>
          <div className={classes.name}>
            <div>
              <Typography variant="body1" component="div">
                <Typography className={classes.secondaryWorkingStepName}>
                  {`${getLocalizedWorkingStep(
                    props.priceSummary.workingStep.secondaryWorkingStep
                  )}`}
                </Typography>
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.usage}>
          <Typography variant="caption">
            {TimeString(changedPriceSummary.productionTime)}
          </Typography>
        </div>
        <div className={classes.rate}>
          <MoneyPerQuantityInput
            label={t('project:rate-per-time-unit', 'rate per time unit')}
            value={changedPriceSummary.rate}
            name="rate"
            onChange={(e) => handleChange('rate', e.currentTarget.value)}
            defaultUnits={['kg', 'g']}
            disabled={!isEditable}
            alignItems="flex-end"
          />
          <HomePriceButtonOutlined
            handleResetToDefaults={() => handleResetToDefaults('costPrice')}
            isLoading={
              loading[getLoadingKey('delete-cost-price', changedPriceSummary)]
            }
            isManuallySet={changedPriceSummary.isCostPriceManuallySet}
            hidden={!isEditable}
          />
        </div>

        <ValueLabel
          valueVariant="body2"
          value={MoneyString(changedPriceSummary.productionTimeCosts)}
          label={'costs [prod time]'}
          labelTranslationKey={'project:costs-prod'}
          valueLabelRootStyles={{
            whiteSpace: 'pre-line',
            gridArea: 'costProd',
          }}
        />
        <ValueLabel
          valueVariant="body2"
          label={'costs [additional]'}
          labelTranslationKey={'project:costs-additional'}
          value={MoneyString(changedPriceSummary.additionalCosts)}
          valueLabelRootStyles={{
            whiteSpace: 'pre-line',
            gridArea: 'costAdd',
          }}
        />
        <ValueLabel
          valueVariant="body2"
          label={'costs [total]'}
          labelTranslationKey={'project:costs-total'}
          value={MoneyString(changedPriceSummary.totalCostPrice)}
          valueLabelRootStyles={{
            whiteSpace: 'pre-line',
            gridArea: 'cost',
          }}
        />
        {props.priceSummary.canEditDiscountOrSurcharge ? (
          <EditableFields
            changedPriceSummary={changedPriceSummary}
            handleChange={handleChange}
            handleResetToDefaults={handleResetToDefaults}
            validationErrors={validationErrors}
            isDiscountRatioDisabled={isDiscountRatioDisabled}
            isDiscountValueDisabled={isDiscountValueDisabled}
            isSurchargeRatioDisabled={isSurchargeRatioDisabled}
            isSurchargeValueDisabled={isSurchargeValueDisabled}
            loading={loading}
            disabled={!isEditable}
          />
        ) : (
          <ReadOnlyFields changedPriceSummary={changedPriceSummary} />
        )}
        <ValueLabel
          valueVariant="body2"
          label={'sales price'}
          labelTranslationKey={'project:sales-price'}
          value={MoneyString(changedPriceSummary.totalSalesPrice)}
          valueLabelRootStyles={{
            gridArea: 'salesPrice',
          }}
        />
      </div>
      {changed && (
        <div className={classes.actions}>
          <ul>
            {Object.keys(validationErrors).map((errKey) => (
              <li key={errKey}>
                <Typography color="secondary" variant="caption">
                  {validationErrors[errKey]}
                </Typography>
              </li>
            ))}
          </ul>

          <Button
            size="small"
            type="reset"
            variant="outlined"
            color="secondary"
          >
            cancel
          </Button>
          <SaveButton
            disabled={Object.keys(validationErrors).length > 0}
            size="small"
            saving={saving}
          />
        </div>
      )}
    </form>
  )
}
