import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemType } from 'model/Project/BoMItemRow'
import { useMemo } from 'react'

export const useBomItemPointer = (
  bomItemId: string,
  bomItemType: BomItemType
) => {
  return useMemo(
    () =>
      ({
        id: bomItemId,
        type: bomItemType,
      } as BomItemPointer),
    [bomItemId, bomItemType]
  )
}
