import { CloseOutlined, WarningOutlined } from '@mui/icons-material'
import { Theme, Typography } from '@mui/material'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import {
  IssueDto,
  IssueSeverity,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { bomItemIssuesSelector } from '../store/selectors/bomItemIssuesSelector'
import { useBomItemIssueDescription } from './useBomItemIssueDescription'

const useStyles = makeStyles()((theme: Theme) => {
  return {
    warningColor: {
      color: theme.palette.warning.light,
    },
    issuesContainer: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
  }
})

export const IssueSeverityIcon = (props: { severity: IssueSeverity }) => {
  const { classes } = useStyles()

  return props.severity === IssueSeverity.Error ? (
    <CloseOutlined color="error" />
  ) : (
    <WarningOutlined className={classes.warningColor} />
  )
}

const BomItemIssue = (props: { issue: IssueDto }) => {
  const { classes } = useStyles()
  const { getIssueDescription } = useBomItemIssueDescription()

  return (
    <li className={classes.issuesContainer}>
      <IssueSeverityIcon severity={props.issue.severity} />
      <Typography variant="body2">
        {getIssueDescription(props.issue)}
      </Typography>
    </li>
  )
}

export const BomItemIssues = (props: {
  bomItemPointer: BomItemPointer
  ulClassName?: string
  liClassName?: string
}) => {
  const issues = useAppSelector(
    bomItemIssuesSelector(props.bomItemPointer),
    isEqual
  )

  if (!issues?.length) {
    return null
  }

  return (
    <ul className={props.ulClassName}>
      {issues.map((x) => (
        <BomItemIssue key={x.id} issue={x} />
      ))}
    </ul>
  )
}
