import { useLocalizedWorkingStepLabel } from 'components/Localization/useLocalizedWorkingStepLabel'
import { useTranslation } from 'react-i18next'
import {
  IssueCode,
  IssueDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'

export const useBomItemIssueDescription = () => {
  const { t } = useTranslation()
  const getLocalizedWorkingStep = useLocalizedWorkingStepLabel()

  const defaultTranslationFromMetadata = (metadataObj: {
    TranslationKey: string
    TranslationDefaultValue: string
  }) => t(metadataObj.TranslationKey, metadataObj.TranslationDefaultValue)

  const getIssueDescription = (issue: IssueDto): string => {
    switch (issue.issueCode) {
      case IssueCode.WorkingStepNotAvailable: {
        const metadataObj = issue.metadata
          ? JSON.parse(issue.metadata)
          : undefined

        if (!metadataObj) {
          return issue.customDescription || issue.description
        }

        if (metadataObj.NotAllowedWorkingSteps?.length > 0) {
          return t(metadataObj.TranslationKey, {
            defaultValue: metadataObj.TranslationDefaultValue,
            replace: {
              translatedWorkingSteps: metadataObj.NotAllowedWorkingSteps.map(
                (x) => getLocalizedWorkingStep(x)
              ).join(', '),
            },
          })
        } else {
          return defaultTranslationFromMetadata(metadataObj)
        }
      }
      case IssueCode.EstimationNotValid:
        return issue.customDescription || issue.description
      default:
        if (issue.customDescription) {
          return issue.customDescription
        }

        return t(`errors:${issue.issueCode}`, {
          defaultValue: issue.description,
        })
    }
  }

  return { getIssueDescription }
}
