import { Box } from '@mui/material'
import { AddWorkingStepButton } from 'components/ProjectPage/BillOfMaterials/Rows/Components/RowWorkingSteps/WorkingSteps/AddWorkingStepButton'
import { BadgeList } from 'features/BillOfMaterials/BoMItemMenu/MenuItems/BadgeList'
import { BomItemDimensionSummary } from 'features/BillOfMaterials/PartType/PartTypeDimensionSummary'
import { PartTypeFileName } from 'features/BillOfMaterials/PartType/PartTypeFileName'
import { PartTypeMenuButton } from 'features/BillOfMaterials/PartType/PartTypeMenuButton'
import { PurchasingItemSwitcher } from 'features/BillOfMaterials/PartType/PurchasingItemSwitcher'
import { ProjectViewsButtonGroup } from 'features/BillOfMaterials/ProjectViews/ProjectViewsButtonGroup'
import { BomItemRoutingData } from 'features/BillOfMaterials/RoutingHeader/BomItemRoutingData'
import { SolveWorkingStepNotAvailableButton } from 'features/BillOfMaterials/WorkingSteps/SolveNotAvailableWorkingStepButton'
import { BomItemActivities } from 'features/BillOfMaterials/components/BomItemActivities/BomItemActivities'
import { BomItemCuttingPlans } from 'features/BillOfMaterials/components/BomItemCuttingPlans'
import { BomItemIssues } from 'features/BillOfMaterials/components/BomItemIssues'
import { BomItemName } from 'features/BillOfMaterials/components/BomItemName'
import { BomItemPricePerBatch } from 'features/BillOfMaterials/components/BomItemPricePerBatch'
import { BomItemQuantity } from 'features/BillOfMaterials/components/BomItemQuantity'
import { RowThumbnailButton } from 'features/BillOfMaterials/components/BomItemThumbnailButton'
import { BomItemTotalProjectQuantity } from 'features/BillOfMaterials/components/BomItemTotal'
import { BomItemTotalPrice } from 'features/BillOfMaterials/components/BomItemTotalPrice'
import { CommentForm } from 'features/BillOfMaterials/components/CommentForm/CommentForm'
import { useBomItemPricePerBatchStyles } from 'features/BillOfMaterials/components/useBomItemPricePerBatchStyles'
import { BomItemType } from 'model/Project/BoMItemRow'
import { useBomItemDetailsDrawerStyles } from '../BomItemDetailsDrawer.styles'
import { BomItemTargetPriceInput } from '../Inputs/BomItemTargetPriceInput'
import { BomItemPriceContainer } from './BomItemPriceContainer'
import { BomItemDetailsProps } from './ProjectDetails/BomItemDetailsProps'

export const PartTypeDetails = (props: BomItemDetailsProps) => {
  const { classes: sharedClasses } = useBomItemDetailsDrawerStyles()
  const { classes: priceClasses } = useBomItemPricePerBatchStyles({
    fullWidth: false,
  })

  return (
    <>
      <div className={sharedClasses.bomItemDescription}>
        <div style={{ display: 'flex' }}>
          <RowThumbnailButton
            bomItemPointer={props.bomItemPointer}
            size="large"
            pictureClassName={sharedClasses.picture}
          />
          <div className={sharedClasses.description}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: (theme) => theme.spacing(2),
                alignItems: 'center',
                paddingLeft: (theme) => theme.spacing(2),
              }}
            >
              <BomItemName
                bomItemPointer={props.bomItemPointer}
                asLabel
                valueLabelProps={{
                  label: '',
                  valueProps: {
                    variant: 'h6',
                    whiteSpace: 'normal',
                  },
                }}
              />
              <PurchasingItemSwitcher
                bomItemPointer={props.bomItemPointer}
                dialog
              />
              <PartTypeMenuButton bomItemPointer={props.bomItemPointer} />
            </Box>
            <div className={sharedClasses.prices}>
              <>
                <BomItemTargetPriceInput
                  bomItemPointer={props.bomItemPointer}
                  priceScope={'perUnit'}
                  textFieldProps={{
                    className: priceClasses.moneyTextField,
                  }}
                />

                <BomItemQuantity
                  bomItemPointer={props.bomItemPointer}
                  translationKey={
                    props.bomItemPointer.type === BomItemType.partType
                      ? 'common:quantity'
                      : 'project:batch-size'
                  }
                />
                <BomItemTotalProjectQuantity
                  bomItemPointer={props.bomItemPointer}
                />

                <BomItemPricePerBatch
                  bomItemPointer={props.bomItemPointer}
                  valueLabelProps={{
                    isLeftAligned: true,
                  }}
                />

                <BomItemTotalPrice bomItemPointer={props.bomItemPointer} />
              </>
            </div>
          </div>
        </div>
        <Box sx={{ padding: (theme) => theme.spacing(1, 2) }}>
          <CommentForm bomItemPointer={props.bomItemPointer} />
        </Box>
      </div>
      <Box
        sx={{
          display: 'flex',
          padding: (theme) => theme.spacing(1, 2),
          justifyContent: 'space-between',
        }}
      >
        <span>
          <PartTypeFileName bomItemPointer={props.bomItemPointer} />
          <BomItemDimensionSummary bomItemPointer={props.bomItemPointer} />
        </span>
        <BadgeList bomItemPointer={props.bomItemPointer} />
      </Box>

      <div className={sharedClasses.partTypeInfo}>
        <BomItemIssues
          bomItemPointer={props.bomItemPointer}
          ulClassName={sharedClasses.bomItemIssues}
        />
        <div className={sharedClasses.materialKeywords}>
          <BomItemActivities
            bomItemPointer={props.bomItemPointer}
            keywordsOnly
          />
        </div>
        <SolveWorkingStepNotAvailableButton
          bomItemPointer={props.bomItemPointer}
        />

        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            paddingRight: '8px',
          }}
        >
          <div className={sharedClasses.workingSteps}>
            <AddWorkingStepButton bomItemPointers={[props.bomItemPointer]} />
            <BomItemRoutingData
              bomItemPointer={props.bomItemPointer}
              rootClassName={sharedClasses.workingStepsRouteIcons}
            />
          </div>
        </div>
      </div>
      <div className={sharedClasses.priceAndCuttingPlansContainer}>
        <BomItemPriceContainer bomItemPointer={props.bomItemPointer} />
        <BomItemCuttingPlans bomItemPointer={props.bomItemPointer} />
      </div>
      <div className={sharedClasses.bottomBar}>
        <ProjectViewsButtonGroup />
      </div>
    </>
  )
}
