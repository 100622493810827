import React, { ElementType } from 'react'
import { AppModule } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowOnModule } from './ShowOnModule'

export function EnterpriseModule(
  props: React.PropsWithChildren<{
    disabled?: boolean
    fallbackComponent?: ElementType<unknown, keyof JSX.IntrinsicElements>
  }>
) {
  return <ShowOnModule module={AppModule.Enterprise} {...props} />
}
