import { ClickAwayListener, Drawer } from '@mui/material'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useAppDispatch } from 'store/configureStore'
import { BomItemDetail } from './BomItemDetails/BomItemDetail'
import { useBomItemDetailsDrawerStyles } from './BomItemDetailsDrawer.styles'
import { BomItemDetailsDrawerHeader } from './BomItemDetailsDrawerHeader'

export type BomItemDetailsDrawerProps = {
  bomItemPointer: BomItemPointer
  onClose?: () => void
  detailTabIndex?: number
}

const minWidth = 850
const maxWidth = document.body.clientWidth * 0.85

export const BomItemDetailsDrawer = (props: BomItemDetailsDrawerProps) => {
  const { classes: sharedClasses } = useBomItemDetailsDrawerStyles()
  const dispatch = useAppDispatch()

  const [open, setOpen] = useState(false)

  useEffect(() => {
    setTimeout(() => setOpen(true))
  }, [])

  const { onClose } = props

  const handleClose = useCallback(() => {
    if (globalThis.isDirty) {
      return
    }

    setOpen(false)
    dispatch(newProjectActions.setBomItemInSearch(null))
    onClose && onClose()
  }, [dispatch, onClose])

  const [resizing, setResizing] = useState(false)
  // const width = useRef(250)
  const containerRef = useRef<HTMLDivElement>(undefined)

  useEffect(() => {
    const handleResize = (e) => {
      if (resizing) {
        const sizeInPx = document.body.clientWidth - e.clientX

        if (sizeInPx > maxWidth || sizeInPx < minWidth) {
          document.body.style.cursor = 'not-allowed'
        } else {
          document.body.style.cursor = 'ew-resize'
          containerRef.current.style.width = `${sizeInPx}px`
        }
      }
    }

    document.addEventListener('mousemove', handleResize)

    if (!resizing) {
      document.removeEventListener('mousemove', handleResize)
      document.body.style.cursor = 'unset'
    }

    return () => {
      document.removeEventListener('mousemove', handleResize)
    }
  }, [resizing])

  if (props.bomItemPointer === undefined) {
    props.onClose()
    return null
  }

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      variant="persistent"
      anchor="right"
      id="financial-details-drawer"
    >
      <ClickAwayListener onClickAway={() => setResizing(false)}>
        <div
          className={sharedClasses.resizeBorder}
          onMouseDown={(e) => {
            e.preventDefault()
            setResizing(true)
          }}
          onMouseUp={(e) => {
            e.preventDefault()
            setResizing(false)
          }}
          onDoubleClick={(e) => {
            e.preventDefault()
            containerRef.current.style.width =
              containerRef.current.clientWidth > maxWidth * 0.85
                ? `${minWidth}px`
                : `${maxWidth}px`
          }}
        />
      </ClickAwayListener>

      <div
        className={sharedClasses.container}
        ref={containerRef}
        id="bom-item-details-drawer-scroll-container"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <div className={sharedClasses.header}>
          <BomItemDetailsDrawerHeader
            bomItemPointer={props.bomItemPointer}
            handleClose={handleClose}
          />
        </div>
        <BomItemDetail
          bomItemPointer={props.bomItemPointer}
          detailTabIndex={props.detailTabIndex}
        />
      </div>
    </Drawer>
  )
}
