import { ClearOutlined, WarningOutlined } from '@mui/icons-material'
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material'
import SearchTextField, {
  SearchTextFieldRef,
} from 'components/Common/SearchTextBox/SearchTextField'
import { WorkingStepIconSvg } from 'components/Common/WorkingSteps/WorkingStepIcon'
import { useLocalizedWorkingStepLabel } from 'components/Localization/useLocalizedWorkingStepLabel'
import { isEqual } from 'lodash'
import React, {
  KeyboardEvent,
  startTransition,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  WorkingStepDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { makeStyles } from 'tss-react/mui'
import { Key } from 'utils/keyCodes'
import {
  WorkingStepErrorsLookup,
  WorkingStepWithData,
} from '../store/AdditionalWorkingStepsReducer'

type Props = {
  workingSteps: WorkingStepDto[]
  onWorkingStepSelected: (workingStep: WorkingStepDto) => void
  highlightWorkingStep?: WorkingStepType
  onWorkingStepRemoved?: (workingStep: WorkingStepWithData) => void
  workingStepsWithErrors?: Partial<WorkingStepErrorsLookup>
  listTitle?: React.ReactNode
  emptyListMessage?: React.ReactNode
}

const useStyles = makeStyles()((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
  list: {
    width: '100%',
  },
  search: {
    marginBottom: theme.spacing(2),
  },
  highlight: {
    backgroundColor: `${theme.palette.action.hover} !important`,
  },
  focus: {
    backgroundColor: `${theme.palette.action.focus} !important`,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    // '&:hover': {
    //   backgroundColor: theme.palette.action.hover,
    // },
    '&:focus': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  tooltip: {
    backgroundColor: theme.palette.background.paper,
  },
}))

const WorkingStepList = (props: Props) => {
  const getLocalizedWorkingStep = useLocalizedWorkingStepLabel()
  const { classes, cx } = useStyles()

  const [filteredWorkingSteps, setFilteredWorkingSteps] = useState(
    props.workingSteps
  )
  const searchRef = useRef<SearchTextFieldRef>(null)
  const listRef = useRef<HTMLUListElement>(null)

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'ArrowDown') {
        e.preventDefault()
        e.stopPropagation()
        const focusIndex = Number(listRef.current?.dataset.focusIndex || -1)
        let nextFocusIndex = Math.min(
          focusIndex + 1,
          filteredWorkingSteps.length
        )
        if (nextFocusIndex === filteredWorkingSteps.length) {
          nextFocusIndex = 0
        }

        listRef.current?.children[focusIndex]?.classList.remove(classes.focus)

        listRef.current?.children[nextFocusIndex].scrollIntoView({
          block: 'end',
          inline: 'center',
        })

        listRef.current?.children[nextFocusIndex]?.classList.add(classes.focus)

        listRef.current.dataset.focusIndex = nextFocusIndex.toString()
      } else if (e.key === 'ArrowUp') {
        e.preventDefault()
        e.stopPropagation()
        const focusIndex = Number(listRef.current?.dataset.focusIndex || -1)
        let nextFocusIndex = Math.max(focusIndex - 1, -1)

        if (nextFocusIndex === -1) {
          nextFocusIndex = filteredWorkingSteps.length - 1
        }

        listRef.current?.children[focusIndex]?.classList.remove(classes.focus)

        listRef.current?.children[nextFocusIndex].scrollIntoView({
          block: 'center',
          inline: 'center',
        })

        listRef.current?.children[nextFocusIndex]?.classList.add(classes.focus)

        listRef.current.dataset.focusIndex = nextFocusIndex.toString()
      } else if (e.key === Key.Enter) {
        e.preventDefault()
        e.stopPropagation()
        const focusIndex = Number(listRef.current?.dataset.focusIndex || -1)
        if (focusIndex >= 0) {
          if (filteredWorkingSteps[focusIndex]) {
            props.onWorkingStepSelected(filteredWorkingSteps[focusIndex])
            searchRef.current?.clear()
            startTransition(() => setFilteredWorkingSteps(props.workingSteps))
          }
        }
      }
    },
    [classes.focus, filteredWorkingSteps, props]
  )

  useEffect(() => {
    const searchValue = searchRef.current.getValue()

    if (searchValue) {
      const filteredWorkingSteps = props.workingSteps.filter((workingStep) => {
        return getLocalizedWorkingStep(workingStep.primaryWorkingStep)
          ?.toLowerCase()
          .includes(searchValue.trim().toLowerCase())
      })

      if (filteredWorkingSteps.length) {
        setFilteredWorkingSteps(filteredWorkingSteps)
      } else {
        setFilteredWorkingSteps(props.workingSteps)
        searchRef.current.clear()
      }
    } else {
      setFilteredWorkingSteps(props.workingSteps)
    }
  }, [getLocalizedWorkingStep, props.workingSteps])

  const handleFilterChange = useCallback(
    (searchValue: string, reason?: 'EnterKeyPressed') => {
      if (reason === 'EnterKeyPressed') {
        props.onWorkingStepSelected(filteredWorkingSteps[0])
        searchRef.current?.clear()
        startTransition(() => setFilteredWorkingSteps(props.workingSteps))
        return
      }

      startTransition(() => {
        if (!searchValue) {
          setFilteredWorkingSteps(props.workingSteps)
          return
        }

        setFilteredWorkingSteps(
          props.workingSteps.filter((workingStep) => {
            return getLocalizedWorkingStep(workingStep.primaryWorkingStep)
              ?.toLowerCase()
              .includes(searchValue.trim().toLowerCase())
          })
        )
      })
    },
    [filteredWorkingSteps, getLocalizedWorkingStep, props]
  )

  return (
    <div className={classes.root}>
      <Box
        sx={{
          padding: (theme) => theme.spacing(1, 2, 0),
          position: 'sticky',
          top: 0,
          background: (theme) => theme.palette.background.paper,
          zIndex: 2,
        }}
        onKeyDown={handleKeyDown}
      >
        <SearchTextField
          disableTimeout
          onSearch={handleFilterChange}
          fullWidth
          className={classes.search}
          ref={searchRef}
        />
      </Box>
      {props.listTitle}
      {filteredWorkingSteps.length === 0 &&
        (props.emptyListMessage ? props.emptyListMessage : null)}
      <ul
        className={classes.list}
        ref={listRef}
        data-focus-index={filteredWorkingSteps.findIndex(
          (x) => x.primaryWorkingStep === props.highlightWorkingStep
        )}
      >
        {filteredWorkingSteps?.map((workingStep) => {
          return (
            <Button
              component="li"
              key={workingStep.key}
              className={cx(classes.listItem, {
                [classes.highlight]:
                  props.highlightWorkingStep === workingStep.primaryWorkingStep,
              })}
              onClick={() => props.onWorkingStepSelected(workingStep)}
            >
              <Box
                // fullWidth
                // variant="text"
                //
                // disableFocusRipple
                // disableRipple
                // disableTouchRipple
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'left',
                  alignItems: 'center',
                  textTransform: 'lowercase',
                  gap: (theme) => theme.spacing(2),
                }}
              >
                <WorkingStepIconSvg
                  workingStepType={workingStep.primaryWorkingStep}
                  attributes={{
                    width: '54px',
                  }}
                />
                <Typography variant="body1" color="textPrimary">
                  {getLocalizedWorkingStep(workingStep.primaryWorkingStep)}
                </Typography>
              </Box>
              {props.workingStepsWithErrors?.[
                workingStep.primaryWorkingStep
              ] && (
                <Tooltip
                  classes={{
                    tooltip: classes.tooltip,
                  }}
                  title={
                    <ul>
                      {Object.values(
                        props.workingStepsWithErrors[
                          workingStep.primaryWorkingStep
                        ]
                      ).map((error, index) => {
                        return (
                          <Typography key={index} variant="body2" color="error">
                            {error}
                          </Typography>
                        )
                      })}
                    </ul>
                    // <JsonViewer
                    //   data={
                    //     props.workingStepsWithErrors[
                    //       workingStep.primaryWorkingStep
                    //     ]
                    //   }
                    // />
                  }
                >
                  <WarningOutlined color="warning" />
                </Tooltip>
              )}
              {props.onWorkingStepRemoved && (
                <IconButton
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    props.onWorkingStepRemoved(workingStep)
                  }}
                  color="secondary"
                >
                  <ClearOutlined />
                </IconButton>
              )}
            </Button>
          )
        })}
      </ul>
      {/* <JsonViewer data={props.workingStepsWithErrors} /> */}
    </div>
  )
}

export default React.memo(WorkingStepList, isEqual)
