import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemType } from 'model/Project/BoMItemRow'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const bomItemManufacturabilitySelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    const projectId = state.project.activeProject?.id

    if (bomItemPointer.id === projectId) {
      return {
        status: state.project.activeProject.manufacturableStatus,
        result: state.project.activeProject.manufacturableResult,
        isManufacturable: state.project.activeProject.isManufacturable,
      }
    }

    const bomItem = bomItemSelector(state, bomItemPointer)

    switch (bomItem?.type) {
      case BomItemType.assemblyType:
      case BomItemType.assemblyInstance:
      case BomItemType.partType:
      case BomItemType.partInstance: {
        return {
          status: bomItem.manufacturableStatus,
          result: bomItem.manufacturableResult,
          isManufacturable: bomItem.isManufacturable,
        }
      }
    }

    return null
  }
