import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { Debugger } from 'utils/debugger'
import { useOrganizationContext } from '../utils/useOrganizationContext'
// import Spinning from './Common/Spinner/Spinning'

export const OnlyWithOrganization = () => {
  const location = useLocation()

  const { partyId: partyId } = useOrganizationContext()

  if (!partyId) {
    Debugger.Info(
      'routes: OnlyWithOrganization partyId not found, will redirect to workspaces',
      {
        redurectUrl: window.location.pathname,
        partyId,
      }
    )

    return (
      <Navigate
        to="/app/workspaces"
        state={{
          returnUrl: location.pathname + location.search + location.hash,
        }}
        replace
      />
    )
  }

  Debugger.Info('routes: OnlyWithOrganization partyId found', partyId)

  return <Outlet />
}
