import { Localization } from '@material-table/core'
import { useTranslation } from 'react-i18next'

export function useMaterialTableLocalization() {
  const { t } = useTranslation(['common'])

  const localization: Localization = {
    body: {
      filterRow: {
        filterTooltip: t('common:filtrer'),
      },
      editRow: {
        saveTooltip: t('common:save'),
        cancelTooltip: t('common:cancel'),
        deleteText: t(
          'common:confirm-delete-row',
          'are you sure you want to delete this row?'
        ),
      },
      addTooltip: t('common:add'),
      deleteTooltip: t('common:delete'),
      editTooltip: t('common:edit'),
      emptyDataSourceMessage: t('common:no-records', 'no records to display'),
    },
    grouping: {
      groupedBy: t('common:grouped-by', 'grouped by'),
      placeholder: t('common:drag-headers', 'drag headers here to group by'),
    },
    header: {
      actions: t('common:actions'),
    },
    pagination: {
      labelDisplayedRows: t('common:from-to', '{from}-{to} of {count}'),
      labelRowsPerPage: t('common:rows-per-page', 'rows per page'),
      labelRows: t('common:rows'),
      firstAriaLabel: t('common:first-page', 'first page'),
      firstTooltip: t('common:first-page'),
      previousAriaLabel: t('common:previous-page', 'previous page'),
      previousTooltip: t('common:previous-page'),
      nextAriaLabel: t('common:next-page', 'next page'),
      nextTooltip: t('common:next-page'),
      lastAriaLabel: t('common:last-page', 'last page'),
      lastTooltip: t('common:last-page'),
    },
    toolbar: {
      addRemoveColumns: t('common:add-remove-columns', 'add or remove columns'),
      nRowsSelected: t('common:rows-selected', '{0} row(s) selected'),
      showColumnsTitle: t('common:show-columns', 'show colunms'),
      showColumnsAriaLabel: t('common:show-columns'),
      exportTitle: t('common:export'),
      exportAriaLabel: t('common:export'),
      exportCSVName: t('common:export'),
      exportPDFName: t('common:export'),
      searchTooltip: t('common:search'),
      searchPlaceholder: t('common:search'),
    },
  }

  return localization
}
