import {
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import {
  AppModule,
  ManagementPartyDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { makeStyles } from 'tss-react/mui'
import { currencyOptions } from './currencyOptions'

type Props = {
  data: ManagementPartyDto
  onChange: <K extends keyof ManagementPartyDto>(
    key: K,
    value: ManagementPartyDto[K]
  ) => void
  isNew: boolean
  errors?: {
    validation?: {
      id?: string
      name?: string
      coCKey?: string
      currencyCode?: string
      modules?: string
    }
  }
}

const useStyles = makeStyles({ name: 'NewFormParties' })((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  toggles: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

export const NewFormParties = (props: Props) => {
  const { classes } = useStyles()
  return (
    <div className={classes.form}>
      <TextField
        label="party id"
        value={props.data.id}
        onChange={(event) => props.onChange('id', event.target.value)}
        onFocus={(e) => e.target.select()}
        disabled={!props.isNew}
        error={Boolean(props.errors?.validation?.id)}
        helperText={props.errors?.validation?.id}
        required
      />
      <TextField
        label={'name'}
        value={props.data.name}
        onChange={(event) => props.onChange('name', event.target.value)}
        onFocus={(e) => e.target.select()}
        error={Boolean(props.errors?.validation?.name)}
        helperText={props.errors?.validation?.name}
        required
      />
      <TextField
        label={'coc key'}
        value={props.data.coCKey}
        onChange={(event) => props.onChange('coCKey', event.target.value)}
        onFocus={(e) => e.target.select()}
        error={Boolean(props.errors?.validation?.coCKey)}
        helperText={props.errors?.validation?.coCKey}
        required
      />
      <TextField
        label="currency code"
        value={props.data.currencyCode}
        select
        onFocus={(e) => e.target.select()}
        onChange={(event) => props.onChange('currencyCode', event.target.value)}
        required
        error={Boolean(props.errors?.validation?.currencyCode)}
        helperText={props.errors?.validation?.currencyCode}
      >
        {currencyOptions.map((currency) => {
          return (
            <MenuItem key={currency.value} value={currency.value}>
              {currency.label}
            </MenuItem>
          )
        })}
        currencyOptions
      </TextField>
      <div className={classes.toggles}>
        <FormControlLabel
          labelPlacement="top"
          control={
            <Switch
              checked={props.data.hasSubscription}
              onChange={(e, checked) =>
                props.onChange('hasSubscription', checked)
              }
            />
          }
          label={
            <Typography variant="caption" color="textSecondary">
              has subscription
            </Typography>
          }
        />
        <FormControlLabel
          labelPlacement="top"
          control={
            <Switch
              checked={props.data.hasSelfServicePortal}
              onChange={(e, checked) =>
                props.onChange('hasSelfServicePortal', checked)
              }
            />
          }
          label={
            <Typography variant="caption" color="textSecondary">
              has self-service portal
            </Typography>
          }
        />

        <FormControlLabel
          labelPlacement="top"
          control={
            <Switch
              checked={props.data.hasSupportChat}
              onChange={(e, checked) =>
                props.onChange('hasSupportChat', checked)
              }
            />
          }
          label={
            <Typography variant="caption" color="textSecondary">
              has party support chat
            </Typography>
          }
        />
        <FormControlLabel
          labelPlacement="top"
          control={
            <Switch
              checked={props.data.hasSelfServicePortalSupportChat}
              onChange={(e, checked) =>
                props.onChange('hasSelfServicePortalSupportChat', checked)
              }
            />
          }
          label={
            <Typography variant="caption" color="textSecondary">
              has self-service support chat
            </Typography>
          }
        />
      </div>
      <TextField
        select
        label="app module"
        value={props.data.modules?.[0]}
        onChange={(event) => {
          props.onChange('modules', [event.target.value] as AppModule[])
        }}
        required
        error={Boolean(props.errors?.validation?.modules)}
        helperText={props.errors?.validation?.modules}
      >
        <MenuItem value={AppModule.NotInitialized} disabled>
          Not Initialized
        </MenuItem>
        <MenuItem value={AppModule.BoMManager}>Bom Manager</MenuItem>
        <MenuItem value={AppModule.Enterprise}>Enterprise</MenuItem>
      </TextField>
    </div>
  )
}
