import { useTheme } from '@mui/material'
import classNames from 'classnames'
import _, { merge } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import DataGrid, {
  DataGridHandle,
  FillEvent,
  PasteEvent,
  SortColumn,
} from 'react-data-grid'
import 'react-data-grid/lib/styles.css'
import { useTranslation } from 'react-i18next'
import { KeywordDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppDataGridStyles } from '../../../components/ProjectPage/BillOfMaterials/AttachmentManager/useAppDataGridStyles'
import { BomItemUpdateGridModel } from './BomItemUpdateGridModel'
import { useBomItemGridColumns } from './useBomItemGridColumns'

type Comparator = (
  a: BomItemUpdateGridModel,
  b: BomItemUpdateGridModel
) => number

/**
 * The grid editable keys. The keys that can be edited by the user and are shown in the grid.
 */
const gridEditableKeys = [
  'rowNumber',
  'thumbnail3d',
  'name',
  'fileName',
  'drawingNumber',
  'revision',
  'quantity',
  'keywords',
  'comment',
]

function getComparator(sortColumn: string, language: string): Comparator {
  switch (sortColumn) {
    case 'name':
    case 'originalFileName':
    case 'drawingNumber':
    case 'revision':
      return (a, b) =>
        a.name.localeCompare(b.name, language, {
          numeric: true,
          sensitivity: 'base',
        })
    case 'quantity':
    case 'rowNumber':
      return (a, b) => a[sortColumn] - b[sortColumn]
    case 'keywords':
      return (a, b) =>
        Object.values(a[sortColumn])
          .flat()
          ?.join(',')
          ?.localeCompare(
            Object.values(b[sortColumn]).flat()?.join(',') ?? '',
            language,
            {
              numeric: true,
              sensitivity: 'base',
            }
          ) ?? 0
    case 'salesPriceOfItems': {
      return (a, b) => {
        return a.salesPriceOfItems?.value - b.salesPriceOfItems?.value
      }
    }
    default:
      throw new Error(`Unknown sort column: ${sortColumn}`)
  }
}

function handleFill({
  columnKey,
  sourceRow,
  targetRow,
}: FillEvent<BomItemUpdateGridModel>): BomItemUpdateGridModel {
  const allowedColumns = [
    'drawingNumber',
    'revision',
    'quantity',
    'keywords',
    'comment',
  ] as Array<keyof BomItemUpdateGridModel>

  if (allowedColumns.includes(columnKey as keyof BomItemUpdateGridModel)) {
    switch (columnKey) {
      case 'keywords': {
        return mergeQualityAndUserInputedKeywords(sourceRow, targetRow)
      }
      case 'quantity': {
        if (targetRow.enableChangeQuantity) {
          return {
            ...targetRow,
            quantity: sourceRow['quantity'],
          }
        } else {
          return targetRow
        }
      }
      default: {
        return {
          ...targetRow,
          [columnKey]: sourceRow[columnKey],
        }
      }
    }
  } else {
    console.warn(`Column ${columnKey} is not allowed to be filled`)
  }

  return targetRow
}

function mergeQualityAndUserInputedKeywords(
  sourceRow: BomItemUpdateGridModel,
  targetRow: BomItemUpdateGridModel
) {
  const sourceRowWithuotShapeAndDimensions = { ...sourceRow.keywords }
  delete sourceRowWithuotShapeAndDimensions['shape']
  delete sourceRowWithuotShapeAndDimensions['dimensions']

  const newKeywords = merge(
    {},
    targetRow.keywords,
    sourceRowWithuotShapeAndDimensions
  )

  // const sourceQualitykeywords = sourceRow.keywords['quality'] || []
  // const newTargetRowkeywords = {
  //   ...targetRow.keywords,
  //   quality: uniqBy(
  //     [...(targetRow.keywords['quality'] || []), ...sourceQualitykeywords],
  //     (x) => x.originalKeyword
  //   ),
  //   userInputedKeywords: uniqBy(
  //     [
  //       ...(targetRow.keywords['userInputedKeywords'] || []),
  //       ...(sourceRow.keywords['userInputedKeywords'] || []),
  //     ],
  //     (x) => x.originalKeyword
  //   ),
  // }

  return {
    ...targetRow,
    keywords: newKeywords,
  }
}

/**
 * The grid paste handler. It will handle the paste event within the grid.
 * It will paste the data from the source row to the target row, if the columns are the same type.
 * And will call the onPasteHandled callback to inform the document paste event that the paste was handled.
 * @param e react-data-grid event
 * @param onPasteHandled callback to call when the paste event is handled
 * @returns the modified row
 */
function handlePaste(
  e: PasteEvent<BomItemUpdateGridModel>,
  onPasteHandled: () => void
): BomItemUpdateGridModel {
  // paste only between columns with same data type
  if (
    typeof e.sourceRow[e.sourceColumnKey] ===
    typeof (e.targetRow[e.targetColumnKey] || '')
  ) {
    onPasteHandled()
    switch (e.targetColumnKey) {
      case 'keywords': {
        // paste only the quality
        return mergeQualityAndUserInputedKeywords(e.sourceRow, e.targetRow)
      }
      default: {
        return {
          ...e.targetRow,
          [e.targetColumnKey]: e.sourceRow[e.sourceColumnKey],
        }
      }
    }
  } else {
    console.info(
      `${typeof e.sourceRow[e.sourceColumnKey]} !== ${typeof e.targetRow[
        e.targetColumnKey
      ]}, will not paste`
    )
  }

  return e.targetRow
}

function NewFileColumnParser(
  columnKey: keyof BomItemUpdateGridModel,
  value: string
) {
  switch (columnKey) {
    case 'quantity':
      return parseInt(value, 10) || 1
    case 'keywords':
      return {
        userInputedKeywords: value.split(',').map((token) => ({
          originalKeyword: token,
          category: 'userInputedKeywords',
        })),
      }
    case 'comment':
      return value.substring(0, 140)
    default:
      return value
  }
}

const pasteColumns = (
  selectedCellElement: Element,
  sortedRows: Array<BomItemUpdateGridModel>,
  clipboardRows: string[][],
  rowsToUpdate: Array<BomItemUpdateGridModel>
) => {
  if (!selectedCellElement) return

  const selectedColumnIndex = Number(selectedCellElement.ariaColIndex) - 1
  const selectedRowIndex =
    Number(selectedCellElement.parentElement.ariaRowIndex) - 1

  let clipboarRowCounter = 0

  sortedRows.forEach((row, index) => {
    if (index < Number(selectedRowIndex) - 1) {
      rowsToUpdate.push(row)
      return
    }

    if (index > Number(selectedRowIndex) + clipboardRows.length - 1) {
      rowsToUpdate.push(row)
      return
    }

    const newRow = { ...row }

    const clipboardRow = clipboardRows[clipboarRowCounter]

    clipboardRow?.forEach((cell, cellIndex) => {
      const currentKey = gridEditableKeys[
        selectedColumnIndex + cellIndex
      ] as keyof BomItemUpdateGridModel

      switch (currentKey) {
        case 'keywords': {
          newRow.keywords = {
            ...row.keywords,
            ...(NewFileColumnParser(currentKey, cell) as Record<
              string,
              KeywordDto[]
            >),
          }
          break
        }
        case 'quantity': {
          if (newRow.enableChangeQuantity) {
            newRow.quantity = NewFileColumnParser('quantity', cell) as number
          }
          break
        }
        default: {
          newRow[currentKey] = NewFileColumnParser(currentKey, cell) as never
        }
      }
    })

    rowsToUpdate.push(newRow)
    clipboarRowCounter++
  })
}

type BomItemUpdateGridProps = {
  bomItemUpdateModels: BomItemUpdateGridModel[]
  setBomItemUpdateModel: (filesWithDetails: BomItemUpdateGridModel[]) => void
  disabled?: boolean
}

const rowHeight = 45
const keywordsPerRow = 5

const BomItemBatchUpdateGrid = (props: BomItemUpdateGridProps) => {
  const { classes } = useAppDataGridStyles()
  const columnsDefinition = useBomItemGridColumns()
  const theme = useTheme()

  const { i18n } = useTranslation()

  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([])
  const gridRef = useRef<DataGridHandle>(null)
  const currentChangedColumn = useRef<number | undefined>(undefined)
  const currentChangedRowId = useRef<number | string | undefined>(undefined)

  const pasteHandled = useRef(false)

  const sortedRows = useMemo(() => {
    if (sortColumns.length === 0) {
      return props.bomItemUpdateModels
    }

    const sortedRows = [...props.bomItemUpdateModels]

    sortedRows.sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey, i18n.language)
        const compResult = comparator(a, b)

        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult
        }
      }
      return 0
    })

    if (currentChangedRowId.current !== undefined) {
      const currentChangedRowIndex = sortedRows.findIndex(
        (row) => row.bomItemPointer.id === currentChangedRowId.current
      )

      gridRef.current.scrollToCell({
        idx: currentChangedColumn.current,
        rowIdx: currentChangedRowIndex,
      })
      gridRef.current.selectCell(
        { idx: currentChangedColumn.current, rowIdx: currentChangedRowIndex },
        true
      )
      currentChangedRowId.current = undefined
      currentChangedColumn.current = undefined
    }

    return sortedRows
  }, [props.bomItemUpdateModels, sortColumns, i18n.language])

  const parsePaste = (str) =>
    str.split(/\r\n|\n|\r/).map((row) => row.split('\t'))

  useEffect(() => {
    /**
     * Handles the paste event from the Window
     *  - the user should be able to paste a column or multiple columns
     *  from the current selected cells (without any ordering)
     *  - the user should be able to paste just a single cell from Excel to current cell
     *  - if the first column of the pasted data is a string,
     *  it will be considered the row name and the paste will be done respecting the row name
     *  - it will not run if there are a selected cell in the grid,
     * meaning the grid will handle the paste event
     *
     *
     */
    const handleGlobalPaste = (e: ClipboardEvent) => {
      if (pasteHandled.current) {
        // the paste was handled by the grid
        pasteHandled.current = false
        return
      }

      /**
       * ['name | file name',	'drawing number',	'revision'	'quantity'	'quality'	'comment']
       */
      const clipboardRows = parsePaste(e.clipboardData.getData('text/plain'))

      let rowsToUpdate: BomItemUpdateGridModel[] = []

      if (!clipboardRows.length) {
        return
      }

      const selectedCellElement = gridRef.current.element.querySelector(
        '[role="gridcell"][aria-selected="true"]'
      )

      if (!selectedCellElement) {
        return
      }

      if (
        [3, 4].includes(Number(selectedCellElement.ariaColIndex)) && //the column "name" or "fileName" is selected (ariaColIndex are 1-based!!!)
        typeof clipboardRows[0][0] === 'string' && // and first column of first pasted data is a string
        props.bomItemUpdateModels.some(
          (row) =>
            row.name?.toLowerCase() === clipboardRows[0][0].toLowerCase() ||
            row.originalFileName?.toLowerCase() ===
              clipboardRows[0][0].toLowerCase()
        ) // and there is a row with the same name or same originalFileName
      ) {
        // paste from respecting the row filename
        clipboardRows.forEach((cells: string[]) => {
          if (cells[0] === '') return
          /**
           * expecting a clipboard row like:
           * ['name | file name',	'drawing number',	'revision'	'quantity'	'quality'	'comment']
           */

          const rowToUpdate = props.bomItemUpdateModels.find(
            (x) =>
              x.name?.toLowerCase() === cells[0].toLowerCase() ||
              x.originalFileName?.toLowerCase() === cells[0].toLowerCase()
          )

          if (!rowToUpdate) {
            return
          }

          const newRowToUpdate = _.merge({}, rowToUpdate, {
            drawingNumber: cells[1],
            revision: cells[2],
            quantity: rowToUpdate.enableChangeQuantity
              ? Number(cells[3]) || 1
              : rowToUpdate.quantity,
            keywords: cells[4]
              ? {
                  ...rowToUpdate.keywords,
                  userInputedKeywords: cells[4]?.split(',').map((token) => ({
                    originalKeyword: token?.trim(),
                    category: 'userInputedKeywords',
                  })),
                }
              : rowToUpdate.keywords,
            comment: cells[5]?.substring(0, 140),
          })

          rowsToUpdate.push(newRowToUpdate)
        })

        //include other rows
        rowsToUpdate = sortedRows.map((originalRow) =>
          Object.assign(
            originalRow,
            rowsToUpdate.find(
              (x) => x.bomItemPointer.id === originalRow.bomItemPointer.id
            )
          )
        )
      } else {
        pasteColumns(
          selectedCellElement,
          sortedRows,
          clipboardRows,
          rowsToUpdate
        )
      }

      if (rowsToUpdate.length) {
        props.setBomItemUpdateModel(rowsToUpdate)
      }
    }

    if (!props.disabled) {
      document.addEventListener('paste', handleGlobalPaste)
    } else {
      document.removeEventListener('paste', handleGlobalPaste)
    }

    return () => {
      document.removeEventListener('paste', handleGlobalPaste)
    }
  }, [props, sortedRows])

  return (
    <DataGrid
      className={classNames(classes.root, {
        ['rdg-light']: theme.palette.mode === 'light',
        ['rdg-dark']: theme.palette.mode === 'dark',
      })}
      columns={columnsDefinition}
      rows={sortedRows}
      style={{
        height: '100%',
      }}
      ref={gridRef}
      rowHeight={(row) => {
        const keywordsCounter = Object.values(row.keywords).flat().length

        return Math.max(
          rowHeight * Math.ceil(keywordsCounter / keywordsPerRow),
          rowHeight
        )
      }}
      rowKeyGetter={(row) => row?.bomItemPointer.id}
      onRowsChange={(rows, data) => {
        currentChangedRowId.current = rows[data.indexes[0]]?.bomItemPointer.id
        currentChangedColumn.current = data.column.idx
        props.setBomItemUpdateModel(rows)
      }}
      defaultColumnOptions={{
        sortable: true,
        resizable: true,
      }}
      sortColumns={sortColumns}
      onSortColumnsChange={setSortColumns}
      onPaste={(e) => handlePaste(e, () => (pasteHandled.current = true))}
      onFill={handleFill}
    />
  )
}

export default BomItemBatchUpdateGrid //React.memo(BomItemBatchUpdateGrid, isEqual)
