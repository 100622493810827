import { useAppModule } from 'features/AppModules/useAppModule'
import { useBomItemDetails } from 'features/BillOfMaterials/components/useBomItemDetails'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { BomItemType } from 'model/Project/BoMItemRow'
import { useTranslation } from 'react-i18next'
import { AppModule } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { CostFactor } from '../GetGraphData'
import { CostFactorSection, Legend } from './CostFactorSection'

export const CostFactorPartItem = (props: {
  costFactor: CostFactor
  legend: Legend
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const enterpriseModule = useAppModule(AppModule.Enterprise)
  const { handleOpenBomItemDetails } = useBomItemDetails()

  const handleClick = () => {
    if (props.costFactor.partTypePointer.type !== BomItemType.partInstance) {
      dispatch(
        newProjectActions.setBomItemInSearch(props.costFactor.partTypePointer)
      )
    }
  }

  const openDetails: React.MouseEventHandler = () => {
    if (
      enterpriseModule &&
      props.costFactor.partTypePointer.type !== BomItemType.partInstance
    ) {
      handleOpenBomItemDetails(props.costFactor.partTypePointer, true)
    }
  }

  const bomItemName = useAppSelector((state) => {
    if (props.costFactor.partTypePointer?.id) {
      return projectSelectors.bomItemNameSelector(
        props.costFactor.partTypePointer
      )(state)
    }

    return null
  })

  let title = ''

  if (bomItemName) {
    title = bomItemName
  } else {
    title =
      props.costFactor.partTypePointer?.type === BomItemType.project
        ? t('project:assemblies')
        : t('project:parts')
  }

  return (
    <div onClick={handleClick} onDoubleClick={openDetails}>
      <CostFactorSection
        costFactor={props.costFactor}
        title={title}
        legend={props.legend}
      />
    </div>
  )
}
