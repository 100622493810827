import { ListItem, ListItemText, Theme, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { PartySearchResult } from '../../../services/APIs/InternalAPI/internal-api.contracts'
import { markText } from '../../../utils/textFilterUtils'
import { HTMLAttributes } from 'react'

const useStyles = makeStyles()((theme: Theme) => ({
  resultRoot: {
    '& mark': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
    },
  },
}))

export function FindPartyResult(props: {
  baseElProps: HTMLAttributes<HTMLLIElement>
  option: PartySearchResult
  searchText: string
}) {
  const { classes } = useStyles()

  return (
    <ListItem
      {...props.baseElProps}
      style={{ ...props.baseElProps.style, padding: '1rem 1.5rem' }}
      sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
    >
      <ListItemText
        primary={props.option.names.map((name, index) => (
          <div className={classes.resultRoot} key={index}>
            <Typography variant="body1" fontWeight={'bold'}>
              {markText(name, props.searchText)}
            </Typography>
            {markText(
              "[" + props.option.code + "]",
              props.searchText
            )}
          </div>
        ))}
        secondary={
          <span className={classes.resultRoot}>
            {markText(
              props.option.addresses[props.option.addresses.length - 1],
              props.searchText
            )}
          </span>
        }
      />
    </ListItem>
  )
}
