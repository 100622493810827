import { Theme } from '@mui/material'
import FreeRotationIcon from '_icons/nesting/rotate_free-sm.svg?react'
import XRotationIcon from '_icons/nesting/rotate_x-sm.svg?react'
import YRotationIcon from '_icons/nesting/rotate_y-sm.svg?react'
import { NestingDirection } from 'services/APIs/InternalAPI/internal-api.contracts'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  nestingIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}))

export const NestingIcon = ({
  nestingDirection,
}: {
  nestingDirection: NestingDirection
}) => {
  const { classes } = useStyles()

  switch (nestingDirection) {
    case NestingDirection.Free:
      return <FreeRotationIcon className={classes.nestingIcon} />
    case NestingDirection.X:
      return <XRotationIcon className={classes.nestingIcon} />
    case NestingDirection.Y:
      return <YRotationIcon className={classes.nestingIcon} />
    default:
      return null
  }
}
