import i18next from 'i18next'

/**
 * Get the date format pattern for the given locale.
 * @example
 *     getDateFormatPattern('en-AU');   // dd/mm/yyyy
 *     getDateFormatPattern('en-US');   // m/d/yyyy
 */
const getDateFormatPattern = (locale: string) => {
  const getPatternForPart = (part: Intl.DateTimeFormatPart) => {
    switch (part.type) {
      case 'day':
        return 'D'.repeat(2)
      case 'month':
        return 'M'.repeat(2)
      case 'year':
        return 'Y'.repeat(part.value.length)
      case 'literal':
        return part.value
      default:
        return ''
    }
  }

  return new Intl.DateTimeFormat(locale)
    .formatToParts(new Date('2021-12-31'))
    .map(getPatternForPart)
    .join('')
}

export const getDateFormatString = () => {
  return getDateFormatPattern(i18next.language)
}
