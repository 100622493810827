import NavBar from 'components/Common/NavigationBar/NavBar'
import { UserFeedbackForm } from 'components/UserFeedback/UserFeedback'
import { CreateProjectDialog } from 'features/CreateProject/CreateProjectDialog'
import { DialogManager } from 'features/DialogManager/DialogManager'
import { Suspense } from 'react'
import { AppRoutes } from './Routes'

export const AppCore = () => {
  return (
    <div className="app">
      <NavBar />
      <main className="main-app">
        <AppRoutes />
      </main>
      <UserFeedbackForm />
      <CreateProjectDialog />
      <Suspense>
        <DialogManager />
      </Suspense>
    </div>
  )
}

export default AppCore
