import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'

import { TOptions } from 'i18next'
import { Accept } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import FileUploader from '.'
import { DialogCloseSaveButtons } from '../DialogActionButtons/DialogCloseSaveButtons'
import { useFileUploader } from './useFileUploader'

type Props = {
  isActive: boolean
  allowedExtensions: Accept
  allowedExtensionsLabel?: string[]
  setIsActive: (active: boolean) => void
  filePostUrl: string
  networkLabel: string
  onFileUploadSucceed: (data?: unknown) => void
  onFileUploadFail: (message) => void
  titleTOptions?: { translationKey: string; options?: TOptions }
  successMessageTitleTKey?: string
  successMessageTKey?: string
  explanationText?: string | (() => string)
  onlyOneFile?: boolean
}

export const FileUploadDialog = (props: Props) => {
  const { t } = useTranslation()

  const { files, setFiles, progress, postFiles, cleanFiles } = useFileUploader(
    props.filePostUrl,
    props.networkLabel,
    props.onFileUploadSucceed,
    props.onFileUploadFail,
    props.successMessageTitleTKey,
    props.successMessageTKey
  )

  return (
    <Dialog
      open={props.isActive}
      onClose={() => props.setIsActive(false)}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        {t(
          props.titleTOptions?.translationKey ?? 'common:import-project',
          props.titleTOptions?.options ?? { defaultValue: 'import project' }
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText component="span">
          {props.explanationText && (
            <Typography color="textPrimary" variant="subtitle1">
              {typeof props.explanationText === 'function'
                ? props.explanationText()
                : props.explanationText}
            </Typography>
          )}
          {t('common:allowed-extensions', {
            defaultValue: 'allowed extensions: {{extensions}}',
            replace: {
              extensions: props.allowedExtensionsLabel
                ? props.allowedExtensionsLabel.join(', ')
                : Object.values(props.allowedExtensions).join(', '),
            },
          })}
        </DialogContentText>
        <FileUploader
          acceptedExtensions={props.allowedExtensions}
          onFileUploaded={postFiles}
          uploadProgress={progress}
          setFiles={setFiles}
          files={files}
          onlyOneFile={props.onlyOneFile}
        />
      </DialogContent>
      <DialogActions>
        <DialogCloseSaveButtons
          onCloseButtonClicked={() => {
            cleanFiles()
            props.setIsActive(false)
          }}
          onSaveButtonClicked={() => {
            postFiles()
          }}
          progress={progress}
          saveButtonDisabled={files?.length === 0}
          saveButtonTranslationKey="common:import-files"
        />
      </DialogActions>
    </Dialog>
  )
}
