import { ListItemAvatar, ListItemText, MenuItem } from '@mui/material'
import { WorkingStepIconSvg } from 'components/Common/WorkingSteps/WorkingStepIcon'
import { saveBomItemIsPurchasingPart } from 'features/BillOfMaterials/store/asyncActions/saveBomItemIsPurchasingPart'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import { PurchasingPartDialogProps } from 'features/PurchasingPartDetails/PurchasingPartDialog'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useTranslation } from 'react-i18next'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppDispatch, useAppSelector } from 'store/configureStore'

export const PurchasingPartMenuItem = ({
  bomItemPointer,
  variant,
  onClose,
}: {
  bomItemPointer?: BomItemPointer
  variant: 'toggle' | 'modal'
  onClose?: () => void
}) => {
  const isPurchasingPart = useAppSelector((state) => {
    if (!bomItemPointer) {
      return projectSelectors.bomItemIsPurchasingPartSelector(
        state.project?.selectedBomItemPointers[0]
      )(state)
    }
    return projectSelectors.bomItemIsPurchasingPartSelector(bomItemPointer)(
      state
    )
  })

  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { openDialog } = useDialogManager()

  const isBuyingParty = useAppSelector(
    projectSelectors.projectInBuyerViewSelector
  )

  const isEditable = useAppSelector(
    projectSelectors.ProjectEditableStateSelector
  )

  if (isBuyingParty || !isEditable) {
    return null
  }

  const openPurchasingPartDialog = () => {
    onClose && onClose()

    if (isPurchasingPart) {
      dispatch(saveBomItemIsPurchasingPart({ bomItemPointer: bomItemPointer }))
    } else {
      openDialog('PurchasingPartDialog', {
        bomItemPointer,
        isOpen: true,
      } as PurchasingPartDialogProps)
    }
  }

  const handleTogglePurchasingPart = () => {
    dispatch(saveBomItemIsPurchasingPart({ bomItemPointer: bomItemPointer }))
    onClose && onClose()
  }

  const handleClick = () => {
    if (variant === 'toggle') {
      handleTogglePurchasingPart()
    }
    if (variant === 'modal') {
      openPurchasingPartDialog()
    }
  }

  const PrimaryComponent = () => {
    if (!bomItemPointer) {
      return (
        <>
          {t(
            'project:purchasing-part-set-unset',
            'set or unset as purchasing part'
          )}
        </>
      )
    }

    if (isPurchasingPart) {
      // if (bomItemPointer) {
      //   return (
      //     <BomItemPricePerBatch bomItemPointer={bomItemPointer} fullWidth />
      //   )
      // } else {
      return (
        <>{t('project:purchasing-part-unset', 'unset as purchasing part')}</>
      )
      // }
    } else {
      return <>{t('project:purchasing-part-set', 'set as purchasing part')}</>
    }
  }

  return (
    <MenuItem onClick={handleClick}>
      <ListItemAvatar>
        <WorkingStepIconSvg
          workingStepType={WorkingStepType.Purchasing}
          attributes={{
            width: '2rem',
          }}
        />
      </ListItemAvatar>
      <ListItemText
        primary={<PrimaryComponent />}
        secondary={t(
          'project:purchasing-part-set-desc',
          'manual set price/batch'
        )}
      />
    </MenuItem>
  )
}
