import { ListItemText, Theme } from '@mui/material'
import { withStyles } from 'tss-react/mui';

export const TargetPriceTooltipListItemText = withStyles(ListItemText, (theme: Theme) => ({
  secondary: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.typography.body2.color,
  },
  primary: {
    fontSize: theme.typography.caption.fontSize,
  },
}));
