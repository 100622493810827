/* eslint-disable @typescript-eslint/no-explicit-any */
import { Column } from '@material-table/core'
import { Typography } from '@mui/material'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'
import { ExpirationDateProgressVariant } from 'features/BillOfMaterials/QuotationExpirationDate/ExpirationDateProgressVariant'
import { ContactInfoLabel } from 'features/ProjectDetailsInfo/components/ContactInfoLabel'
import { TFunction } from 'i18next'
import { matchSorter } from 'match-sorter'
import { MoneyString } from 'model/Money'
import moment from 'moment'
import { ProjectSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { CellTypography } from './ProjectListPage'

const statesToRenderExpirationDate: ProjectStateAsString[] = [
  ProjectStateAsString.CANCELLED,
  ProjectStateAsString.DEFINING,
  ProjectStateAsString.REQUESTED,
  ProjectStateAsString.QUOTING,
  ProjectStateAsString.QUOTED,
  ProjectStateAsString.NEGOTIATING,
]

const lastOperation: (t: TFunction) => Column<ProjectSummaryDto> = (
  t: TFunction
) => ({
  title: (
    <Typography variant={'inherit'} noWrap>
      {t('common:last-modified')}
    </Typography>
  ),
  field: 'lastOperation',
  grouping: true,
  type: 'date',
  width: '5%',
  groupRender: (groupKey) => {
    return (
      <span style={{ display: 'inline-flex' }}>
        {moment(groupKey, 'YYYY-MM-DD').format('L')}
      </span>
    )
  },
  defaultGroupSort: 'desc',
  defaultSort: 'desc',
  sorting: true,
  headerStyle: { maxWidth: '150px' },
  render: (row) => {
    return (
      <CellTypography>
        {moment(row.lastOperation, 'YYYY-MM-DD').format('L')}
      </CellTypography>
    )
  },
})

const customer: (t: TFunction) => Column<ProjectSummaryDto> = (
  t: TFunction
) => ({
  title: t('common:customer'),
  field: 'buyingPartyName',
  render: (rowData) => {
    return (
      <CellTypography
        typographyProps={{
          style: {
            textAlign: 'left',
            wordWrap: 'break-word',
            maxWidth: '140px',
          },
        }}
      >
        {rowData.buyingPartyName}
      </CellTypography>
    )
  },
  width: '15%',
})

const status: (t: TFunction) => Column<ProjectSummaryDto> = (t: TFunction) => ({
  title: t('common:status'),
  field: 'status',
  width: '7%',
  groupRender: (groupKey) => {
    return (
      <LocalizedTypography
        component="span"
        translationKey={`project:${groupKey}`}
        variant="body2"
        noWrap
      />
    )
  },
  grouping: true,
  render: (rowData) => (
    <LocalizedTypography
      component="span"
      variant="body2"
      translationKey={`project:${rowData.status}`}
      noWrap
    />
  ),
})

const order: (t: TFunction) => Column<ProjectSummaryDto> = (t: TFunction) => ({
  title: t('project:order'),
  field: 'orderNumber',
  grouping: false,
  cellStyle: { textAlign: 'left' },
  width: '10%',
  render: (rowData) => {
    return <CellTypography>{rowData.orderNumber}</CellTypography>
  },
})

const projectReference: (t: TFunction) => Column<ProjectSummaryDto> = (
  t: TFunction
) => ({
  title: t('project:project-reference'),
  field: 'projectReference',
  grouping: false,
  width: '20%',

  // headerStyle: { width: '30%' },
  render: (rowData) => {
    return <CellTypography>{rowData.projectReference}</CellTypography>
  },
})

const salesPrice: (t: TFunction) => Column<ProjectSummaryDto> = (
  t: TFunction
) => ({
  title: (
    <Typography variant="inherit" noWrap>
      {t('project:net-sales-price', 'net sales price')}
    </Typography>
  ),
  field: 'salesPriceOfItems',
  render: (rowData) => {
    return (
      <CellTypography
        typographyProps={{
          style: {
            textAlign: 'right',
          },
        }}
      >
        {MoneyString(rowData.salesPriceOfItems)}
      </CellTypography>
    )
  },
  grouping: false,
  cellStyle: { textAlign: 'right' },
  headerStyle: {
    textAlign: 'right',
  },
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const numOfParts: (t: TFunction) => Column<ProjectSummaryDto> = (
  t: TFunction
) => ({
  title: (
    <Typography noWrap variant="inherit">
      {t('common:num-parts', '# parts')}
    </Typography>
  ),
  field: 'numberOfRows',
  grouping: false,
  width: '3%',
  cellStyle: { textAlign: 'center' },
  headerStyle: { textAlign: 'center' },
  render: (rowData) => {
    return <CellTypography>{rowData.numberOfRows}</CellTypography>
  },
})

const createdBy: (t: TFunction) => Column<ProjectSummaryDto> = (
  t: TFunction
) => ({
  title: t('common:created-by', 'created by'),
  field: 'creator.email',
  width: '10%',
  customFilterAndSearch: (term, rowData) => {
    return Boolean(
      matchSorter(
        [
          `${rowData.creator?.firstName}`,
          `${rowData.creator?.lastName}`,
          `${rowData.creator?.email}`,
        ],
        term
      ).length
    )
  },
  groupRender: (groupKey) => {
    return <>{groupKey}</>
  },
  grouping: true,
  headerStyle: { textAlign: 'left', maxWidth: '100px' },
  cellStyle: { textAlign: 'left', maxWidth: '100px' },
  render: (row) => {
    return <ContactInfoLabel personInfo={row.creator} disableEmail />
  },
})

const supplier: (t: TFunction) => Column<ProjectSummaryDto> = (
  t: TFunction
) => ({
  title: t('common:supplier'),
  field: 'sellingPartyName',
  width: '10%',
  headerStyle: { textAlign: 'left' },
  cellStyle: { textAlign: 'left' },

  render: (rowData) => {
    return <CellTypography>{rowData.sellingPartyName}</CellTypography>
  },
  // // headerStyle: { width: '15%' },
})

const salesPerson: (t: TFunction) => Column<ProjectSummaryDto> = (
  t: TFunction
) => ({
  title: (
    <Typography variant="inherit">
      {t('common:sales-representative', 'sales representative')}
    </Typography>
  ),
  field: 'salesPerson.email',
  // defaultFilter: props.onlyMyProjects && user.email,
  width: '10%',
  customFilterAndSearch: (term, rowData) => {
    return Boolean(
      matchSorter(
        [
          `${rowData.creator?.firstName}`,
          `${rowData.creator?.lastName}`,
          `${rowData.creator?.email}`,
        ],
        term
      ).length
    )
  },
  groupRender: (groupKey) => {
    return <>{groupKey}</>
  },
  grouping: true,
  headerStyle: { textAlign: 'left' },
  cellStyle: { textAlign: 'left' },

  render: (row) => {
    return <ContactInfoLabel personInfo={row.salesPerson} disableEmail />
  },
})

const contactPerson: (t: TFunction) => Column<ProjectSummaryDto> = (
  t: TFunction
) => ({
  title: (
    <Typography variant="inherit" noWrap>
      {t('common:contact-person')}
    </Typography>
  ),
  field: 'contactPerson.email',
  width: '10%',
  headerStyle: { textAlign: 'left' },
  cellStyle: { textAlign: 'left' },

  render: (rowData) => {
    return <ContactInfoLabel personInfo={rowData.contactPerson} disableEmail />
  },
})

const expirationDate: (t: TFunction) => Column<ProjectSummaryDto> = (
  t: TFunction
) => ({
  title: (
    <Typography variant="inherit" noWrap>
      {t('common:expires', 'expires')}
    </Typography>
  ),
  field: 'quotationExpireDate',
  width: '10%',
  headerStyle: { textAlign: 'left' },
  cellStyle: {
    textAlign: 'left',
    padding: 0,
    verticalAlign: 'middle',
  },
  groupRender: (groupKey) => {
    const momentObj = moment(groupKey, 'YYYY-MM-DD')
    return (
      <>
        <span style={{ display: 'inline-block', width: '80px' }}>
          {momentObj.format('L')}
        </span>
        <span>- {momentObj.fromNow()}</span>
      </>
    )
  },

  render: (rowData) => {
    if (
      !rowData.quotationExpiresAt ||
      !statesToRenderExpirationDate.includes(
        rowData.status.toUpperCase() as ProjectStateAsString
      )
    ) {
      return null
    }

    return (
      <div style={{ paddingTop: '16px' }}>
        <ExpirationDateProgressVariant
          expirationDate={rowData.quotationExpiresAt}
          disableLabel
        />
      </div>
    )
  },
})

export const projectTableColumns: (
  t: TFunction
) => Column<ProjectSummaryDto>[] = (t) => [
  lastOperation(t),
  status(t),
  order(t),
  projectReference(t),
  numOfParts(t),
  salesPrice(t),
  expirationDate(t),
  customer(t),
  contactPerson(t),
  supplier(t),
  salesPerson(t),
  createdBy(t),
]
