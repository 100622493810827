import { ChevronLeftOutlined } from '@mui/icons-material'
import { IconButton, Tab, Tabs } from '@mui/material'
import { BomItemFeaturesDetailsContainer } from 'components/ProjectPage/BillOfMaterials/RowDetails/FeaturesDetailsContainer'
import { useClientStorage } from 'customHooks/useClientStorage'
import { ModelExplorer } from 'features/ModelViewer/cadexchanger/ModelExplorer'
import { ModelPMIData } from 'features/ModelViewer/cadexchanger/ModelPMIData'
import { ModelController } from 'features/ModelViewer/cadexchanger/manager/ModelController'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FeatureDto,
  WorkingStepDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { makeStyles } from 'tss-react/mui'

type Props = {
  bomItemPointer: BomItemPointer
  modelController: ModelController
  onShowModel: (modelType: '2d' | '3d', show: boolean) => void
  activeTab?: DetailsTab
  activeWorkingStep: WorkingStepType
  handleInputModel?: (workingStep: WorkingStepDto) => void
  handleOutputModel?: (workingStep: WorkingStepDto) => void
  features?: FeatureDto[]
  loadingFeatures?: boolean
}

const TabPanel = (props: {
  children: React.ReactNode
  value: string
  currentTab: string
}) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: props.value === props.currentTab ? 'block' : 'none',
      }}
    >
      {props.children}
    </div>
  )
}

const useStyles = makeStyles()((theme) => ({
  menuRoot: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '500px',
    width: '500px',
  },
  menuRootClosed: {
    minWidth: '32px',
    width: theme.spacing(2),
  },
  menuContainer: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    height: '90vh',
    width: '100%',
    position: 'relative',
    overflowY: 'auto',
    overflowX: 'hidden',
    boxShadow: theme.shadows[2],
    // maxHeight: '80vh',
  },
  menuContainerClosed: {
    width: 0,
    flexBasis: '0',
  },
  closeDetailsButton: {
    position: 'relative',
    transform: 'translateX(-35%)',
    backgroundColor: theme.palette.background.paper,
    transition: 'transform 0.5s ease-in-out',
  },
  closeDetailsButtonClosed: {
    transform: 'translateX(0) rotate(180deg)',
  },
}))

export type DetailsTab = 'features' | 'explorer' | 'pmi'

export const BomItemDetailsMenu = (props: Props) => {
  const { classes, cx } = useStyles()
  const [currentTab, setCurrentTab] = useClientStorage<DetailsTab>(
    'bomItemDetailsTab',
    props.activeTab ?? undefined
  )
  const { t } = useTranslation('project')

  const [availableTabs, setAvailableTabs] = useState<Array<DetailsTab>>([
    'features',
    'explorer',
    'pmi',
  ])

  /**
   * Change the visibility of the tabs.
   * @param tab The tab to change the visibility of.
   * @param visible Whether the tab should be visible or not.
   * @param priority if < 0 tab will be included in the left.
   */
  const changeTabVisibility = useCallback(
    (tab: DetailsTab, visible: boolean, priority?: number) => {
      if (visible) {
        if (!availableTabs.includes(tab)) {
          setAvailableTabs((currentTabs) => {
            let newTabs = undefined

            if (priority < 0) {
              newTabs = [tab, ...currentTabs]
            } else {
              newTabs = [...currentTabs, tab]
            }

            return newTabs
          })
        }
      } else {
        if (availableTabs.includes(tab)) {
          setAvailableTabs(availableTabs.filter((t) => t !== tab))
        }
        if (currentTab === tab) setCurrentTab('features')
      }
    },
    [availableTabs, currentTab, setCurrentTab]
  )

  useEffect(() => {
    switch (props.bomItemPointer.type) {
      case BomItemType.assemblyType:
      case BomItemType.assemblyInstance:
        changeTabVisibility('features', true, -1)
        changeTabVisibility('explorer', true)
        if (!currentTab) {
          setCurrentTab('explorer')
        }
        break
      case BomItemType.partType:
      case BomItemType.partInstance:
        changeTabVisibility('features', true, -1)
        changeTabVisibility('explorer', false)
        changeTabVisibility('pmi', false)
        if (!currentTab) {
          setCurrentTab('features')
        }
        break
    }
  }, [
    changeTabVisibility,
    currentTab,
    props.bomItemPointer.type,
    setCurrentTab,
  ])

  const getTabName = (tab: DetailsTab) => {
    switch (tab) {
      case 'features':
        return t('features', 'features')
      case 'explorer':
        return t('modelExplorer', 'model explorer')
      case 'pmi':
        return t('pmiData', 'PMI').toLowerCase()
    }
  }

  const [open, setOpen] = useState(true)
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setVisible(true)
      }, 500)
    } else {
      setVisible(false)
    }
  }, [open])

  const handleToggleOpen = () => setOpen((c) => !c)

  return (
    <div
      className={cx(classes.menuRoot, {
        [classes.menuRootClosed]: !open,
      })}
    >
      <div
        className={cx(classes.menuContainer, {
          [classes.menuContainerClosed]: !open,
        })}
      >
        {visible ? (
          <>
            <Tabs
              centered
              value={currentTab}
              onChange={(e, index) => {
                setCurrentTab(index)
              }}
            >
              {availableTabs.map((tab) => (
                <Tab key={tab} label={getTabName(tab)} value={tab} />
              ))}
            </Tabs>

            {/*
            not implemented
            <TabPanel value="route" currentTab={currentTab}>
              <BomItemWorkingStepTimeline
                bomItemPointer={props.bomItemPointer}
                viewerController={props.modelController}
                onShowModel={props.onShowModel}
                activeWorkingStep={props.activeWorkingStep}
                handleInputModel={props.handleInputModel}
                handleOutputModel={props.handleOutputModel}
                noWorkingStepsComponent={
                  <div
                    style={{
                      width: '100%',
                      height: '85% ',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '2em',
                    }}
                    onClick={() =>
                      document.getElementById('add-working-step-input')?.focus()
                    }
                  >
                    <div style={{ textAlign: 'center', cursor: 'pointer' }}>
                      <LocalizedTypography
                        translationDefaultValue="this item does not have any additional working steps yet."
                        translationKey="project:additional-working-steps-no-working-steps"
                        variant="body1"
                        component="p"
                      />
                      <LocalizedTypography
                        translationKey="project:additional-working-steps-click-to-check"
                        variant="caption"
                        component="p"
                      >
                        click here to check the available working steps
                      </LocalizedTypography>
                    </div>
                  </div>
                }
              />
            </TabPanel> */}

            <TabPanel value={'features'} currentTab={currentTab}>
              <BomItemFeaturesDetailsContainer
                features={props.features}
                loading={props.loadingFeatures}
                bomItemPointer={props.bomItemPointer}
                orientation="horizontal"
                rootSxProps={{
                  height: '100%',
                  borderBottom: 0,
                }}
                modelController={props.modelController}
              />
            </TabPanel>

            <TabPanel value={'explorer'} currentTab={currentTab}>
              <ModelExplorer
                bomItemPointer={props.bomItemPointer}
                modelViewer={props.modelController}
                // onNoModelExplorer={() => changeTabVisibility('explorer', false)}
              />
            </TabPanel>

            <TabPanel value={'pmi'} currentTab={currentTab}>
              <ModelPMIData
                modelViewer={props.modelController}
                onNoPMIData={() => {
                  changeTabVisibility('pmi', false)
                  // setCurrentTab('explorer')
                }}
                onPMIDataFound={() => {
                  changeTabVisibility('pmi', true)
                  // setCurrentTab('pmi')
                }}
              />
            </TabPanel>
          </>
        ) : null}
      </div>
      <IconButton
        name="closeMenuButton"
        size="small"
        onClick={handleToggleOpen}
        className={cx(classes.closeDetailsButton, {
          [classes.closeDetailsButtonClosed]: !open,
        })}
      >
        <ChevronLeftOutlined />
      </IconButton>
    </div>
  )
}
