/* eslint-disable react-hooks/exhaustive-deps */
import { useArticleIdFilter } from 'features/BillOfMaterials/BoMItemFilter/Components/ArticleIdFilter/useArticleIdFilter'
import { useResourceIdFilter } from 'features/BillOfMaterials/BoMItemFilter/Components/WorkingStepsFilter/useResourceIdFilter'
import { PriceDetailsGraphData } from 'features/BomItemDetailsDrawer/useBomItemDetails'
import { useMemo, useRef } from 'react'
import {
  MoneyDto,
  PriceScope,
  PriceSummaryDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { MaterialsBarChart } from '../Graphs/MaterialsBarChart'
import { TotalsBarChart } from '../Graphs/TotalsBarChart'
import { WorkingStepsBarChart } from '../Graphs/WorkingStepsBarChart'

type Props = {
  priceSummaryKeys: string[]
  setPriceSummaryQuery: (description: string) => void
  isMaterialVisible: boolean
  isWorkingStepVisible: boolean
  priceScope: PriceScope
}

export type GraphData = {
  description: string
  category: string
  totalSalesPriceWorkingSteps: MoneyDto
  totalSalesPriceMaterials: MoneyDto
  workingStepPrices: PriceDetailsGraphData[]
  materialPrices: PriceDetailsGraphData[]
}

export const ProjectPriceSummaryGraph = (props: Props) => {
  const { handleChangeFilter: handleChangeResourceIdFilter } =
    useResourceIdFilter()
  const { handleChangeFilter: handleChangeArticleIdFilter } =
    useArticleIdFilter()

  const graphContainerRef = useRef<HTMLDivElement>()

  // const mousePosition = useRef({ x: -200, y: -200 })

  // const [tooltipData, setTooltipData] =
  //   useState<TargetPriceTooltipPayload>(undefined)

  // useEffect(() => {
  //   const escapeHandler = (e: KeyboardEvent) => {
  //     if (e.key === Key.Escape) {
  //       setTooltipData(undefined)
  //     }
  //   }

  //   document.addEventListener('keydown', escapeHandler)

  //   return () => {
  //     document.removeEventListener('keydown', escapeHandler)
  //   }
  // }, [])

  // const handleMouseEnter = (e, data: PriceDetailsGraphData) => {
  //   mousePosition.current = { x: e.clientX, y: e.clientY }

  //   if (!data) {
  //     setTooltipData(undefined)
  //     return
  //   }

  //   setTooltipData({
  //     priceScope: props.priceScope,
  //     data: {
  //       ...data,
  //       bomItemPointer: undefined,
  //       description: data.description,
  //       mainActivityCostPrice: data.totalCostPrice,
  //       discountValue: MultiplyMoney(data.discountValue, -1),
  //       primaryWorkingStep: data.primaryWorkingStep,
  //       total: data.totalSalesPrice,
  //     },
  //     tooltipDataType: data?.materialId
  //       ? 'ProjectLevelMaterial'
  //       : 'ProjectLevelWorkingStep',
  //   })
  // }

  const scrollAndFilter = (price: PriceSummaryDto) => {
    if (price?.isWorkingStep) {
      handleChangeResourceIdFilter(price.workingStep?.resource?.id)
      handleChangeArticleIdFilter(undefined)
    } else if (price?.isMaterial) {
      handleChangeArticleIdFilter(price.materialId)
      handleChangeResourceIdFilter(undefined)
    }

    props.setPriceSummaryQuery(price.description)
  }

  const GraphComponent = useMemo(
    // eslint-disable-next-line react/display-name
    () => (graphProps: { height?: number }) => {
      if (props?.isMaterialVisible && props?.isWorkingStepVisible) {
        return (
          <TotalsBarChart
            {...props}
            scrollAndFilter={scrollAndFilter}
            containerHeight={graphProps.height}
          />
        )
      }

      if (props?.isMaterialVisible) {
        return (
          <MaterialsBarChart
            {...props}
            scrollAndFilter={scrollAndFilter}
            containerHeight={graphProps.height}
          />
        )
      }

      if (props?.isWorkingStepVisible) {
        return (
          <WorkingStepsBarChart
            {...props}
            scrollAndFilter={scrollAndFilter}
            containerHeight={graphProps.height}
          />
        )
      }

      return null
    },
    [props?.isMaterialVisible, props?.isWorkingStepVisible]
  )

  // const boundingRect = graphContainerRef.current?.getBoundingClientRect()

  return (
    <>
      {/* <TargetPriceTooltip
        position={mousePosition.current}
        payload={tooltipData}
        onClose={() => setTooltipData(undefined)}
        boundaries={
          boundingRect && {
            top: boundingRect.top,
            bottom: boundingRect.bottom,
            left: boundingRect.left,
            right: boundingRect.right,
          }
        }
        containerRef={graphContainerRef}
      /> */}
      {/* )} */}
      <div ref={graphContainerRef}>
        {/* <ClickAwayListener onClickAway={() => setTooltipData(undefined)}> */}
        <span>
          <GraphComponent height={204} />
        </span>
        {/* </ClickAwayListener> */}
      </div>
    </>
  )
}
