import {
  Autocomplete,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
} from '@mui/material'
import { DialogCloseSaveButtons } from 'components/Common/DialogActionButtons/DialogCloseSaveButtons'
import { useAppController } from 'customHooks/useAppController'
import { HTMLAttributes, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SalesPerson } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { ProjectStateSelectors } from 'store/Project/ProjectSelectors'
import { useAppSelector } from 'store/configureStore'
import { SalesRepresentativeController } from '../SalesRepresentativeController'

export type SalesRepresentativeDialogProps = {
  open: boolean
  onClose: () => void
}

const formatSalesRepr = (person: SalesPerson) => {
  return `${person.firstName || ''} ${person.lastName} (${person?.email})`
}

const SalesReprOption = (props: {
  person: SalesPerson
  baseElProps: HTMLAttributes<HTMLLIElement>
}) => {
  return (
    <ListItem {...props.baseElProps}>
      <ListItemAvatar>
        <Avatar src={props.person.picture} />
      </ListItemAvatar>
      <ListItemText
        primary={`${props.person.firstName || ''} ${
          props.person.lastName
        }`.trim()}
        secondary={props.person.email}
      />
    </ListItem>
  )
}

export const SalesRepresentativeDialog = (
  props: SalesRepresentativeDialogProps
) => {
  const { controller, loading } = useAppController(
    () => new SalesRepresentativeController()
  )

  const { t } = useTranslation()

  const projectSalesRepr = useAppSelector(
    ProjectStateSelectors.SalesRepresentant
  )

  const [selectedReprToChange, setSelectedReprToChange] = useState<
    SalesPerson | undefined
  >(undefined)

  const [reprs, setReprs] = useState<Array<SalesPerson>>([])

  useEffect(() => {
    ;(async () => {
      try {
        setReprs(await controller.GetSalesRepresentants())
      } catch (ex) {
        ShowException('sales representative', ex)
      }
    })()
  }, [controller])

  const handleChangeSelectedRepr = (value: SalesPerson) => {
    setSelectedReprToChange(value)
  }

  const handleSave = async () => {
    try {
      await controller.SetSalesRepresentant(selectedReprToChange.userId)
      props.onClose()
    } catch (ex) {
      ShowException('sales representative', ex)
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={'sm'} fullWidth>
      <DialogTitle>
        {t('common:sales-representative', 'sales representative')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            'project:sales-repr-set-description',
            'set the sales representative for this project'
          )}
        </DialogContentText>
        <form id="sales-repr" onSubmit={(e) => e.preventDefault()}>
          <Autocomplete
            loading={loading['get-sales-person']}
            options={reprs}
            value={selectedReprToChange || projectSalesRepr}
            autoHighlight
            onChange={(_, value) =>
              typeof value !== 'string' && handleChangeSelectedRepr(value)
            }
            isOptionEqualToValue={(option) =>
              option?.email === (selectedReprToChange || projectSalesRepr).email
            }
            getOptionLabel={(repr) => formatSalesRepr(repr)}
            renderOption={(baseElProps, repr) => (
              <SalesReprOption baseElProps={baseElProps} person={repr} />
            )}
            renderInput={(props) => (
              <TextField
                {...props}
                placeholder={t('common:select-user', 'select a user')}
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <DialogCloseSaveButtons
          onCloseButtonClicked={props.onClose}
          onSaveButtonClicked={handleSave}
          saving={loading['set-sales-person']}
          saveButtonFormId="sales-repr"
          saveButtonDisabled={!selectedReprToChange}
        />
      </DialogActions>
    </Dialog>
  )
}
