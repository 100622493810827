import { BaseController } from 'controllers/BaseController'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import {
  ProjectOperationDto,
  ProjectOperationStatus,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import store from 'store/configureStore'
import { GetContext } from 'store/storeUtils'
import { ProjectOperationsAPI } from './GeometryLogs.api'
import { IProjectOperationsAPI } from './GeometryLogs.types'

export class ProjectOperationsController extends BaseController<IProjectOperationsAPI> {
  private _currentPage = 0
  private _hasMore = true

  public get hasMoreItems() {
    return this._hasMore
  }

  public get currentPage() {
    return this._currentPage
  }

  constructor() {
    super((onRequestChange) => {
      const { partyId, projectId } = GetContext()

      return new ProjectOperationsAPI(partyId, projectId, onRequestChange)
    })
  }

  public async GetProjectOperations() {
    try {
      return await this.api.GetProjectOperations()
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async GetGeometryOperationsPage(page: number, pageSize = 200) {
    try {
      this._currentPage = page

      const ret = this.GetGeometryOperations(page, pageSize)
      return ret
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async GetGeometryOperations(page = 0, pageSize = 200) {
    try {
      this._currentPage = page

      const ret = await this.api.GetGeometriesOperations(page, pageSize)

      if (!ret) {
        return null
      }

      this._hasMore = ret?._meta.totalPages > page

      const filteredRecords: ProjectOperationDto[] = []
      const assemblyHeaders = store.getState().project.assemblyHeaders
      const partTypes = store.getState().project.partTypes

      for (let i = 0; i < ret.records.length; i++) {
        const record = ret.records[i]

        if (
          record.status !== ProjectOperationStatus.Finished ||
          (assemblyHeaders &&
            Boolean(assemblyHeaders[record.children[0]?.boMItemId])) ||
          (partTypes && partTypes[record.children[0]?.boMItemId])
        ) {
          filteredRecords.push(record)
        }
      }

      return {
        ...ret,
        records: filteredRecords,
      }
    } catch (err) {
      throw err
    }
  }

  public async DeleteAllProjectOperationLogs() {
    try {
      return await this.api.RemoveAllGeometryLogs()
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async GetOperationsSummary() {
    try {
      const resp = await this.api.GetOperationsSummary()
      store.dispatch(
        newProjectActions.updateOperationsFlags({
          ...resp,
          shallUpdateOperationsSummary: false,
        })
      )
    } catch (err) {
      throw this.HandleError(err)
    }
  }
}
