import {
  Brightness2Outlined,
  ExitToAppOutlined,
  SettingsOutlined,
  WbSunnyOutlined,
  WorkspacesOutlined,
} from '@mui/icons-material'
import {
  Avatar,
  Button,
  Divider,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import { LanguageButton } from 'components/Localization/LanguageButton'
import { useAppRunningContext } from 'features/EmbeddedRh24/useAppRunningContext'
import { Profile } from 'model/User'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PartySummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { SetDefaultTheme } from 'store/Application/appActions'
import { RootAppState, useAppSelector } from 'store/configureStore'
import { BuiltInThemes } from 'utils/appTheme'
import { useOrganizationContext } from 'utils/useOrganizationContext'

import { useAppController } from 'customHooks/useAppController'
import { UserController } from 'features/CustomerPortal/ConnectedParties/controllers/UserController'
import { newUserActions } from 'store/User/newUserReducer'

const mapStateToProps = (state: RootAppState) => ({
  userProfile: state.user.profile,
  organizationContext: state.user.organizationContext,
  themeName: state.app.builtInThemeName,
})

const mapDispatchToProps = (dispatch) => ({
  clearOrganizationContext: () =>
    dispatch(newUserActions.clearOrganizationContext()),
  changeBuiltInTheme: (themeName: BuiltInThemes) =>
    dispatch(SetDefaultTheme(themeName)),
})

type userButtonProps = {
  userProfile: Profile
  organizationContext: PartySummaryDto
  clearOrganizationContext: () => void
  themeName: string
  changeBuiltInTheme: (themeName: BuiltInThemes) => void
}

const UserButton = (props: userButtonProps) => {
  const navigate = useNavigate()
  const [isExpanded, setExpanded] = useState(false)
  const anchorEl = useRef(undefined)
  const { withinIframe } = useAppRunningContext()

  const { controller } = useAppController(() => new UserController())

  const userPartiesLength = useAppSelector(
    (state) =>
      state.user?.organizations?.length ||
      0 + state.user?.availablePortals?.length ||
      0
  )

  const { t } = useTranslation()

  const handleContextMenu = () => {
    setExpanded(!isExpanded)
  }

  const handleLogOut = async () => {
    navigate('/logout')
  }

  const menuAction = (action) => {
    action()
    setExpanded(false)
  }

  const { partyId: organizationId } = useOrganizationContext()

  const profileOrSettingsUrl = useMemo(
    () => (organizationId ? '/app/settings' : '/app/user'),
    [organizationId]
  )

  if (!props.userProfile) {
    return null
  }

  return (
    <div ref={anchorEl} style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        name="rh24-menu-button"
        onClick={handleContextMenu}
        color="inherit"
        sx={{ gap: (theme) => theme.spacing(1) }}
      >
        <ListItemText
          primary={
            <Typography variant="body1" noWrap sx={{ textAlign: 'right' }}>
              {`${props.userProfile?.firstName || ''} ${
                props.userProfile?.lastName
              }`.trim()}
            </Typography>
          }
          secondaryTypographyProps={{
            color: 'inherit',
          }}
          secondary={`${
            props.organizationContext?.name ??
            t('common:select-organization', 'select an organization')
          }`}
          sx={{
            textAlign: 'right',
          }}
        />
        <ListItemAvatar>
          <Avatar
            src={props.userProfile?.picture}
            variant="circular"
            alt="user avatar"
          ></Avatar>
        </ListItemAvatar>
        {/* </div> */}
      </Button>
      <Menu
        id="user_menu"
        anchorEl={anchorEl.current}
        open={isExpanded}
        onClose={() => setExpanded(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& li': {
            padding: (theme) => theme.spacing(1.5),
          },
          '& .MuiTypography-body1': {
            fontSize: (theme) => theme.typography.h6.fontSize,
          },
        }}
      >
        <MenuItem
          onClick={(e) => {
            e.preventDefault()
            menuAction(() => navigate(profileOrSettingsUrl + location.search))
          }}
          onAuxClick={(e) => {
            e.preventDefault()
            window.open(profileOrSettingsUrl, '_blank')
          }}
        >
          <ListItemIcon>
            <SettingsOutlined />
          </ListItemIcon>
          <ListItemText primary={t('common:settings', 'settings')} />
        </MenuItem>

        {userPartiesLength === 1 ? null : (
          <MenuItem
            onClick={(e) => {
              e.preventDefault()
              menuAction(() => {
                controller.ClearUserOrganizationContext()
                navigate('/app/workspaces')
              })
            }}
            onAuxClick={(e) => {
              e.preventDefault()
              controller.ClearLastVisitedWorkspace()
              window.open('/app/workspaces', '_blank')
            }}
          >
            <ListItemIcon>
              <WorkspacesOutlined />
            </ListItemIcon>
            <ListItemText
              primary={t('common:switch-workspace', 'switch workspace')}
            />
          </MenuItem>
        )}

        <Divider style={{ margin: 0 }} />
        <MenuItem onClick={() => menuAction(() => handleLogOut())}>
          <ListItemIcon>
            <ExitToAppOutlined />
          </ListItemIcon>
          <ListItemText primary={t('common:logout', 'logout')} />
        </MenuItem>
        {!withinIframe ? (
          <span>
            <Divider style={{ margin: 0 }} />
            <MenuItem
              onClick={() =>
                props.themeName === 'light'
                  ? props.changeBuiltInTheme('darkBlue')
                  : props.changeBuiltInTheme('light')
              }
            >
              <ListItemIcon>
                {props.themeName !== 'light' ? (
                  <WbSunnyOutlined />
                ) : (
                  <Brightness2Outlined />
                )}
              </ListItemIcon>
              <ListItemText
                primary={t('common:change-app-theme', 'change app theme')}
              />
            </MenuItem>
          </span>
        ) : null}
        <LanguageButton variant="menu-item" />
      </Menu>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserButton)
