import { SpinningIcon } from 'components/Common/Spinner/Spinning'
import { AddWorkingStepButton } from 'components/ProjectPage/BillOfMaterials/Rows/Components/RowWorkingSteps/WorkingSteps/AddWorkingStepButton'
import { PartTypeNestingDirection } from 'features/BillOfMaterials/PartType/PartTypeNestingDirection'
import { bomItemActivitiesSelectors } from 'features/BillOfMaterials/store/selectors/activities/bomItemActivitiesSelectors'
import { isEqual } from 'lodash'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { Suspense } from 'react'
import {
  BoMItemActivityDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { ActivityWithArticle } from './ActivityWithArticle'
import { ActivityWithoutArticle } from './ActivityWithoutArticle'

type Props = {
  bomItemPointer: BomItemPointer
  /**
   * in the material view we should not show the keywords input, just a button to open the article selector
   */
  keywordsInputVariant?: 'input' | 'button'
  /**
   * will disable the list of working steps below the keywords input
   */
  keywordsOnly?: boolean
}

const useStyles = makeStyles({ name: 'BomItemActivities' })((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  activityWithArticles: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  activityWithoutArticles: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    paddingLeft: theme.spacing(0.5),
    minHeight: theme.spacing(5),
    flexWrap: 'wrap',
    paddingRight: '94px',
  },
}))

export function BomItemActivities(props: Props) {
  const { classes } = useStyles()

  const bomItemActivities: BoMItemActivityDto[] = useAppSelector(
    bomItemActivitiesSelectors.allActivities(props.bomItemPointer),
    isEqual
  )

  const rawMaterialActivity: BoMItemActivityDto = useAppSelector(
    bomItemActivitiesSelectors.rawMaterialActivity(props.bomItemPointer),
    isEqual
  )

  const activitiesWithArticles = bomItemActivities?.filter(
    (act) =>
      act.supportsArticle &&
      act.primaryWorkingStep !== rawMaterialActivity?.primaryWorkingStep
  )

  if (!bomItemActivities && !rawMaterialActivity) {
    return null
  }

  return (
    <div className={classes.root}>
      <Suspense
        fallback={
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <SpinningIcon />
          </div>
        }
      >
        <ul className={classes.activityWithArticles}>
          <ActivityWithArticle
            bomItemPointer={props.bomItemPointer}
            activity={rawMaterialActivity}
            keywordsInputVariant={props.keywordsInputVariant}
          />
          {activitiesWithArticles.map((bomItemActivity) => (
            <li key={bomItemActivity.id}>
              <ActivityWithArticle
                bomItemPointer={props.bomItemPointer}
                activity={bomItemActivity}
                keywordsInputVariant={props.keywordsInputVariant}
              />
            </li>
          ))}
        </ul>
        <ul className={classes.activityWithoutArticles}>
          {!props.keywordsOnly &&
            props.bomItemPointer.type !== BomItemType.partInstance && (
              <li>
                <AddWorkingStepButton
                  bomItemPointers={[props.bomItemPointer]}
                />
              </li>
            )}
          {props.bomItemPointer.type !== BomItemType.partInstance &&
            bomItemActivities.find(
              (x) => x.primaryWorkingStep === WorkingStepType.SheetCutting
            ) && (
              <li>
                <PartTypeNestingDirection
                  bomItemPointer={props.bomItemPointer}
                  workingStepType={WorkingStepType.SheetCutting}
                />
              </li>
            )}
          {!props.keywordsOnly &&
            bomItemActivities.map((bomItemActivity) => (
              <li key={bomItemActivity.id + '_no_article'}>
                <ActivityWithoutArticle
                  bomItemPointer={props.bomItemPointer}
                  activity={bomItemActivity}
                />
              </li>
            ))}
        </ul>
      </Suspense>
    </div>
  )
}
