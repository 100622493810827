import { DeleteOutlined, EditOutlined } from '@mui/icons-material'
import { Box, Button, Checkbox, IconButton, Typography } from '@mui/material'
import {
  PartyManagementController,
  PartyManagementOperations,
} from 'controllers/System/PartyManagementController'
import { useAppController } from 'customHooks/useAppController'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_SortingState,
  useMaterialReactTable,
} from 'material-react-table'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  AppModule,
  ManagementPartyDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { markText } from 'utils/textFilterUtils'
import { SystemPartySettingsDialog } from './components/SystemPartySettingsDialog'

const emptyPartyItem: ManagementPartyDto = {
  id: '',
  name: '',
  coCKey: '',
  hasSubscription: false,
  hasSelfServicePortal: false,
  currencyCode: 'EUR',
  createdByEmail: '',
  hasSupportChat: false,
  hasSelfServicePortalSupportChat: false,
  createdById: undefined,
  isCreatedOn: undefined,
  modules: [AppModule.Enterprise],
}

export const NewPartiesPage = () => {
  const [globalFilter, setGlobalFilter] = useState('')
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [pageSize, setPageSize] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })
  const [totalCount, setTotalCount] = useState(0)
  const [sortableColumns, setSortableColumns] = useState([])

  const [data, setData] = useState<ManagementPartyDto[]>([])

  const { controller, loading, error } = useAppController(
    () => new PartyManagementController()
  )

  const fetchData = useCallback(async () => {
    try {
      const response = await controller.GetAllPaged({
        page: pageSize.pageIndex,
        pageSize: pageSize.pageSize,
        search: globalFilter,
        orderBy: sorting?.[0]?.id as keyof ManagementPartyDto,
        orderDirection: sorting[0]?.desc ? 'desc' : 'asc',
      })

      setData(response.details)
      setTotalCount(response.totalCount)
      setSortableColumns(response._sortFields)

      return response
    } catch (err) {
      ShowException('parties', err)
      return null
    }
  }, [controller, globalFilter, pageSize.pageIndex, pageSize.pageSize, sorting])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const { openDialog } = useDialogManager()

  const handleDeleteParty = useCallback(
    (partyId: string) => {
      openDialog('ConfirmationDialog', {
        title: 'Are you sure you want to delete this party?',
        onConfirm: async () => {
          try {
            await controller.Delete(partyId)
            await fetchData()
          } catch (err) {
            ShowException('System Management > Delete Party', err)
          }
        },
      })
    },
    [controller, fetchData, openDialog]
  )

  const columns = useMemo<MRT_ColumnDef<ManagementPartyDto>[]>(() => {
    return [
      {
        accessorKey: 'id',
        enableEditing: false,
        enableGrouping: false,
        enablePinning: true,
        enableSorting: sortableColumns.includes('Id'),
        grow: false,
        header: 'id',
        maxSize: 80,
        minSize: 1,
        size: 1,
        Cell: (cellProps) => (
          <Typography variant="body2" whiteSpace={'break-spaces'}>
            {cellProps.row.original.id}
          </Typography>
        ),
      },
      {
        accessorKey: 'name',
        enableGrouping: false,
        enableResizing: true,
        enableSorting: sortableColumns.includes('Name'),
        header: 'name',
        minSize: 1,
        size: 1,
        Cell: (cellProps) => {
          return (
            <Typography variant="body2" whiteSpace={'break-spaces'}>
              {markText(
                cellProps.row.original.name,
                cellProps.table.getState().globalFilter
              )}
            </Typography>
          )
        },
      },
      {
        accessorKey: 'coCKey',
        enableGrouping: false,
        enableResizing: true,
        enableSorting: sortableColumns.includes('CoCKey'),
        header: 'coCKey',
        minSize: 1,
        size: 1,
      },
      {
        accessorKey: 'currencyCode',
        enableResizing: true,
        enableSorting: sortableColumns.includes('CurrencyCode'),
        header: 'currency',
        minSize: 1,
        size: 1,
        muiTableHeadCellProps: {
          sx: {
            ['& div']: {
              justifyContent: 'center',
            },
          },
        },
        muiTableBodyCellProps: {
          style: {
            textAlign: 'center',
          },
        },
      },
      {
        accessorKey: 'modules',
        header: 'subscription',
        minSize: 1,
        size: 1,
        enableSorting:
          sortableColumns.includes('HasSubscription') ||
          sortableColumns.includes('Modules'),
        getGroupingValue: (row) => {
          return row.modules?.[0] || row.hasSubscription
        },
        muiTableHeadCellProps: {
          sx: {
            ['& div']: {
              justifyContent: 'center',
            },
          },
        },
        muiTableBodyCellProps: {
          style: {
            textAlign: 'center',
          },
        },
        Cell: (cellProps) => {
          return (
            <>
              <Checkbox
                checked={cellProps.row.original.hasSubscription}
                style={{
                  pointerEvents: 'none',
                }}
              />
              <Typography variant="body2">
                {cellProps.row.original.modules?.join(', ')}
              </Typography>
            </>
          )
        },
      },
      {
        accessorKey: 'hasSelfServicePortal',
        enableSorting: sortableColumns.includes('HasSelfServicePortal'),
        header: 'self service portal',
        minSize: 1,
        size: 1,
        getGroupingValue: (row) => row.hasSelfServicePortal,
        muiTableHeadCellProps: {
          sx: {
            '& div': {
              justifyContent: 'center',
            },
          },
        },
        muiTableBodyCellProps: {
          style: {
            textAlign: 'center',
          },
        },
        Cell: (cellProps) => {
          return (
            <Checkbox
              checked={cellProps.row.original.hasSelfServicePortal}
              style={{
                pointerEvents: 'none',
              }}
            />
          )
        },
      },
      {
        accessorKey: 'hasSupportChat',
        enableSorting: sortableColumns.includes('HasSupportChat'),
        header: 'party support chat',
        minSize: 1,
        size: 1,
        muiTableHeadCellProps: {
          sx: {
            ['& div']: {
              justifyContent: 'center',
            },
          },
        },
        muiTableBodyCellProps: {
          style: {
            textAlign: 'center',
          },
        },
        Cell: (cellProps) => {
          return (
            <Checkbox
              checked={cellProps.row.original.hasSupportChat}
              style={{
                pointerEvents: 'none',
              }}
            />
          )
        },
      },
      {
        accessorKey: 'hasSelfServicePortalSupportChat',
        enableSorting: sortableColumns.includes(
          'HasSelfServicePortalSupportChat'
        ),
        header: 'self service portal chat',
        minSize: 1,
        size: 1,
        muiTableHeadCellProps: {
          sx: {
            ['& div']: {
              justifyContent: 'center',
            },
          },
        },
        muiTableBodyCellProps: {
          style: {
            textAlign: 'center',
          },
        },
        Cell: (cellProps) => {
          return (
            <Checkbox
              checked={cellProps.row.original.hasSelfServicePortalSupportChat}
              style={{
                pointerEvents: 'none',
              }}
            />
          )
        },
      },
      {
        accessorKey: 'createdByEmail',
        enableSorting: sortableColumns.includes('CreatedByEmail'),
        enableGrouping: false,
        header: 'created by',
        minSize: 1,
        size: 1,
      },
    ]
  }, [sortableColumns])

  const table = useMaterialReactTable({
    columnResizeMode: 'onEnd',
    columns: columns,
    data: data || [],
    editDisplayMode: 'custom',
    enableColumnPinning: true,
    enableColumnResizing: true,
    enableEditing: true,
    enableGrouping: true,
    enableRowActions: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      density: 'compact',
    },
    layoutMode: 'semantic',
    manualFiltering: true,
    groupedColumnMode: 'remove',
    manualPagination: true,
    muiTableContainerProps: {
      sx: {
        height: 'calc(100vh - 200px)',
      },
    },
    muiTablePaperProps: {
      sx: {
        ['& [for="mrt-rows-per-page"]']: {
          transform: 'translate(0, 0)',
          fontSize: (theme) => theme.typography.caption.fontSize,
          marginRight: (theme) => theme.spacing(2),
        },
      },
    },
    muiTableBodyRowProps: (tableBodyProps) => ({
      onClick: () => {
        if (tableBodyProps.row.getIsGrouped()) return

        openPartyModalForm(tableBodyProps.row.original, true)
      },
      sx: {
        cursor: tableBodyProps.row.getIsGrouped ? 'pointer' : 'default',
      },
    }),
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPageSize,
    onSortingChange: setSorting,
    paginationDisplayMode: 'default',
    positionActionsColumn: 'last',
    rowCount: totalCount,
    state: {
      globalFilter,
      pagination: pageSize,
      sorting,
      isLoading: loading[PartyManagementOperations.GetAll],
    },
    renderRowActions: (renderRowProps) => {
      return (
        <Box>
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              openPartyModalForm(renderRowProps.row.original, true)
            }}
            size="small"
          >
            <EditOutlined />
          </IconButton>
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              handleDeleteParty(renderRowProps.row.original.id)
            }}
            size="small"
          >
            <DeleteOutlined />
          </IconButton>
        </Box>
      )
    },
    renderTopToolbarCustomActions: () => {
      return (
        <Button onClick={() => openPartyModalForm(emptyPartyItem, false)}>
          Add Party
        </Button>
      )
    },
  })

  const [openPartyModal, setOpenPartyModal] = useState(false)
  const [isEditMode, setEditMode] = useState(false)
  const [currentPartData, setCurrentPartData] =
    useState<ManagementPartyDto>(emptyPartyItem)

  const openPartyModalForm = (party: ManagementPartyDto, editMode = false) => {
    setCurrentPartData(Object.assign({}, emptyPartyItem, party))
    setEditMode(editMode)
    setOpenPartyModal(true)
  }

  const handleSaveParty = async (party: ManagementPartyDto) => {
    try {
      const result: ManagementPartyDto = isEditMode
        ? await controller.Update(party)
        : await controller.Add(party)

      await fetchData()

      return result
    } catch (err) {
      throw err
    }
  }

  return (
    <div>
      {openPartyModal && (
        <SystemPartySettingsDialog
          data={currentPartData}
          open={openPartyModal}
          isNew={!isEditMode}
          onClose={() => setOpenPartyModal(false)}
          onConfirm={handleSaveParty}
          errors={
            isEditMode
              ? (error[PartyManagementOperations.Update] as unknown)
              : (error[PartyManagementOperations.Add] as unknown)
          }
        />
      )}
      <MaterialReactTable table={table} />
    </div>
  )
}
