import { Box, Typography } from '@mui/material'
import ShowToRole from 'components/Common/Portal/ShowToRole'
import {
  ProjectStateAsString,
  ProjectStatus,
} from 'controllers/Project/ProjectStateMachine'
import { ProjectProcessFlow } from 'features/BillOfMaterials/ProjectHeader/ProjectProcessFlow'
import { ProjectVisibleInPortal } from 'features/BillOfMaterials/ProjectHeader/ProjectVisibleInPortal'
import { projectStatusSelector } from 'features/BillOfMaterials/store/selectors/projectStatusSelector'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useTranslation } from 'react-i18next'
import { RootAppState, useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { BomItemErpExportErrorFilter } from '../BomItemErpExportFilter'
import './ProjectStatus.scss'
import { CancelledState } from './States/CancelledState'
import { ProductionStates } from './States/CurrentState'
import { DefiningState } from './States/DefiningState'
import { OrderState } from './States/OrderState'
import { QuotedState } from './States/QuoteState'
import { RequestState } from './States/RequestState'

const useStyles = makeStyles()(() => {
  // const disabledBackgroundColor = theme.palette.action.disabledBackground
  // const disabledBorderColor = theme.palette.action.disabledBackground

  return {
    root: {
      '& li:after': {
        content: '" "',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '10px 0 10px 14px',
        right: '-13px',
        top: 0,
        zIndex: '2',
        position: 'absolute',
      },
    },
    // active: {
    //   backgroundColor: theme.palette.info.main,
    //   color: theme.palette.info.contrastText,
    //   '&:after': {
    //     borderColor: `transparent transparent transparent ${theme.palette.info.main}`,
    //   },
    // },
    // disabled: {
    //   backgroundColor: disabledBackgroundColor,
    //   '&:after': {
    //     borderColor: `transparent transparent transparent ${disabledBorderColor}`,
    //   },
    // },
  }
})

export const InProductionProjectStatuses = [
  ProjectStateAsString.ORDERED,
  ProjectStateAsString.PRODUCING,
  ProjectStateAsString.PRODUCED,
  ProjectStateAsString.PACKAGING,
  ProjectStateAsString.PACKAGED,
  ProjectStateAsString.DELIVERING,
  ProjectStateAsString.DELIVERED,
]

export const ProjectStatusSelector = (props: {
  bomItemPointer: BomItemPointer
}) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const currentProjectStateFromStore = useAppSelector(
    projectStatusSelector,
    isEqual
  )

  const createdInPortal = useAppSelector(
    (state: RootAppState) => state.project.activeProject?.createdInPortal
  )

  if (currentProjectStateFromStore === ProjectStateAsString.CANCELLED) {
    return (
      <div className="project-status">
        <CancelledState />
      </div>
    )
  }

  const StatesToShow: React.ReactElement[] = []

  if (InProductionProjectStatuses.includes(currentProjectStateFromStore)) {
    StatesToShow.push(
      <ProductionStates
        key={ProjectStatus.ORDERED + '_production'}
        showStatus={ProjectStatus.ORDERED}
      />
    )
    StatesToShow.push(
      <ProductionStates
        key={ProjectStatus.PRODUCING}
        showStatus={ProjectStatus.PRODUCING}
      />
    )
    StatesToShow.push(
      <ProductionStates
        key={ProjectStatus.PACKAGING}
        showStatus={ProjectStatus.PACKAGING}
      />
    )
    StatesToShow.push(
      <ProductionStates
        key={ProjectStateAsString.DELIVERING}
        showStatus={ProjectStatus.DELIVERING}
      />
    )
  } else {
    StatesToShow.push(<DefiningState key={ProjectStatus.DEFINING} />)
    StatesToShow.push(<RequestState key={ProjectStatus.REQUESTED} />)
    StatesToShow.push(<QuotedState key={ProjectStatus.QUOTED} />)
    StatesToShow.push(<OrderState key={ProjectStatus.ORDERED} />)
  }

  return (
    <div className="project-status">
      <ul className={classes.root}>{StatesToShow}</ul>
      <Box
        display={'flex'}
        flex={1}
        justifyContent={'space-between'}
        gap={2}
        paddingRight={2}
        alignItems={'center'}
      >
        {createdInPortal && (
          <ShowToRole seller>
            <Typography variant="caption" style={{ marginLeft: '1rem' }}>
              {t('project:created-in-portal', 'self-service portal')}
            </Typography>
          </ShowToRole>
        )}
        <ProjectVisibleInPortal />
        <BomItemErpExportErrorFilter bomItemPointer={props.bomItemPointer} />
        <ProjectProcessFlow />
      </Box>
    </div>
  )
}
