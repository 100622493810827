import { RefreshOutlined } from '@mui/icons-material'
import {
  CircularProgress,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import NestingRunningIcon from '_icons/processing/nesting_ani.svg?react'
import { LoadingContainer } from 'components/Common/LoadingContainer'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { FeaturesDetails } from 'components/ProjectPage/BillOfMaterials/RowDetails/FeaturesDetails'
import { WorkingStepButton } from 'features/BillOfMaterials/components/BomItemWorkingStepSummary'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { uniq } from 'lodash'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  PriceScope,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { useAppSelector } from 'store/configureStore'
import { useBomItemDetailsDrawerStyles } from '../BomItemDetailsDrawer.styles'
import PartTypeFinancialGraph from '../Graph/PartTypeFinancialGraph/PartTypeFinancialGraph'
import { PriceDetailsForm } from '../PriceDetails/PriceDetailsForm'
import { useFinancialDetails } from '../useFinancialDetails'
import { usePartGeometryDetails } from '../usePartGeometryDetails'
import { BomItemDetailsProps } from './ProjectDetails/BomItemDetailsProps'

export const BomItemPriceContainer = (props: BomItemDetailsProps) => {
  const { classes: sharedClasses } = useBomItemDetailsDrawerStyles()
  const { t } = useTranslation()

  const isProjectNesting = useAppSelector(
    projectSelectors.projectIsNestingSelector
  )

  const { formDetails, handleLoadGeometryDetails } = usePartGeometryDetails(
    props.bomItemPointer
  )

  const {
    loading,
    priceDetails,
    priceScope,
    originalPriceDetails,
    handleChangeFinancialView,
    handleResetProductionTime,
    handleResetAdditionalCosts,
    handleSaveProductionTime,
    handleSaveAdditionalCosts,
    getItemPriceDetails,
    bomItemTotalProjectQuantity,
  } = useFinancialDetails({
    bomItemPointer: props.bomItemPointer,
    open: true,
  })

  const handleReload = useCallback(() => {
    try {
      getItemPriceDetails.cancel()
      getItemPriceDetails(props.bomItemPointer)
      getItemPriceDetails.flush()
      handleLoadGeometryDetails()
    } catch (err) {
      ShowException('BomItemPriceContainer', err)
    }
  }, [getItemPriceDetails, handleLoadGeometryDetails, props.bomItemPointer])

  const isPriceDetailsEmpty =
    !priceDetails?.length ||
    priceDetails.findIndex((x) => x.costPrice?.value > 0) === -1

  const isLoadingPriceDetails =
    loading[`get-price-details-${props.bomItemPointer.id}`]

  return (
    <>
      <LoadingContainer
        style={{ height: '60vh' }}
        loading={isPriceDetailsEmpty && isLoadingPriceDetails}
        noMessage
        empty={
          isPriceDetailsEmpty && !isLoadingPriceDetails && !isProjectNesting ? (
            <>
              <LocalizedTypography
                translationKey={'project:prices-not-calculated'}
                color="GrayText"
              >
                prices are not calculated for this item. Check the item material
                and working steps
              </LocalizedTypography>
              <IconButton onClick={handleReload} size="small">
                {isLoadingPriceDetails ? (
                  <CircularProgress size={20} />
                ) : (
                  <RefreshOutlined />
                )}
              </IconButton>
            </>
          ) : null
        }
        emptyBox={{
          height: '10vh',
        }}
      >
        <div>
          {isPriceDetailsEmpty ? null : (
            <>
              {isProjectNesting && (
                <Tooltip
                  title={t(
                    'project:project-is-nesting-prices-may-change',
                    'project is nesting, prices may change'
                  )}
                >
                  <div
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <NestingRunningIcon width="64px" />
                  </div>
                </Tooltip>
              )}
              <div className={sharedClasses.actions}>
                <div
                  style={{
                    display: 'flex',
                    gap: '1em',
                  }}
                >
                  {uniq(
                    priceDetails?.flatMap((p) => {
                      return p.children?.flatMap(
                        (c) => c.workingStep.secondaryWorkingStep
                      )
                    })
                  )
                    .filter((x) => {
                      if (
                        !x ||
                        x === WorkingStepType.Loading ||
                        x === WorkingStepType.Setup ||
                        x === WorkingStepType.Unloading
                      ) {
                        return false
                      }
                      return true
                    })

                    .map((ws) => {
                      return (
                        <WorkingStepButton
                          key={ws}
                          ws={{
                            primaryWorkingStep: ws,
                            count: 1,
                          }}
                          isNotAllowed={false}
                          shouldFilter
                        />
                      )
                    })}
                </div>

                {/* <BomItemWorkingStepSummary
                  bomItemPointer={props.bomItemPointer}
                  shouldFilter
                  includeCountOfWorkingSteps={
                    props.bomItemPointer.type === BomItemType.assemblyType
                  }
                  onlyAssemblyWorkingSteps={
                    props.bomItemPointer.type === BomItemType.assemblyType
                  }
                  includeAssemblyWorkingSteps={true}
                /> */}
                <TextField
                  select
                  value={priceScope}
                  label="scope"
                  SelectProps={{
                    onChange: (ev) =>
                      handleChangeFinancialView(
                        originalPriceDetails,
                        ev.target.value as PriceScope
                      ),
                    renderValue: (value) => (
                      <Typography variant="body2" color="textPrimary">
                        {value === PriceScope.Unit
                          ? t('common:perUnit', 'per unit')
                          : t('common:total') +
                            ` (x${bomItemTotalProjectQuantity})`}
                      </Typography>
                    ),
                  }}
                >
                  <MenuItem value={PriceScope.Unit}>
                    {t('common:perUnit', 'per unit')}
                  </MenuItem>
                  <MenuItem value={PriceScope.Total}>
                    {t('common:total') + ` (x${bomItemTotalProjectQuantity})`}
                  </MenuItem>
                </TextField>
                <IconButton onClick={handleReload} size="small">
                  {isLoadingPriceDetails ? (
                    <CircularProgress size={20} />
                  ) : (
                    <RefreshOutlined />
                  )}
                </IconButton>
              </div>
              <div className={sharedClasses.graph}>
                <PartTypeFinancialGraph
                  bomItemPointer={props.bomItemPointer}
                  priceDetails={priceDetails}
                  priceScope={priceScope}
                />

                {/* <FinancialDetailsGraph
                  bomItemPointer={props.bomItemPointer}
                  graphData={summarizedPriceDetails}
                  open={true}
                  targetPrice={
                    priceScope === PriceScope.Unit
                      ? bomItemTargetPricePerUnit
                      : MultiplyMoney(
                          bomItemTargetPricePerUnit,
                          bomItemTotalProjectQuantity
                        )
                  }
                  priceScope={priceScope}
                /> */}
              </div>
              <PriceDetailsForm
                bomItemPointer={props.bomItemPointer}
                priceDetails={priceDetails}
                priceScope={priceScope}
                handleResetProductionTime={handleResetProductionTime}
                handleResetAdditionalCosts={handleResetAdditionalCosts}
                handleSaveAdditionalCostPrice={handleSaveAdditionalCosts}
                handleSaveProductionTime={handleSaveProductionTime}
                loading={loading}
              />
            </>
          )}
        </div>
      </LoadingContainer>
      {formDetails?.length > 0 && (
        <div className={sharedClasses.formDetails}>
          <FeaturesDetails features={formDetails} orientation="vertical" />
        </div>
      )}
    </>
  )
}
