import {
  WorkingStepDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { alphabetcalSort } from 'utils/customSort'
import { AdditionalWorkingStepsDialogActions } from '../store/AdditionalWorkingStepsDialogActions'

export type WorkingStepErrorsLookup = Record<
  WorkingStepType,
  Record<string, string[]>
>

export type WorkingStepWithData = WorkingStepDto & {
  label?: string
  comment?: string
  formData?: Record<string, unknown>
}

type State = {
  addWorkingStepOpen: boolean
  availableWorkingSteps: WorkingStepWithData[]
  editableWorkingSteps: WorkingStepWithData[]
  workingStepsToAdd: WorkingStepWithData[]
  workingStepsToRemove: WorkingStepWithData[]
  originalWorkingSteps: WorkingStepWithData[]
  currentWorkingStep: WorkingStepWithData | undefined
  workingStepsWithErrors: Partial<WorkingStepErrorsLookup> | undefined
}
export const initialState: State = {
  addWorkingStepOpen: true,
  availableWorkingSteps: [],
  editableWorkingSteps: [],
  workingStepsToAdd: [],
  workingStepsToRemove: [],
  originalWorkingSteps: [],
  currentWorkingStep: undefined as WorkingStepWithData | undefined,
  workingStepsWithErrors: undefined,
}
export const reducer = (
  state: State,
  action: AdditionalWorkingStepsDialogActions
): State => {
  switch (action.type) {
    case 'setAddWorkingStepOpen':
      return { ...state, addWorkingStepOpen: action.payload }
    case 'setAvailableWorkingSteps':
      return { ...state, availableWorkingSteps: action.payload }
    case 'setSelectedWorkingSteps':
      return { ...state, editableWorkingSteps: action.payload }
    case 'setWorkingStepsToAdd': {
      const newEditableWorkingSteps = [
        ...state.editableWorkingSteps,
        action.payload,
      ].sort(alphabetcalSort('label'))
      const newWorkingStepsToAdd = [...state.workingStepsToAdd, action.payload]
      const newAvailableWorkingSteps = state.availableWorkingSteps.filter(
        (item) => item.primaryWorkingStep !== action.payload.primaryWorkingStep
      )
      const newRemovedWorkingSteps = state.workingStepsToRemove.filter(
        (item) => item.primaryWorkingStep !== action.payload.primaryWorkingStep
      )

      return {
        ...state,
        workingStepsToAdd: newWorkingStepsToAdd,
        availableWorkingSteps: newAvailableWorkingSteps,
        editableWorkingSteps: newEditableWorkingSteps,
        workingStepsToRemove: newRemovedWorkingSteps,
        currentWorkingStep: action.payload,
      }
    }
    case 'setWorkingStepsToRemove': {
      // should only truly remove if it was part of the original list
      let newWorkingStepsToRemove: WorkingStepWithData[]

      action.payload.comment = ''
      action.payload.formData = {}

      if (
        state.originalWorkingSteps.findIndex(
          (item) =>
            item.primaryWorkingStep === action.payload.primaryWorkingStep
        ) > -1
      ) {
        newWorkingStepsToRemove = [
          ...state.workingStepsToRemove,
          action.payload,
        ]
      } else {
        newWorkingStepsToRemove = state.workingStepsToRemove
      }

      const newEditableWorkingSteps = state.editableWorkingSteps.filter(
        (item) => item.primaryWorkingStep !== action.payload.primaryWorkingStep
      )
      const newWorkingStepsToAdd = state.workingStepsToAdd.filter(
        (item) => item.primaryWorkingStep !== action.payload.primaryWorkingStep
      )
      const newAvailableWorkingSteps = [
        ...state.availableWorkingSteps,
        action.payload,
      ].sort(alphabetcalSort('label'))

      const newWorkingStepsWithErrors = { ...state.workingStepsWithErrors }
      delete newWorkingStepsWithErrors[action.payload.primaryWorkingStep]

      return {
        ...state,
        workingStepsToRemove: newWorkingStepsToRemove,
        availableWorkingSteps: newAvailableWorkingSteps,
        editableWorkingSteps: newEditableWorkingSteps,
        workingStepsToAdd: newWorkingStepsToAdd,
        workingStepsWithErrors: newWorkingStepsWithErrors,
        currentWorkingStep:
          state.currentWorkingStep?.primaryWorkingStep ===
          action.payload.primaryWorkingStep
            ? undefined
            : state.currentWorkingStep,
      }
    }
    case 'setCurrentWorkingStep':
      return { ...state, currentWorkingStep: action.payload }

    case 'updateWorkingStep': {
      let updateList: WorkingStepWithData[]

      if (
        state.workingStepsToAdd.findIndex(
          (item) =>
            item.primaryWorkingStep === action.payload.primaryWorkingStep
        ) === -1
      ) {
        updateList = [...state.workingStepsToAdd, action.payload]
      } else {
        updateList = state.workingStepsToAdd.map((item) => {
          if (item.primaryWorkingStep === action.payload.primaryWorkingStep) {
            return action.payload
          }
          return item
        })
      }

      return {
        ...state,
        workingStepsToAdd: updateList,
        editableWorkingSteps: state.editableWorkingSteps.map((item) => {
          if (item.primaryWorkingStep === action.payload.primaryWorkingStep) {
            return action.payload
          }
          return item
        }),
        currentWorkingStep: { ...state.currentWorkingStep, ...action.payload },
      }
    }
    case 'setOriginalWorkingSteps':
      return { ...state, originalWorkingSteps: action.payload }
    case 'updateWorkingStepsWithErrors': {
      const keysWithErrors = Object.keys(action.payload.errors)
      let newWorkingStepWithErrors = { ...state.workingStepsWithErrors }

      keysWithErrors.forEach((key) => {
        if (!action.payload.errors[key].__errors?.length) {
          if (!newWorkingStepWithErrors[action.payload.workingStepType]) {
            return
          }
          if (newWorkingStepWithErrors[action.payload.workingStepType][key]) {
            delete newWorkingStepWithErrors[action.payload.workingStepType][key]
          }
          if (
            Object.keys(
              newWorkingStepWithErrors[action.payload.workingStepType]
            ).length === 0
          ) {
            delete newWorkingStepWithErrors[action.payload.workingStepType]
          }
        } else {
          newWorkingStepWithErrors = {
            ...newWorkingStepWithErrors,
            [action.payload.workingStepType]: {
              ...newWorkingStepWithErrors[action.payload.workingStepType],
              [key]: action.payload.errors[key].__errors,
            },
          }
        }
      })

      return {
        ...state,
        workingStepsWithErrors: newWorkingStepWithErrors,
      }
    }

    case 'resetWorkingStepsToAddAndRemove':
      return {
        ...state,
        workingStepsToAdd: [],
        workingStepsToRemove: [],
      }

    default:
      return state
  }
}
