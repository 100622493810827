import { SxProps } from '@mui/material'
import { LoadingContainer } from 'components/Common/LoadingContainer'
import { ModelController } from 'features/ModelViewer/cadexchanger/manager/ModelController'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { FeatureDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { FeatureTree } from './FeatureTree'

export const BomItemFeaturesDetailsContainer = (props: {
  bomItemPointer: BomItemPointer
  orientation: 'horizontal' | 'vertical'
  features: FeatureDto[]
  rootSxProps?: SxProps
  onNoFeatures?: () => void
  modelController?: ModelController
  loading: boolean
}) => {
  if (!props.features?.length) {
    return null
  }

  return (
    <LoadingContainer loading={props.loading} noMessage>
      <FeatureTree
        features={props.features}
        modelController={props.modelController}
      />
    </LoadingContainer>
  )
}
