/* eslint-disable react/display-name */
import MaterialTable, { Query, QueryResult } from '@material-table/core'
import { Typography, TypographyProps, useTheme } from '@mui/material'
import TrashSvg from '_icons/ui/trash.svg?react'
import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'
import { useMaterialTableLocalization } from 'customHooks/useMaterialTableLocalization'
import { fullUnloadActiveProject } from 'features/BillOfMaterials/store/asyncActions/fullUnloadActiveProject'
import { ConnectionState } from 'features/SignalR/components/ConnectionState'
import { isEqual } from 'lodash'
import { forwardRef, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ProjectSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { deleteProjects } from 'store/ProjectList/asyncActions/deleteProjects'
import {
  RootAppState,
  useAppDispatch,
  useAppSelector,
} from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { ProjectsPageHelpers } from './ProjectsPageHelpers'
import { projectTableColumns } from './projectTableColumns'
import { useProjectList } from './useProjectList'

export const CellTypography = ({
  children,
  typographyProps = {},
}: {
  children: React.ReactNode
  typographyProps?: TypographyProps
}) => {
  return (
    <Typography variant="body2" {...typographyProps}>
      {children}
    </Typography>
  )
}

const useStyles = makeStyles({ name: 'ProjectsPage' })(() => ({
  projectTableFix: {
    /**
     * you don't really have control how material-table renders groups.
     * uggly hack that depends on number of columns, so don't forget to change it here
     * if you change the number of columns.
     *
     * it will not show the checkbox for the group header (due to ugly alignment), and will align the text to the left
     * */
    '& b': {
      display: 'none',
    },
    '& td[colspan="13"]': {
      '& .MuiCheckbox-root': {
        display: 'none',
      },
      textAlign: 'left',
    },
  },
}))

export const ProjectsListPage = (props: { isFirstRender: boolean }) => {
  const { classes } = useStyles()

  const {
    fetchProjectListPaged,
    onlyMyProjects,
    setOnlyMyProjects,
    pageSize,
    setPageSize,
    connectToUpdates,
    disconnectFromUpdates,
    groupName,
    loading,
    setShowCanceledProjects,
    showCanceledProjects,
  } = useProjectList(props.isFirstRender)

  const navigate = useNavigate()
  const theme = useTheme()
  const localization = useMaterialTableLocalization()

  const tableRef = useRef<{
    onQueryChange: () => void
    setState: (obj: Record<string, unknown>) => void
    dataManager: { changePageSize: (pageSize: number) => void }
  }>()

  useEffect(() => {
    // tableRef.current.dataManager.changePageSize(pageSize)
    connectToUpdates(() => tableRef.current?.onQueryChange())

    return () => {
      disconnectFromUpdates()
    }
  }, [connectToUpdates, disconnectFromUpdates, pageSize])

  const dispatch = useAppDispatch()

  const handleDeleteSelectedProjects = async (
    _evt,
    data: ProjectSummaryDto | ProjectSummaryDto[]
  ) => {
    try {
      if (data instanceof Array) {
        dispatch(
          deleteProjects(
            data.map((x) => {
              const fix = x.status ? x.status.toUpperCase() : ''

              return {
                projectId: x.id,
                projectStatus: ProjectStateAsString[fix],
              }
            })
          )
        )
      } else {
        const fix = data.status ? data.status.toUpperCase() : ''

        dispatch(
          deleteProjects([
            {
              projectId: data.id,
              projectStatus: ProjectStateAsString[fix],
            },
          ])
        )
      }
    } catch (err) {
      ShowException('delete projects', err)
    }
  }

  const projectListOperation = useAppSelector(
    (state: RootAppState) => ({
      operation: state.projectList.operation,
      operationStatus: state.projectList.operationStatus,
      operationError: state.projectList.operationError,
    }),
    isEqual
  )

  useEffect(() => {
    if (projectListOperation.operation === 'delete') {
      switch (projectListOperation?.operationStatus) {
        case 'pending': {
          tableRef.current.setState({ isLoading: true })
          break
        }
        case 'fulfilled': {
          tableRef.current.onQueryChange()
          break
        }
        case 'rejected': {
          ShowException('delete projects', projectListOperation.operationError)
          tableRef.current.onQueryChange()
          break
        }
      }
    }
  }, [projectListOperation])

  const { t } = useTranslation()
  const _columns = useMemo(() => projectTableColumns(t), [t])

  const getProjectsList = async (query: Query<ProjectSummaryDto>) => {
    let sortColumn: keyof ProjectSummaryDto

    for (const value of query.orderByCollection) {
      if (value.sortOrder > 0) {
        sortColumn = _columns[value.orderBy].field as keyof ProjectSummaryDto

        // backend is not able to sort by money fields,
        // so we need to change the name of the field
        if (sortColumn === 'salesPriceOfItems') {
          sortColumn = 'salesPriceOfItemsAmount' as never
        }
      }
    }

    const result = await fetchProjectListPaged({
      page: query.page,
      pageSize: query.pageSize,
      orderBy: sortColumn,
      orderDirection:
        (query.orderByCollection?.[0]?.orderDirection as 'desc' | 'asc') ||
        'desc',
      search: query.search,
    })

    // try {
    //   result.details?.forEach((x) => {
    //     const date = (x.lastOperation = t('common:formattedDate', {
    //       date: moment(x.lastOperation).toDate(),
    //     }) as never)

    //     x.lastOperation = date
    //   })
    // } catch (err) {
    //   console.warn('error parsing date', err)
    // }

    return {
      data: result.details || [],
      page: result.page,
      totalCount: result.totalCount,
    } as QueryResult<ProjectSummaryDto>
  }

  return (
    <span className={classes.projectTableFix}>
      <MaterialTable
        localization={localization}
        tableRef={tableRef}
        title={
          <ProjectsPageHelpers
            handleOnlyMyProjects={(myProjects) => {
              setOnlyMyProjects(myProjects)
              tableRef.current.onQueryChange()
            }}
            onlyMyProjects={onlyMyProjects}
            showCanceledProjects={showCanceledProjects}
            handleShowCanceledProjects={(show) => {
              setShowCanceledProjects(show)
              tableRef.current.onQueryChange()
            }}
            handleReload={() => {
              tableRef.current.onQueryChange()
            }}
            reloading={loading['GetProjectsListPaged']}
          />
        }
        data={getProjectsList}
        columns={_columns}
        onRowsPerPageChange={setPageSize}
        options={{
          loadingType: 'linear',
          initialPage: 0,
          pageSize: pageSize,
          pageSizeOptions: [20, 50, 100, 250],
          emptyRowsWhenPaging: true,
          showFirstLastPageButtons: true,
          exportAllData: true,
          actionsColumnIndex: -1,
          paging: true,
          paginationType: 'normal',
          draggable: true,
          grouping: true,
          selection: true,
          showSelectAllCheckbox: true,
          headerStyle: {
            position: 'sticky',
            top: 0,
            fontSize: '0.775em',
            fontWeight: 'bold',
            backgroundColor: theme.palette.background.paper,
            zIndex: 2,
          },
          maxBodyHeight: 'calc(100vh - 360px)',
          showTitle: true,
          padding: 'dense',
          tableLayout: 'auto',
        }}
        onRowClick={(e, rowData) => {
          dispatch(fullUnloadActiveProject())
          navigate(`/app/project/${rowData.id}`)
        }}
        editable={{
          isDeletable: (rowData) => {
            const fix = rowData.status ? rowData.status.toUpperCase() : ''
            return fix !== ProjectStateAsString.ORDERED
          },
          isDeleteHidden: (rowData) => {
            const fix = rowData.status ? rowData.status.toUpperCase() : ''
            return fix === ProjectStateAsString.ORDERED
          },
          onRowDelete: (rowData) => {
            return new Promise((resolve) => {
              setTimeout(() => {
                const fix = rowData.status ? rowData.status.toUpperCase() : ''
                dispatch(
                  deleteProjects([
                    {
                      projectId: rowData.id,
                      projectStatus: ProjectStateAsString[fix],
                      confirmationDone: true,
                    },
                  ])
                )
                resolve(true)
              }, 100)
            })
          },
        }}
        actions={[
          {
            tooltip: 'Remove All Selected Projects',
            icon: () => (
              <TrashSvg
                className={'MuiSvgIcon-root'}
                style={{ width: '1.25em' }}
              />
            ),
            onClick: handleDeleteSelectedProjects,
          },
        ]}
        icons={{
          Delete: forwardRef((props, ref) => (
            <TrashSvg
              {...props}
              className={'MuiSvgIcon-root'}
              ref={ref}
              style={{ width: '1rem' }}
            />
          )),
        }}
      />
      <ConnectionState expectedGroups={[groupName]} />
    </span>
  )
}
