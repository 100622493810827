import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { KeywordDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { TelemetryService } from 'services/Telemetry'

export type KeywordWithTranslation = KeywordDto & { translatedString: string }

export function useKeywordsTranslation() {
  const { t, i18n } = useTranslation('keywords')

  const translateKeyword = useCallback(
    (keyword: KeywordDto) => {
      return keyword.translationKey
        ? t(`${keyword.translationKey}`, {
            value: keyword.translationArgument,
            defaultValue: keyword.translationArgument
              ? '{{value}} ' + keyword.translationKey
              : keyword.originalKeyword,
          })
        : keyword.originalKeyword || ''
    },
    [t]
  )

  const addTranslation = useCallback(
    (keyword: KeywordDto) => {
      return {
        ...keyword,
        translatedString: translateKeyword(keyword),
      }
    },
    [translateKeyword]
  )

  const asArrayOfTranslatedKeywords = useCallback(
    (
      tokens: Record<string, KeywordDto[]> = {},
      options: { disableSorting: boolean } = {
        disableSorting: false,
      }
    ): KeywordWithTranslation[] => {
      try {
        const translatedKeywords: KeywordWithTranslation[] = []

        Object.keys(tokens).forEach((group) => {
          tokens[group].forEach((keyword) => {
            translatedKeywords.push(addTranslation(keyword))
          })
        })

        if (options.disableSorting) {
          return translatedKeywords
        }

        return translatedKeywords.sort((a, b) =>
          a.translatedString.localeCompare(b.translatedString, i18n.language, {
            numeric: true,
          })
        )
      } catch (ex) {
        TelemetryService.getInstance().logError(ex)
        if (tokens) {
          return Object.values(tokens || {}).flat() as KeywordWithTranslation[]
        }
        return []
      }
    },
    [addTranslation, i18n.language]
  )

  return {
    translateKeyword,
    addTranslation,
    asArrayOfTranslatedKeywords,
  }
}
