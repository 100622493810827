import { PriceSummariesController } from 'controllers/Project/PriceSummariesController'
import { useAppController } from 'customHooks/useAppController'
import { TFunction } from 'i18next'
import { isEqual } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PriceSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { useOrganizationAndProjectContext } from 'utils/useOrganizationContext'

export const useProjectPriceSummaryDetails = (
  initialPriceSummary: PriceSummaryDto
) => {
  const { t } = useTranslation()

  const [_initialPriceSummary, setInitialPriceSummary] =
    useState(initialPriceSummary)
  const [_changedPriceSummary, setChangedPriceSummary] =
    useState<PriceSummaryDto>(initialPriceSummary)
  const [changed, setChanged] = useState(false)
  const [saving, setSaving] = useState(false)
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string>
  >({})

  const { partyId, projectId } = useOrganizationAndProjectContext()
  const { controller, loading } = useAppController(
    () => new PriceSummariesController(partyId, projectId)
  )

  const [isDiscountValueDisabled, setIsDiscountValueDisabled] = useState(false)
  const [isDiscountRatioDisabled, setIsDiscountRatioDisabled] = useState(false)
  const [isSurchargeValueDisabled, setIsSurchargeValueDisabled] =
    useState(false)
  const [isSurchargeRatioDisabled, setIsSurchargeRatioDisabled] =
    useState(false)

  useEffect(() => {
    setInitialPriceSummary(initialPriceSummary)

    if (changed) {
      setChangedPriceSummary((curr) => ({
        ...initialPriceSummary,
        ...curr,
      }))
    } else {
      setChangedPriceSummary(initialPriceSummary)
      setChanged(false)
      setIsDiscountRatioDisabled(false)
      setIsDiscountValueDisabled(false)
      setIsSurchargeRatioDisabled(false)
      setIsSurchargeValueDisabled(false)
    }
  }, [initialPriceSummary, changed])

  const handleChange = useCallback(
    (property: keyof PriceSummaryDto, value: unknown) => {
      //should not handle changes if fixed value is equal to initial value
      if (value && value['value']) {
        const newValue = parseFloat(value['value'])?.toFixed(2)
        const initialValueFixed =
          _initialPriceSummary[property]['value']?.toFixed(2)

        if (newValue === initialValueFixed) {
          return
        }
      }

      const newPriceSummary = {
        ..._changedPriceSummary,
        [property]: value,
      }

      const isEqualToInitial = isEqual(newPriceSummary, _initialPriceSummary)

      if (isEqualToInitial) {
        setIsDiscountRatioDisabled(false)
        setIsDiscountValueDisabled(false)
        setIsSurchargeRatioDisabled(false)
        setIsSurchargeValueDisabled(false)
        setValidationErrors({})
      } else {
        switch (property) {
          case 'discountRatio': {
            setIsDiscountValueDisabled(true)
            break
          }
          case 'discountValue': {
            setIsDiscountRatioDisabled(true)
            break
          }
          case 'surchargeRatio': {
            setIsSurchargeValueDisabled(true)
            break
          }
          case 'surchargeValue': {
            setIsSurchargeRatioDisabled(true)
          }
        }
      }

      setChanged(!isEqualToInitial)
      setChangedPriceSummary(newPriceSummary)

      setValidationErrors({})
      setValidationErrors(validateFields(newPriceSummary, t))
    },
    [_changedPriceSummary, _initialPriceSummary, t]
  )

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (changed) {
      try {
        setSaving(true)
        await controller.SavePriceSummary(
          _changedPriceSummary,
          _initialPriceSummary
        )
      } catch (err) {
        ShowException('price summary', err)
        setChangedPriceSummary(_initialPriceSummary)
        setChanged(false)
      } finally {
        setChanged(false)
        setSaving(false)
      }
    }

    setChanged(false)
  }

  const handleResetToDefaults = async (
    valueType: 'costPrice' | 'discount' | 'surcharge'
  ): Promise<void> => {
    try {
      setIsDiscountRatioDisabled(false)
      setIsDiscountValueDisabled(false)
      setIsSurchargeRatioDisabled(false)
      setIsSurchargeValueDisabled(false)
      setChanged(false)
      setSaving(false)

      await controller.ResetToDefault(valueType, _changedPriceSummary)
    } catch (err) {
      ShowException('reset to defaults', err)
    }
  }

  const handleReset = (e) => {
    e.preventDefault()
    setChanged(false)
    setChangedPriceSummary(_initialPriceSummary)
    setIsDiscountRatioDisabled(false)
    setIsDiscountValueDisabled(false)
    setIsSurchargeRatioDisabled(false)
    setIsSurchargeValueDisabled(false)
  }

  return {
    handleChange,
    handleSubmit,
    changed,
    handleReset,
    changedPriceSummary: _changedPriceSummary,
    saving,
    handleResetToDefaults,
    isDiscountRatioDisabled,
    isDiscountValueDisabled,
    isSurchargeRatioDisabled,
    isSurchargeValueDisabled,
    validationErrors,
    loading,
  }
}

function validateFields(newPriceSummary: PriceSummaryDto, t: TFunction) {
  const validation = {}

  if (
    isNaN(newPriceSummary.surchargeValue.value) ||
    newPriceSummary.surchargeValue.value < 0
  ) {
    validation['surchargeValue'] = `${t('common:surcharge')} ${t(
      'common:validation-greter-or-equal-to-zero',
      { defaultValue: 'must be greater or equal to zero' }
    )}` // 'Surcharge value must be greater or equal to 0'
  }

  if (
    isNaN(newPriceSummary.discountValue.value) ||
    newPriceSummary.discountValue.value < 0
  ) {
    validation['discountValue'] = `${t('common:discount')} ${t(
      'common:validation-greater-or-equal-to-zero'
    )}` // 'Discount value cannot be negative'
  }

  if (
    newPriceSummary.discountValue.value >
    newPriceSummary.totalCostPrice.value + newPriceSummary.surchargeValue.value
  ) {
    validation['discountValue'] = `${t('common:discount')} ${t(
      'common:min-value',
      {
        minValue: t('project:cost-price') + ' + ' + t('project:surcharge'),
      }
    )}`
    // 'Discount value cannot be greater than total cost price + surcharge'
  }

  if (
    isNaN(newPriceSummary.discountRatio.value) ||
    newPriceSummary.discountRatio.value < 0
  ) {
    validation['discountRatio'] = `${t('common:discount')} ${t(
      'common:validation-greater-or-equal-to-zero'
    )}` // 'Discount ratio cannot be negative'
  }

  if (newPriceSummary.discountRatio.value > 100) {
    validation['discountRatio'] = 'Discount ratio cannot be greater than 100'
  }

  return validation
}
