import { MoreHorizOutlined } from '@mui/icons-material'
import { Button, IconButton, Tooltip, Typography } from '@mui/material'
import { ProjectEditableStateSelector } from 'features/BillOfMaterials/store/selectors/ProjectEditableStateSelector'
import { bomItemActivitiesSelectors } from 'features/BillOfMaterials/store/selectors/activities/bomItemActivitiesSelectors'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'
import { KeywordTagsList } from './KeywordTag'

type Props = {
  bomItemPointer: BomItemPointer
  workingStepType: WorkingStepType
  activityId: string
  variant: 'materialView' | 'partTypeView'
  setArticleDialogOpen: MouseEventHandler
}

export const ActivityArticleSelectorButton = (props: Props) => {
  const { t } = useTranslation()

  const activity = useAppSelector(
    bomItemActivitiesSelectors.byActivityId(
      props.bomItemPointer,
      props.activityId
    ),
    isEqual
  )

  const isProjectEditable = useAppSelector(ProjectEditableStateSelector)

  const isArticleSelectorAllowed = useAppSelector(
    projectSelectors.articleSelectorAllowedSelector
  )

  if (!isArticleSelectorAllowed) return null

  switch (props.variant) {
    case 'materialView': {
      return (
        <Button
          title={`${
            (activity && activity.article?.description) ||
            t('project:material-select-an-article')
          }`}
          disableFocusRipple
          fullWidth
          style={{
            justifyContent: 'flex-start',
            paddingTop: 0,
            paddingBottom: 0,
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '4px',
            textAlign: 'left',
          }}
          disableElevation
          disableRipple
          onClick={props.setArticleDialogOpen}
          color="inherit"
          disabled={!isProjectEditable}
        >
          <span>
            <Typography variant="body2">
              {activity?.article?.id
                ? activity.article?.description
                : t('project:material-select-an-article')}
            </Typography>
            {activity?.article?.id && (
              <Typography variant="caption" color="textSecondary" component="p">
                {`${activity.article?.id}`}
              </Typography>
            )}
          </span>
          <KeywordTagsList keywords={activity.keywords} />
        </Button>
      )
    }
    case 'partTypeView': {
      return (
        <Tooltip title={t('project:material-select-an-article')}>
          <span>
            <IconButton
              size="small"
              onClick={props.setArticleDialogOpen}
              disabled={!isProjectEditable}
            >
              <MoreHorizOutlined color="action" />
            </IconButton>
          </span>
        </Tooltip>
      )
    }
  }
}
