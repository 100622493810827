import { ClearOutlined, SearchOutlined } from '@mui/icons-material'
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Theme,
} from '@mui/material'
import { LoadingContainer } from 'components/Common/LoadingContainer'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { useLocalizedWorkingStepLabel } from 'components/Localization/useLocalizedWorkingStepLabel'
import { useWorkingStepFilter } from 'features/BillOfMaterials/BoMItemFilter/Components/WorkingStepsFilter/useWorkingStepFilter'
import { RemoveFiltersIcon } from 'features/BillOfMaterials/ProjectHeader/RemoveFiltersIcon'
import { ProjectPriceSummariesWorkingStepSummary } from 'features/BillOfMaterials/components/BomItemWorkingStepSummary'
import { useBomItemPointer } from 'features/BillOfMaterials/components/useBomItemPointer'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import { isEqual } from 'lodash'
import { BomItemType } from 'model/Project/BoMItemRow'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  PriceScope,
  PriceSummaryDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import {
  RootAppState,
  useAppDispatch,
  useAppSelector,
} from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { useOrganizationAndProjectContext } from 'utils/useOrganizationContext'
import { ProjectPriceSummaryGraph } from './ProjectPriceSummaries/ProjectPriceSummaryGraph'
import { ProjectTotals } from './ProjectTotals/ProjectTotals'

const useProjectFinancialDetailsStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: theme.spacing(0, 2),
    width: '100%',
    flexDirection: 'column',
  },
}))

const getOrderedPriceSummary =
  (
    orderBy: (
      a: PriceSummaryDto,
      b: PriceSummaryDto,
      getLocalizedWorkingStep?: (ws: WorkingStepType) => string
    ) => number,
    getLocalizedWorkingStep?: (ws: WorkingStepType) => string
  ) =>
  (state: RootAppState) => {
    const projectPriceSummaryKeys = Object.keys(
      state.project.priceSummaries || {}
    )

    return projectPriceSummaryKeys.sort((a, b) =>
      orderBy(
        state.project.priceSummaries[a],
        state.project.priceSummaries[b],
        getLocalizedWorkingStep
      )
    )
  }

const orderBy = {
  salesPriceAsc: (a: PriceSummaryDto, b: PriceSummaryDto) => {
    return a.totalSalesPrice.value - b.totalSalesPrice.value
  },
  salesPriceDesc: (a: PriceSummaryDto, b: PriceSummaryDto) =>
    orderBy.salesPriceAsc(b, a),

  descriptionAsc: (a: PriceSummaryDto, b: PriceSummaryDto) => {
    return (
      a?.isMaterial || a?.isPurchasePartWithoutArticle
        ? a.description
        : a.workingStep?.resource?.name
    )?.localeCompare(
      b?.isMaterial || b?.isPurchasePartWithoutArticle
        ? b.description
        : b.workingStep?.resource?.name
    )
  },
  descriptionDesc: (a: PriceSummaryDto, b: PriceSummaryDto) =>
    orderBy.descriptionAsc(b, a),
  surchargeRatioAsc: (a: PriceSummaryDto, b: PriceSummaryDto) => {
    return a.surchargeRatio.value - b.surchargeRatio.value
  },
  surchargeRatioDesc: (a: PriceSummaryDto, b: PriceSummaryDto) => {
    return orderBy.surchargeRatioAsc(b, a)
  },
  discountRatioAsc: (a: PriceSummaryDto, b: PriceSummaryDto) => {
    return a.discountRatio.value - b.discountRatio.value
  },
  discountRatioDesc: (a: PriceSummaryDto, b: PriceSummaryDto) => {
    return orderBy.discountRatioAsc(b, a)
  },
}

export const ProjectFinancialDetails = () => {
  const { projectId } = useOrganizationAndProjectContext()
  const bomItemPointer = useBomItemPointer(projectId, BomItemType.project)
  const [orderByKey, setOrderByKey] =
    useState<keyof typeof orderBy>('descriptionAsc')

  const { t } = useTranslation()
  const getLocalizedWorkingStep = useLocalizedWorkingStepLabel()
  const projectPriceSummaryKeys = useAppSelector(
    getOrderedPriceSummary(orderBy[orderByKey], getLocalizedWorkingStep),
    isEqual
  )

  const dispatch = useAppDispatch()

  const { classes } = useProjectFinancialDetailsStyles()

  const { handleChangeFilter, workingStepFilter } = useWorkingStepFilter()

  const [isMaterialVisible, setIsMaterialVisible] = useState(true)
  const [isWorkingStepVisible, setIsWorkingStepVisible] = useState(true)
  const [isPurchasePartVisible, setIsPurchasePartVisible] = useState(true)
  const [descriptionQuery, setDescriptionQuery] = useState('')
  const [visibleWorkingSteps, setVisibleWorkingSteps] = useState<
    Array<WorkingStepType>
  >([])

  useEffect(() => {
    if (workingStepFilter.active) {
      setVisibleWorkingSteps(workingStepFilter.filter.enabledFilters)
    } else {
      setVisibleWorkingSteps([])
    }
  }, [workingStepFilter])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setLoading(false)
    }, 420)

    return () => {
      clearTimeout(loadingTimeout)
    }
  }, [])

  const handleVisibility = (
    key: 'material' | 'workingStep' | 'purchasePart'
  ) => {
    let _isMaterialVisible = isMaterialVisible
    let _isWorkingStepVisible = isWorkingStepVisible

    switch (key) {
      case 'material':
        _isMaterialVisible = !_isMaterialVisible
        if (!_isMaterialVisible && !_isWorkingStepVisible) {
          _isWorkingStepVisible = true
        }
        break
      case 'workingStep':
        if (_isWorkingStepVisible) {
          if (visibleWorkingSteps.length > 0) {
            setVisibleWorkingSteps([])
            handleChangeFilter(WorkingStepType.NotInitialized)
          }
          _isWorkingStepVisible = false

          if (!_isMaterialVisible) {
            _isMaterialVisible = true
          }
        } else {
          _isWorkingStepVisible = true
        }

        break
      case 'purchasePart':
        setIsPurchasePartVisible((curr) => !curr)
        break
    }

    if (!_isWorkingStepVisible && !_isMaterialVisible) {
      _isMaterialVisible = true
      _isWorkingStepVisible = true
    }

    setIsWorkingStepVisible(_isWorkingStepVisible)
    setIsMaterialVisible(_isMaterialVisible)
  }

  const handleSetSearch = (searchText: string) => {
    setDescriptionQuery((curr) => (curr === searchText ? '' : searchText))
  }

  const firstRender = useRef(false)
  useEffect(() => {
    if (!firstRender.current) {
      firstRender.current = true
      return
    }

    if (descriptionQuery === '') {
      dispatch(newProjectActions.removeAllFilters())
    }
  }, [descriptionQuery, dispatch])

  return (
    <LoadingContainer
      loading={loading}
      noMessage
      style={{ minHeight: '70vh' }}
      empty={
        !projectPriceSummaryKeys?.length &&
        !loading && (
          <LocalizedTypography translationKey={'project:prices-not-calculated'}>
            prices are not calculated for this item. Check the item material and
            working steps
          </LocalizedTypography>
        )
      }
      emptyBox={{
        minHeight: '30vh',
      }}
    >
      <div className={classes.root}>
        <div className={classes.header}>
          <TextField
            value={descriptionQuery}
            fullWidth
            onChange={(e) => setDescriptionQuery(e.target.value)}
            placeholder="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => setDescriptionQuery('')}
                  >
                    <ClearOutlined />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '1em',
              alignItems: 'center',
              width: '100%',
              marginTop: '1em',
            }}
          >
            {/* {isWorkingStepVisible && ( */}
            <div style={{ marginRight: 'auto' }}>
              <RemoveFiltersIcon
                onFiltersRemoved={() => setDescriptionQuery('')}
              />
            </div>
            <ProjectPriceSummariesWorkingStepSummary
              bomItemPointer={bomItemPointer}
              shouldFilter
              getButtonVariant={(ws) => {
                if (visibleWorkingSteps.includes(ws)) {
                  return 'filled'
                }
                return 'outlined'
              }}
              onClick={(ws) => {
                setVisibleWorkingSteps((curr) =>
                  curr.findIndex((x) => x === ws.primaryWorkingStep) === -1
                    ? [...curr, ws.primaryWorkingStep]
                    : curr.filter((x) => x !== ws.primaryWorkingStep)
                )
              }}
            />
            {/* )} */}

            <FormControlLabel
              control={
                <Checkbox
                  checked={isWorkingStepVisible}
                  onChange={() => handleVisibility('workingStep')}
                  color="primary"
                  size="small"
                />
              }
              label={
                <LocalizedTypography
                  variant="caption"
                  translationKey="common:working-steps"
                />
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isMaterialVisible}
                  onChange={() => handleVisibility('material')}
                  color="primary"
                  size="small"
                />
              }
              label={
                <LocalizedTypography
                  variant="caption"
                  translationKey="common:materials"
                />
              }
            />
            <TextField
              select
              // label={t('common:order-by')}
              value={orderByKey}
              onChange={(e) =>
                setOrderByKey(e.target.value as keyof typeof orderBy)
              }
            >
              {Object.keys(orderBy).map((key) => (
                <MenuItem key={key} value={key}>
                  {t(`project:order-by-${key}`)}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
        <ProjectPriceSummaryGraph
          setPriceSummaryQuery={(desc) => handleSetSearch(desc)}
          priceSummaryKeys={projectPriceSummaryKeys}
          isMaterialVisible={isMaterialVisible}
          isWorkingStepVisible={isWorkingStepVisible}
          priceScope={PriceScope.Total}
        />
        <ProjectTotals />
        {/* ============= OLD IMPLMENETATION =============
        <ul>
          {projectPriceSummaryKeys.map((priceSummaryKey) => {
            return (
              <li key={priceSummaryKey}>
                <ProjectPriceSummaryDetails
                  descriptionQuery={descriptionQuery}
                  isPurchasingVisible={isPurchasePartVisible}
                  isMaterialVisible={isMaterialVisible}
                  isWorkingStepVisible={isWorkingStepVisible}
                  priceSummaryKey={priceSummaryKey}
                  visibleWorkingSteps={visibleWorkingSteps}
                />
              </li>
            )
          })}
        </ul> */}
      </div>
    </LoadingContainer>
  )
}
