import { TextField, Typography } from '@mui/material'
import { WidgetProps } from '@rjsf/utils'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dashify } from 'utils/dashify'

export function ZincCodeWidget(props: WidgetProps) {
  const { t } = useTranslation()

  const label =
    props.uiSchema['ui:title']?.toString() ||
    props.uiSchema['ui:label']?.toString()

  let translatedLabel = undefined

  if (label) {
    const translationKey = `ws-${
      props.uiSchema['ui:label-translation-key'] || dashify(label)
    }`

    translatedLabel = t(translationKey, { defaultValue: label, ns: 'project' })
  }

  const [validation, setValidation] = useState<string>(undefined)
  const handleValidation = (value: string): boolean => {
    let validationMessage = undefined

    if (value && value.length < 8) {
      validationMessage = t('validation-min-char-length', {
        defaultValue: 'should be at least {{count}} characters long',
        count: 8,
        ns: 'common',
      })
    }

    if (value && value.split('/').length < 3) {
      validationMessage = t('validation-format-invalid', {
        defaultValue: 'invalid format',
        ns: 'common',
      })
    }

    props.formContext.onValidationError &&
      setTimeout(() => {
        props.formContext.onValidationError({
          [props.idSchema.$id]: {
            __errors: Boolean(validationMessage)
              ? [`${label}: ${validationMessage}`]
              : undefined,
          },
        })
      })

    if (validationMessage) {
      setValidation(validationMessage)
      return false
    } else {
      setValidation(undefined)
      return true
    }
  }

  // console.log(props)

  return (
    <TextField
      type="text"
      value={props.formData}
      onChange={(e) => {
        handleValidation(e.target.value)
        props.onChange(e.target.value)
      }}
      onFocus={(e) => e.target.select()}
      error={Boolean(props.rawErrors) || Boolean(validation)}
      helperText={
        <span>
          <Typography
            variant="caption"
            color="textSecondary"
            component="p"
            whiteSpace={'normal'}
          >
            {props.uiSchema['ui:description']}
          </Typography>
          <Typography variant="caption" whiteSpace={'normal'}>
            {validation || props.rawErrors?.join(' ')}
          </Typography>
        </span>
      }
      color={props.rawErrors ? 'error' : 'primary'}
      label={translatedLabel}
    />
  )
}
