import { Auth0ProviderWithRedirectCallback } from 'AppAuth0Provider'
import Spinning from 'components/Common/Spinner/Spinning'
import LogRocket from 'logrocket'
import { Suspense, useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend as Backend } from 'react-dnd-html5-backend'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from 'reportWebVitals'
import store from 'store/configureStore'
import App from './App'
import { ErrorBoundary } from './components/Common/ErrorBoundary'

import { EmbeddedRh24 } from 'features/EmbeddedRh24/EmbeddedRh24'
import 'polyfills'
import './style/index.sass'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

if (
  import.meta.env.VITE_ENVIRONMENT === 'prd' ||
  import.meta.env.VITE_ENVIRONMENT === 'uat'
) {
  LogRocket.init('8jypme/rhodium24')
}

console.info('App version', import.meta.env.VITE_BUILD_NUMBER)

function AppContainer() {
  useEffect(() => {
    rootElement.classList.remove('app-loader')
  }, [])

  return (
    // <React.StrictMode>
    <DndProvider backend={Backend}>
      <Provider store={store}>
        <ErrorBoundary>
          <EmbeddedRh24 />
          <Suspense fallback={<Spinning fixed />}>
            <BrowserRouter>
              <Auth0ProviderWithRedirectCallback
                domain="auth.rhodium24.io"
                clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
                authorizationParams={{
                  redirect_uri: window.location.origin + '/auth/callback',
                  audience: 'https://api.rhodium24.io',
                }}
              >
                <App />
                <div id="root-portal"></div>
              </Auth0ProviderWithRedirectCallback>
            </BrowserRouter>
          </Suspense>
        </ErrorBoundary>
      </Provider>
    </DndProvider>
    // </React.StrictMode>
  )
}

root.render(<AppContainer />)

// try {
//   const po = new PerformanceObserver((list) => {
//     for (const entry of list.getEntries()) {
//       // Log the entry and all associated details.
//       console.log('long task logger', entry)
//     }
//   })

//   po.observe({ type: 'longtask', buffered: true })
// } catch (e) {
//   // Do nothing if the browser doesn't support this API.
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// serviceWorker.register({
//   // https://github.com/facebook/create-react-app/issues/5316
//   onUpdate: registration => {
//     alert('New version available! Update it now!')
//     window.location.reload()
//     if (registration && registration.waiting) {
//       registration.waiting.postMessage({ type: 'SKIP_WAITING' })
//     }
//   },
//   onSuccess: registration => {
//     while (true) {
//       setTimeout(() => {
//         console.log('calling registration.update')
//         registration.update()
//       }, 2000)
//     }
//   }
// })

// serviceWorker.unregister()
