/* eslint-disable tss-unused-classes/unused-classes */
import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export const useWorkspacesStyles = makeStyles()((theme: Theme) => ({
  workspacesRoot: {
    padding: theme.spacing(3, 0),
    overflow: 'auto',
    height: '100%',
    width: '100%',
  },
  pageTitle: {},
  workspaces: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    gap: theme.spacing(4),
  },
  workspaceTable: {
    flex: `1 1 ${theme.breakpoints.values.xs}px`,
  },
  noWorkspaces: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(15),
    textAlign: 'center',
    '& img': {
      maxWidth: '60%',
      maxHeight: '150px',
    },
  },
  noWorkspacesMessage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    justifyContent: 'flex-start',
    gap: theme.spacing(2),
  },
  noWorkspacesForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  noWorkspacesActions: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
  },
}))
