import { MoreVertOutlined } from '@mui/icons-material'
import {
  AppBar,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
} from '@mui/material'
import { AppLogo } from 'components/Layout/AppLogo'
import { Container } from 'components/Layout/Container'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { SearchGlobal } from 'components/Search/SearchGlobal'
import { useAppRunningContext } from 'features/EmbeddedRh24/useAppRunningContext'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { RootAppState, useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import UserButton from '../../UserButton/UserButton'
import { NewProjectButton } from './NewProjectButton/NewProjectButton'

import { useAuth0 } from '@auth0/auth0-react'
import { useOrganizationContext } from 'utils/useOrganizationContext'

import { SocialMedia } from 'components/SocialMedia'
import { Hidden } from '../Hidden/Hidden'
import './NavBar.sass'

const useStyles = makeStyles({ name: 'mine' })((theme: Theme) => ({
  toolbar: {
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1em',
    maxWidth: theme.breakpoints.values.xl,
    margin: '0 auto',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      minHeight: '62px',
      padding: 0,
    },
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 auto',
    textAlign: 'center',
    '& button': {
      height: '100%',
      padding: 0,
    },
    '& button:hover': {
      backgroundColor: 'transparent',
    },
  },
  logoImg: {
    height: `100%`,
    maxHeight: '62px',
    maxWidth: theme.spacing(20),
    mixBlendMode: theme.palette.mode === 'dark' ? 'hard-light' : 'inherit',
  },
  search: {
    ['& .MuiInputBase-root']: {
      color: 'inherit',
      '& button': {
        color: 'inherit',
      },
    },
    flex: '1 0 auto',
  },
  fieldSection: {
    display: 'flex',
    height: '100%',
  },
  sectionDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '1em',
      flex: '1 1 auto',
    },
  },
  sectionMobile: {
    display: 'flex',
    marginLeft: 'auto',
  },
  navBarContainer: {
    padding: theme.spacing(0),
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 0),
    },
  },
}))

const MyProjectsButton = () => {
  const navigate = useNavigate()

  return (
    <Button
      color="inherit"
      component="a"
      href="/app"
      variant="text"
      data-testid="navbar-btn-my-projects"
      onAuxClick={() => window.open('/app', '_blank')}
      onClick={(e) => {
        e.preventDefault()

        if (e.ctrlKey || e.metaKey) return window.open('/app', '_blank')

        navigate('/app')

        return null
      }}
      style={{ flex: '0 0 auto' }}
    >
      <LocalizedTypography
        color="inherit"
        translationKey="common:my-projects"
        translationDefaultValue={'my projects'}
      />
    </Button>
  )
}

const MobileMenu = ({ portalView }: { portalView?: boolean }) => {
  const [open, setOpen] = React.useState(false)
  const buttonRef = React.useRef(undefined)
  const { t } = useTranslation()
  return (
    <>
      <IconButton
        color="inherit"
        ref={buttonRef}
        onClick={() => setOpen((state) => !state)}
        size="small"
      >
        <MoreVertOutlined />
      </IconButton>
      <Menu
        anchorEl={buttonRef.current}
        open={open}
        onClose={() => setOpen(false)}
      >
        <MenuItem>
          <MyProjectsButton />
        </MenuItem>
        <MenuItem>
          <NewProjectButton />
        </MenuItem>
        <MenuItem>
          <UserButton />
        </MenuItem>
        {!portalView ? (
          <div>
            <Divider />
            <MenuItem id="app-notification-button">
              {t('common:qf-road-map-notifications', 'roadmap / notifications')}
            </MenuItem>
          </div>
        ) : null}
      </Menu>
    </>
  )
}

const NavBar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { partyId: organizationId } = useOrganizationContext()
  // const currentModule = useCurrentAppModule()

  const { isAuthenticated } = useAuth0()
  const userProfile = useAppSelector(
    (state: RootAppState) => state.user.profile
  )

  const { classes } = useStyles()

  const hasActiveProject = useAppSelector((state: RootAppState) =>
    Boolean(state.project.activeProject)
  )

  const { withinIframe } = useAppRunningContext()

  const hasOrganizationContext = organizationId

  if (!isAuthenticated || !userProfile) return null

  if (!location.pathname.includes('/app')) return null

  return (
    <AppBar position={'sticky'} color="transparent">
      <div id="navbar-placeholder" className="navbar-placeholder"></div>
      <Container maxWidth="xl" className={classes.navBarContainer}>
        <Toolbar className={classes.toolbar}>
          <Hidden mdDown>
            <div className={classes.logoContainer}>
              <Button
                onClick={() => navigate('/app')}
                onAuxClick={() => window.open('/app', '_blank')}
                disableRipple
                disableFocusRipple
                disableElevation
              >
                <AppLogo className={classes.logoImg} />
              </Button>
              {/* <Typography variant="caption">{currentModule}</Typography> */}
            </div>
          </Hidden>
          {!withinIframe && !hasActiveProject ? (
            <Hidden lgDown>
              <div className={classes.fieldSection}>
                <SocialMedia />
              </div>
            </Hidden>
          ) : null}
          <div className={classes.search}>
            {isAuthenticated && (
              <>
                {
                  // bomitems search bar should not be show in project page
                  !hasActiveProject && hasOrganizationContext && (
                    <SearchGlobal />
                  )
                }
              </>
            )}
          </div>
          <div className={classes.fieldSection}>
            <Hidden mdDown>
              <div className={classes.sectionDesktop}>
                {hasOrganizationContext && (
                  <>
                    <Hidden lgDown>
                      <MyProjectsButton />
                    </Hidden>
                    <NewProjectButton />
                  </>
                )}
                <Hidden mdDown>
                  <UserButton />
                </Hidden>
                <Hidden mdUp>
                  <MobileMenu portalView={withinIframe} />
                </Hidden>
              </div>
            </Hidden>
            <Hidden mdUp>
              <div className={classes.sectionMobile}>
                <MobileMenu portalView={withinIframe} />
              </div>
            </Hidden>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default NavBar
