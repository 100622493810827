import { Button } from '@mui/material'
import { ValueLabelProps } from 'components/Common/ValueLabel/ValueLabel'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import { PurchasingPartDialogProps } from 'features/PurchasingPartDetails/PurchasingPartDialog'
import { MoneyString } from 'model/Money'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useTranslation } from 'react-i18next'
import { MoneyDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'
import { ValueLabelConnected } from '../store/components/ValueLabelConnected'
import { ProjectEditableStateSelector } from '../store/selectors/ProjectEditableStateSelector'
import { bomItemFinancialSelector } from '../store/selectors/bomItemFinancialSelector'
import { bomItemIsPurchasingPartSelector } from '../store/selectors/bomItemIsPurchasingPartSelector'
import { projectInBuyerViewSelector } from '../store/selectors/projectInBuyerViewSelector'

export type StyleProps = {
  fullWidth?: boolean
}

export const BomItemPricePerBatch = ({
  bomItemPointer,
  label,
  valueLabelProps,
  fullWidth = false,
}: {
  bomItemPointer: BomItemPointer
  label?: string
  valueLabelProps?: Partial<ValueLabelProps>
  fullWidth?: boolean
}) => {
  const { t } = useTranslation()
  const isPurchasingPart = useAppSelector(
    bomItemIsPurchasingPartSelector(bomItemPointer)
  )

  const projectIsEditable = useAppSelector(ProjectEditableStateSelector)
  const isBuyerView = useAppSelector(projectInBuyerViewSelector)

  const { openDialog } = useDialogManager()

  const handleOpenPurchasingPartDialog = () => {
    openDialog('PurchasingPartDialog', {
      bomItemPointer: bomItemPointer,
      isOpen: true,
    } as PurchasingPartDialogProps)
  }

  if (
    !isPurchasingPart ||
    !projectIsEditable ||
    isBuyerView ||
    bomItemPointer.type === BomItemType.partInstance ||
    bomItemPointer.type === BomItemType.assemblyInstance
  ) {
    return (
      <ValueLabelConnected
        propertySelector={(state) =>
          MoneyString(
            bomItemFinancialSelector(
              bomItemPointer,
              'salesPricePerItem'
            )(state) as MoneyDto
          ) || '-'
        }
        valueLabelProps={{
          label: label || t('project:price-per-batch'),
          valueProps: {
            style: { width: fullWidth ? '100%' : '90px' },
          },
          ...valueLabelProps,
        }}
      />
    )
  }

  return (
    <Button
      sx={{
        padding: 0,
        flex: (theme) => `1 1 ${theme.spacing(5)}`,
        marginRight: (theme) => theme.spacing(-2),
      }}
      onClick={handleOpenPurchasingPartDialog}
    >
      <ValueLabelConnected
        propertySelector={(state) =>
          MoneyString(
            bomItemFinancialSelector(
              bomItemPointer,
              'salesPricePerItem'
            )(state) as MoneyDto
          ) || '-'
        }
        valueLabelProps={{
          label: label || t('project:price-per-batch'),
          valueProps: {
            style: { width: fullWidth ? '100%' : '90px' },
          },
          ...valueLabelProps,
        }}
      />
    </Button>
  )

  // return (
  //   <ProjectTextField
  //     valueSelector={bomItemFinancialSelector(
  //       bomItemPointer,
  //       'salesPricePerItem'
  //     )}
  //     saveAction={(value) =>
  //       saveBomItemMaterialCostPrice({
  //         bomItemPointer: bomItemPointer,
  //         materialCostPrice: value,
  //         priceScope: PriceScope.Unit,
  //       })
  //     }
  //     actionTypePrefix={saveBomItemMaterialCostPrice.typePrefix.concat(
  //       '/',
  //       bomItemPointer.id
  //     )}
  //     money
  //     disableShowHelpText
  //     textFieldProps={{
  //       // type: 'number',
  //       label: label || t('project:price-per-batch'),
  //       className: classes.moneyTextField,
  //       required: true,
  //       inputProps: {
  //         style: {
  //           textAlign: 'right',
  //           width: fullWidth ? '100%' : '90px',
  //         },
  //       },
  //     }}
  //     validation={valueGreaterThanZero}
  //   />
  // )
}
