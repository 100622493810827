import i18n from 'i18next'
import { NumberFormatValues } from 'react-number-format'

type ValidatorFunc = () => string[]

const IsMinMaxAllowed =
  (min: number, max: number) => (values: NumberFormatValues) => {
    if (values.floatValue == null) {
      return values.formattedValue === ''
    }

    if (max !== undefined && values.floatValue > max) {
      return false
    }
    if (min !== undefined && values.floatValue < min) {
      return false
    }

    return true
  }

const IsRequired =
  (
    value: string | number,
    translationKey = 'common:validation-required-field'
  ) =>
  () => {
    if (value == null || value === '') {
      return [i18n.t(translationKey)]
    }

    return undefined
  }

const MinValue =
  (value: number, minValue: number, translationKey = 'common:min-value') =>
  () => {
    if (!isNaN(value) && minValue > value) {
      return [i18n.t(translationKey, { minValue })]
    }

    return undefined
  }

const MaxValue =
  (value: number, maxValue: number, translationKey = 'common:max-value') =>
  () => {
    if (!isNaN(value) && value > maxValue) {
      return [i18n.t(translationKey, { maxValue })]
    }

    return undefined
  }

const CombineValidators = (validators: Array<ValidatorFunc>): string[] => {
  return validators.flatMap((validator) => validator()).filter(Boolean)
}

export const DefinitiveInputValidators = {
  IsMinMaxAllowed,
  CombineValidators,
  IsRequired,
  MaxValue,
  MinValue,
}
