import { MenuItem, Select, Typography } from '@mui/material'
import { BomItemController } from 'controllers/Project/BomItemController'
import { useAppController } from 'customHooks/useAppController'
import { isEqual } from 'lodash'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ResourceMatchType,
  ResourceSummaryDto,
  WorkingStepDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { useOrganizationAndProjectContext } from 'utils/useOrganizationContext'
import { saveBomItemResource } from '../store/asyncActions/saveBomItemResource'
import { ProjectEditableStateSelector } from '../store/selectors/ProjectEditableStateSelector'
import { bomItemActivitiesSelectors } from '../store/selectors/activities/bomItemActivitiesSelectors'

type Props = {
  bomItemPointers: BomItemPointer[]
  workingStep: WorkingStepDto
  onFocus?: () => void
  onBlur?: () => void
  disableLabel?: boolean
  disableUnderline?: boolean
  disableMatchDescription?: boolean
  disableSelectMaterialError?: boolean
}

export const WorkingStepResourceSelector = ({
  bomItemPointers,
  workingStep,
  onFocus,
  onBlur,
  disableLabel,
  disableUnderline,
  disableMatchDescription,
  disableSelectMaterialError,
}: Props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const materialSummary = useAppSelector(
    bomItemActivitiesSelectors.articlesPerWorkingStep(
      bomItemPointers[0],
      workingStep.primaryWorkingStep
    ),
    isEqual
  )

  const [open, setOpen] = useState(false)

  const projectEditable = useAppSelector(ProjectEditableStateSelector)

  const { partyId, projectId } = useOrganizationAndProjectContext()
  const { controller, loading, error } = useAppController(
    () => new BomItemController(partyId, projectId)
  )

  const [resourceOptions, setResourceOptions] =
    useState<Array<ResourceSummaryDto>>()

  const handleChange = (e) => {
    dispatch(
      saveBomItemResource({
        bomItemPointers: bomItemPointers,
        workingStepType: workingStep.primaryWorkingStep,
        resource: resourceOptions.find((x) => x.id === e.target.value),
        controllerInstance: controller,
      })
    )
  }

  const getHelperText = useCallback(() => {
    let helperText =
      error[`get-selectable-resources-${bomItemPointers[0]}`]?.message

    if (
      bomItemPointers[0]?.type === BomItemType.partType &&
      !materialSummary?.id &&
      !disableSelectMaterialError
    ) {
      helperText = t(
        'project:select-material-before-selecting-resource',
        'material group or quality must be selected before selecting a resource'
      )
    }

    if (!helperText && !disableMatchDescription) {
      helperText = t(workingStep.resource?.matchDescription)
    }

    return helperText
  }, [
    bomItemPointers,
    disableMatchDescription,
    disableSelectMaterialError,
    error,
    materialSummary,
    t,
    workingStep.resource?.matchDescription,
  ])

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()

      if (
        !projectEditable ||
        workingStep.resource?.matchType === ResourceMatchType.OneCapableResource
      ) {
        return
      }

      if (!open) {
        controller
          .GetBomItemSelectableResources(
            bomItemPointers[0],
            workingStep.primaryWorkingStep
          )
          .then((summaries) => {
            setResourceOptions(
              summaries.filter((x) => x.id !== workingStep.resource?.id)
            )
          })
      }

      setOpen(!open)
    },
    [
      bomItemPointers,
      controller,
      open,
      projectEditable,
      workingStep.primaryWorkingStep,
      workingStep.resource?.id,
      workingStep.resource?.matchType,
    ]
  )

  return (
    <span>
      {workingStep.resource?.id && (
        <Select
          value={
            workingStep.resource?.id &&
            workingStep.resource?.id !== '00000000-0000-0000-0000-000000000000'
              ? workingStep.resource?.id
              : ''
          }
          required
          onChange={handleChange}
          label={disableLabel ? null : t('project:resource')}
          open={open}
          fullWidth
          onClick={handleClick}
          onClose={() => {
            setOpen(false)
            setResourceOptions([])
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          disableUnderline={disableUnderline}
          disabled={
            workingStep.resource?.matchType ===
              ResourceMatchType.OneCapableResource || !projectEditable
          }
          renderValue={() => {
            return (
              <Typography
                variant={workingStep.resource?.id ? 'caption' : 'caption'}
                color={workingStep.resource?.id ? 'textPrimary' : 'GrayText'}
                style={{
                  whiteSpace: 'nowrap',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                {workingStep.resource?.name ??
                  t('project:select-resource', 'select a resource')}
              </Typography>
            )
          }}
        >
          {loading[
            `get-selectable-resources-${bomItemPointers[0]}-${workingStep.primaryWorkingStep}`
          ] && (
            <MenuItem value="">
              <Typography variant="caption">{`${t(
                'common:loading'
              )}...`}</Typography>
            </MenuItem>
          )}
          {
            // this one is here to not have the @mui runtime warning
            // about current value not being in the options.
            // notice the display: none
            workingStep.resource?.id &&
              workingStep.resource?.id !==
                '00000000-0000-0000-0000-000000000000' && (
                <MenuItem
                  value={workingStep.resource?.id}
                  style={{ display: 'none' }}
                  sx={{
                    borderBottom: (theme) =>
                      `2px solid ${theme.palette.divider}`,
                    gap: '8px',
                  }}
                >
                  <Typography variant="caption">
                    {workingStep.resource?.name}
                  </Typography>
                </MenuItem>
              )
          }
          {resourceOptions
            ?.filter((x) => x.id !== workingStep?.resource?.id)
            .map((x) => {
              return (
                <MenuItem key={x.id} value={x.id}>
                  <Typography variant="caption">{x.name}</Typography>
                </MenuItem>
              )
            })}
        </Select>
      )}
      <Typography variant="caption" color="GrayText">
        {getHelperText()}
      </Typography>
    </span>
  )
}
