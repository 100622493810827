import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import ValueLabel from 'components/Common/ValueLabel/ValueLabel'
import { MoneyString } from 'model/Money'
import { QuantityString } from 'model/Quantity'
import {
  PriceRowDto,
  PriceScope,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import {
  FINANCIAL_DETAILS_VALUE_LABEL_WIDTH,
  useFinancialDetailsFormStyles,
} from './FinancialDetailsForm.styles'

import { CategoryOutlined } from '@mui/icons-material'
import InfoOverIcon from '_icons/info_over-sm.svg?react'
import DownloadIcon from '_icons/project-menu/download.svg?react'
import { useAppController } from 'customHooks/useAppController'
import { BomItemActivityController } from 'features/BillOfMaterials/components/BomItemActivities/BomItemActivityController'
import { bomItemActivityByArticleIdSelector } from 'features/BillOfMaterials/store/selectors/activities/bomItemActivityByArticleIdSelector'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store/configureStore'

type Props = {
  bomItemPointer: BomItemPointer
  materialRow: PriceRowDto
  setActiveCostPriceExplanation: (priceRow: PriceRowDto) => void
  priceScope: PriceScope
}

export function PriceDetailsMaterialrow(props: Props) {
  const { classes, cx } = useFinancialDetailsFormStyles()
  const { t } = useTranslation()

  const activity = useAppSelector(
    bomItemActivityByArticleIdSelector(
      props.bomItemPointer,
      props.materialRow.materialId
    ),
    isEqual
  )

  const { controller } = useAppController(() => new BomItemActivityController())

  return (
    <li className={classes.row}>
      <div className={cx(classes.header)}>
        <div className={classes.resourceDescription}>
          <Box
            sx={{
              display: 'flex',
              gap: (theme) => theme.spacing(2),
              alignItems: 'center',
              maxWidth: '70%',
            }}
            title={t('project:material')}
          >
            <CategoryOutlined
              sx={{
                color: (theme) => theme.iconColors.outline,
                width: '24px',
              }}
            />
            <div>
              <Typography variant="body2">
                {props.materialRow.description}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {props.materialRow.materialId}
              </Typography>
            </div>
          </Box>

          <IconButton
            size="small"
            onClick={async () => {
              if (activity?.usesRawMaterial) {
                props.setActiveCostPriceExplanation(props.materialRow)
              } else {
                await controller.DownloadMaterialEstimatorRequest({
                  boMItemActivityId: activity?.id,
                  boMItemId: props.bomItemPointer.id,
                })
              }
            }}
          >
            {activity?.usesRawMaterial ? (
              <InfoOverIcon width="21px" />
            ) : (
              <DownloadIcon width="21px" />
            )}
          </IconButton>
        </div>
      </div>
      <div className={classes.values}>
        <Typography variant="body2" style={{ textAlign: 'right' }}>
          -
        </Typography>
        <Typography variant="body2" style={{ textAlign: 'right' }}>
          -
        </Typography>
        <Tooltip
          placement="top"
          title={
            <>{`${t('project:costs-additional')}: ${MoneyString(
              props.materialRow?.additionalProductionCosts,
              10
            )}`}</>
          }
        >
          <span style={{ textAlign: 'right' }}>
            <ValueLabel
              valueLabelRootStyles={{
                width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH,
              }}
              valueProps={{
                variant: 'body2',
              }}
              value={MoneyString(props.materialRow?.additionalProductionCosts)}
            />
          </span>
        </Tooltip>
        <Tooltip
          placement="top"
          title={
            <>{`${t('project:costs-total')}: ${MoneyString(
              props.materialRow?.costPrice,
              10
            )}`}</>
          }
        >
          <span style={{ textAlign: 'right' }}>
            <ValueLabel
              valueLabelRootStyles={{
                width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH,
              }}
              valueProps={{
                variant: 'body2',
              }}
              value={MoneyString(props.materialRow?.costPrice)}
            />
          </span>
        </Tooltip>

        <Tooltip
          placement="top"
          title={
            <>{`${t('common:surcharge')}: ${MoneyString(
              props.materialRow?.surchargeValue,
              10
            )}`}</>
          }
        >
          <span style={{ textAlign: 'right', position: 'relative' }}>
            <ValueLabel
              valueLabelRootStyles={{
                width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH,
              }}
              valueProps={{
                variant: 'body2',
              }}
              value={MoneyString(props.materialRow?.surchargeValue)}
            />
            <Typography
              color="textSecondary"
              variant="caption"
              style={{
                position: 'absolute',
                bottom: '-1.25em',
                right: 0,
              }}
            >
              {QuantityString(props.materialRow.surchargeRatio)}
            </Typography>
          </span>
        </Tooltip>

        <Tooltip
          placement="top"
          title={
            <>{`${t('common:discount')}: ${MoneyString(
              props.materialRow?.discountValue,
              10
            )}`}</>
          }
        >
          <span style={{ textAlign: 'right', position: 'relative' }}>
            <ValueLabel
              valueLabelRootStyles={{
                width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH,
              }}
              valueProps={{
                variant: 'body2',
              }}
              value={MoneyString(props.materialRow?.discountValue)}
            />

            <Typography
              color="textSecondary"
              variant="caption"
              style={{
                position: 'absolute',
                bottom: '-1.25em',
                right: 0,
              }}
            >
              {QuantityString(props.materialRow?.discountRatio)}
            </Typography>
          </span>
        </Tooltip>

        <Tooltip
          placement="top"
          title={
            <>{`${t('project:sales-price')}: ${MoneyString(
              props.materialRow?.salesPrice,
              10
            )}`}</>
          }
        >
          <span style={{ textAlign: 'right' }}>
            <ValueLabel
              valueLabelRootStyles={{
                width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH,
              }}
              valueProps={{
                variant: 'body2',
              }}
              value={MoneyString(props.materialRow?.salesPrice)}
            />
          </span>
        </Tooltip>
      </div>
    </li>
  )
}
