import MaterialTable from '@material-table/core'
import { MenuItem, Paper, TextField } from '@mui/material'
import { LoadingContainer } from 'components/Common/LoadingContainer'
import { useAppController } from 'customHooks/useAppController'
import { MixPanelDashboardsController } from 'features/MixPanelDashboards/MixPanelDashboards.controller'
import { MixPanelDashboardsAdminController } from 'features/MixPanelDashboards/MixPanelDashboardsAdminController'
import { useEffect, useState } from 'react'
import {
  DashBoardDto,
  DashboardType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'

type Props = {
  partyId: string
}
export const DashboardManagement = (props: Props) => {
  const { controller: getDashboardsController, loading: getDashboardsLoading } =
    useAppController(() => new MixPanelDashboardsController(props.partyId))
  const { controller: adminController } = useAppController(
    () => new MixPanelDashboardsAdminController(props.partyId)
  )

  const [dashboards, setDashboards] = useState<DashBoardDto[] | undefined>(
    undefined
  )

  useEffect(() => {
    getDashboardsController
      .getDashboards()
      .then((res) => {
        res.dashboards = res.dashboards.map((d, i) => {
          return {
            ...d,
            id: `${d.dashboardType}-${i}`,
          }
        })
        setDashboards(res.dashboards)
      })
      .catch((err) => {
        console.info('no dashboard found', err)
      })
  }, [getDashboardsController])

  return (
    <div>
      <LoadingContainer
        loading={getDashboardsLoading['getDashboards']}
        noMessage
      >
        {dashboards && (
          <MaterialTable
            options={{
              paging: false,
              showTitle: true,
              toolbar: true,
              search: false,
              grouping: false,
              actionsColumnIndex: -1,
            }}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
            }}
            title="Dashboards"
            columns={[
              {
                id: 'dashboardType',
                title: 'Dashboard Type',
                field: 'dashboardType',
                width: 180,
                editComponent: (params) => {
                  return (
                    <TextField
                      select
                      value={params.value?.toLowerCase() || ''}
                      onChange={(e) =>
                        params.onChange(e.target.value.toLowerCase())
                      }
                      placeholder={'Select Dashboard Type'}
                    >
                      {Object.values(DashboardType)
                        .filter(
                          (dashboardType) =>
                            dashboards.findIndex(
                              (d) => d.dashboardType === dashboardType
                            ) === -1
                        )
                        .map((dashboardType, i) => (
                          <MenuItem key={i} value={dashboardType.toLowerCase()}>
                            {dashboardType}
                          </MenuItem>
                        ))}
                    </TextField>
                  )
                },
              },
              {
                title: 'Dashboard Url',
                field: 'dashboardUrl',
              },
            ]}
            data={dashboards}
            editable={{
              onRowDelete: async (oldData) => {
                try {
                  await adminController.deleteDashboard(
                    (oldData as DashBoardDto).dashboardType as DashboardType
                  )
                } catch (err) {
                  ShowException('management', err)
                } finally {
                  setDashboards(
                    (await getDashboardsController.getDashboards()).dashboards
                  )
                }
              },
              onRowUpdate: async (newData) => {
                try {
                  await adminController.setDashboard({
                    dashboardType: newData.dashboardType as DashboardType,
                    dashboardUrl: (newData as DashBoardDto).dashboardUrl,
                  })
                } catch (err) {
                  ShowException('management', err)
                } finally {
                  setDashboards(
                    (await getDashboardsController.getDashboards()).dashboards
                  )
                }
              },
              onRowAdd: async (newData) => {
                try {
                  await adminController.setDashboard({
                    dashboardType: newData.dashboardType as DashboardType,
                    dashboardUrl: (newData as DashBoardDto).dashboardUrl,
                  })

                  setDashboards(
                    (await getDashboardsController.getDashboards()).dashboards
                  )
                } catch (err) {
                  ShowException('management', err)
                }
              },
            }}
          />
        )}
      </LoadingContainer>
    </div>
  )
}
