import MaterialTable from '@material-table/core'
import {
  CheckOutlined,
  ErrorOutlined,
  WarningOutlined,
} from '@mui/icons-material'
import { Dialog, DialogActions, useTheme } from '@mui/material'
import { CancelButton } from 'components/Common/Button/CancelButton'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { useAppController } from 'customHooks/useAppController'
import { useMaterialTableLocalization } from 'customHooks/useMaterialTableLocalization'
import _ from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useEffect, useState } from 'react'
import {
  EventLogDto,
  EventLogSeverityDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { EventLogsController } from '../EventLogsController'

type EventLogType = 'manufacturability' | 'erp'

export type EventLogsDialogProps = {
  eventLogType?: EventLogType
  bomItemPointer: BomItemPointer
  open?: boolean
  onClose: () => void
}

const page_title = 'event logs'

const SeverityIcon = (props: { severity: EventLogDto['severity'] }) => {
  const theme = useTheme()

  switch (props.severity) {
    case EventLogSeverityDto.Information:
      return (
        <CheckOutlined
          htmlColor={theme.palette.info.main}
          style={{ alignSelf: 'center' }}
        />
      )
    case EventLogSeverityDto.Warning:
      return <WarningOutlined htmlColor={theme.palette.warning.main} />
    case EventLogSeverityDto.Error:
      return <ErrorOutlined htmlColor={theme.palette.error.main} />
    default:
      return <>{props.severity}</>
  }
}

export const EventLogsDialog = (props: EventLogsDialogProps) => {
  const [events, setEvents] = useState<Array<EventLogDto>>([])
  const { controller, loading } = useAppController(
    () => new EventLogsController()
  )
  const materialTableLocalization = useMaterialTableLocalization()

  useEffect(() => {
    ;(async () => {
      try {
        const getEventLogs = (eventType: EventLogType) =>
          controller.GetEventLogs(eventType, props.bomItemPointer)

        setEvents(
          _.flatten(
            await Promise.all([
              getEventLogs('erp'),
              getEventLogs('manufacturability'),
            ])
          )
        )

        // setEvents([...erpLogs, ...manufactLogs])
      } catch (err) {
        ShowException(page_title, err)
      }
    })()
  }, [controller, props.bomItemPointer, props.eventLogType])

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="lg" fullWidth>
      <MaterialTable
        localization={materialTableLocalization}
        title={
          <LocalizedTypography
            translationKey={'common:event-logs-title'}
            variant="h6"
          >
            Event Logs
          </LocalizedTypography>
        }
        options={{
          search: false,
          grouping: true,
          showEmptyDataSourceMessage: true,
          emptyRowsWhenPaging: false,
          maxBodyHeight: '70vh',
          defaultExpanded: true,
          defaultOrderByCollection: [],
        }}
        isLoading={loading['get-event-logs']}
        data={events}
        columns={[
          {
            field: 'category',
            title: 'category',
            defaultGroupOrder: 0,
            groupRender: (groupKey) => {
              return (
                <LocalizedTypography
                  translationKey={`common:event-log-category-${groupKey}`}
                  translationDefaultValue={groupKey as string}
                >
                  {groupKey}
                </LocalizedTypography>
              )
            },
            render: (rowData) => {
              return rowData.category
            },
          },
          {
            field: 'severity',
            title: 'severity',
            headerStyle: {
              textAlign: 'center',
            },
            cellStyle: (_, rowData) => {
              return {
                textAlign: rowData ? 'center' : 'left',
              }
            },
            groupRender: (groupKey) => {
              return (
                <LocalizedTypography
                  translationKey={`common:severity-${groupKey}`}
                >
                  {groupKey}
                </LocalizedTypography>
              )
            },
            render: (data) => {
              return <SeverityIcon severity={data.severity} />
            },
          },
          {
            field: 'message',
            title: 'message',
            grouping: false,
            width: '80%',
            headerStyle: {
              width: '80%',
            },
          },
          {
            title: 'date',
            field: 'dateTime',
            type: 'datetime',
            defaultSort: 'desc',
            headerStyle: {
              textAlign: 'center',
            },
          },
          {
            field: 'channel',
            title: 'channel',
            headerStyle: {
              textAlign: 'center',
            },
            cellStyle: {
              textAlign: 'center',
            },
          },
          {
            field: 'source',
            title: 'source',
            headerStyle: {
              textAlign: 'center',
            },
            cellStyle: {
              textAlign: 'center',
            },
          },
        ]}
      />
      {/* <pre>{JSON.stringify(eventLogs, null, 2)}</pre> */}
      <DialogActions>
        <CancelButton
          onClick={props.onClose}
          labelTranslationKey={'common:close'}
        />
      </DialogActions>
    </Dialog>
  )
}
