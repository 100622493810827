import { Box, Dialog, DialogContent, Typography, styled } from '@mui/material'
import { CancelButton } from 'components/Common/Button/CancelButton'
import { useBomItemFeatures } from 'components/ProjectPage/BillOfMaterials/RowDetails/useBomItemFeatures'
import { BomItemModelViewer } from 'features/ModelViewer/cadexchanger/BoMItemModelViewer'
import { useModelViewInstance } from 'features/ModelViewer/cadexchanger/manager/useManagerInstance'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useCallback, useEffect, useState } from 'react'
import {
  WorkingStepDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'
import { projectSelectors } from '../store/selectors/projectSelectors'
import { BomItemDetailsMenu } from './BomItemDetailsMenu'
import { ModelVisibilitySwitches } from './ModelVisibilitySwitches'

export type BomItemDetailsV2DialogProps = {
  bomItemPointer: BomItemPointer
  open: boolean
  onClose?: () => void
  activeWorkingStep?: WorkingStepType
}

const ViewContainer = styled('div')(({ theme }) => ({
  width: '100%',
  paddingTop: theme.spacing(1),
}))

const ModelsContainer = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  height: 'calc(100% - 80px)',
  flexDirection: 'row',
  position: 'relative',
}))

const HeaderContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  display: 'flex',
  justifyContent: 'space-between',
}))

export const BomItemDetailsV2_Dialog = (props: BomItemDetailsV2DialogProps) => {
  const bomItemName = useAppSelector(
    projectSelectors.bomItemNameSelector(props.bomItemPointer)
  )
  const model2dFilePath = useAppSelector(
    projectSelectors.bomItem2DPathSelector(props.bomItemPointer)
  )

  const [show3dModel, setShow3dModel] = useState(true)
  const [show2dModel, setShow2dModel] = useState(true)

  const [modelViewer3d] = useModelViewInstance(props.bomItemPointer)
  const [modelViewer2d] = useModelViewInstance(props.bomItemPointer)

  const [features, loadingFeatures] = useBomItemFeatures(props.bomItemPointer)

  useEffect(() => {
    if (show3dModel) {
      modelViewer3d.SetModelFeatures(features)
    }
  }, [features, modelViewer3d, show3dModel])

  const handleShowModel = useCallback(
    (modelType: '2d' | '3d', show: boolean) => {
      setTimeout(() =>
        modelType === '2d' ? setShow2dModel(show) : setShow3dModel(show)
      )
    },
    []
  )

  const handleInputModel = useCallback(
    (workingStep: WorkingStepDto): void => {
      if (workingStep.usesRawMaterial) {
        return null
      }

      if (workingStep.primaryWorkingStep === WorkingStepType.SheetBending) {
        handleShowModel('2d', true)
        handleShowModel('3d', false)

        return
      }

      handleShowModel('3d', true)
      handleShowModel('2d', false)
    },
    [handleShowModel]
  )

  const handleOutputModel = useCallback(
    (workingStep: WorkingStepDto): void => {
      if (handleShowModel) {
        if (workingStep.usesRawMaterial) {
          handleShowModel('2d', true)
          handleShowModel('3d', false)
        } else {
          handleShowModel('3d', true)
          handleShowModel('2d', false)
        }
      }
    },
    [handleShowModel]
  )

  return (
    <Dialog
      open={props.open}
      onClose={(e, r) => {
        if (r === 'backdropClick') {
          return
        }

        props.onClose && props.onClose()
      }}
      maxWidth={'xxxxl'}
      fullWidth
      keepMounted={false}
    >
      {/* <DialogTitle>{bomItemName}</DialogTitle> */}
      <DialogContent
        sx={{
          padding: 0,
          overflowX: 'hidden',
          minHeight: '90vh',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: 'calc(90vh)',
            gap: (theme) => theme.spacing(1),
          }}
        >
          <BomItemDetailsMenu
            bomItemPointer={props.bomItemPointer}
            modelController={modelViewer3d}
            onShowModel={handleShowModel}
            activeWorkingStep={props.activeWorkingStep}
            handleInputModel={handleInputModel}
            handleOutputModel={handleOutputModel}
            features={features}
            loadingFeatures={loadingFeatures}
          />
          <div style={{ width: '100%' }}>
            <HeaderContainer>
              <Typography variant="h6">{bomItemName}</Typography>
              {model2dFilePath && (
                <ModelVisibilitySwitches
                  show2dModel={show2dModel}
                  setShow2dModel={setShow2dModel}
                  show3dModel={show3dModel}
                  setShow3dModel={(show) => {
                    setShow3dModel(show)
                  }}
                />
              )}
            </HeaderContainer>
            <ModelsContainer
              onContextMenu={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              {
                <ViewContainer
                  style={{
                    display: show3dModel ? 'block' : 'none',
                  }}
                >
                  <BomItemModelViewer
                    bomItemPointer={props.bomItemPointer}
                    show3DOr2D="3D"
                    modelController={modelViewer3d}
                  />
                </ViewContainer>
              }
              {model2dFilePath && (
                <ViewContainer
                  style={{
                    display: show2dModel ? 'block' : 'none',
                  }}
                >
                  <BomItemModelViewer
                    modelController={modelViewer2d}
                    bomItemPointer={props.bomItemPointer}
                    show3DOr2D="2D"
                  />
                </ViewContainer>
              )}
              {model2dFilePath && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: (theme) => theme.spacing(2),
                    left: 0,
                  }}
                ></Box>
              )}
            </ModelsContainer>
          </div>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(1),
          }}
        >
          <CancelButton
            onClick={props.onClose}
            labelTranslationKey="common:close"
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default BomItemDetailsV2_Dialog
