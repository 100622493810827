/* eslint-disable react/display-name */
import MaterialTable from '@material-table/core'
import { Dialog, DialogActions, DialogContent, Paper } from '@mui/material'
import { LocalizedButton } from 'components/Localization/LocalizedButton'
import { useMaterialTableLocalization } from 'customHooks/useMaterialTableLocalization'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store/configureStore'
import { useAppDispatch } from 'store/configureStore'
import { IssueDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { APICall, ErrorMessage } from 'store/Application/appActions'
import { useIsLoading } from 'utils/useOrganizationContext'
import './IssuesDialog.scss'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemType } from 'model/Project/BoMItemRow'

export type IssuesDialogProps = {
  bomItemPointer: BomItemPointer
  open: boolean
  onClose: () => void
}

export const IssuesDialog = (props: IssuesDialogProps) => {
  const { t } = useTranslation()
  const materialTableLocalization = useMaterialTableLocalization()

  const [issueList, setIssueList] = useState<IssueDto[]>([])

  const partTypes = useAppSelector((state) => state.project.partTypes)

  const { organizationId, projectId, networkLabel, isLoading } =
    useIsLoading('get-progress')

  const dispatch = useAppDispatch()

  useEffect(() => {
    const url =
      props.bomItemPointer.id === projectId
        ? `api/parties/${organizationId}/projects/${projectId}/issues`
        : `api/parties/${organizationId}/projects/${projectId}/items/${props.bomItemPointer}/issues`

    dispatch(
      APICall({
        label: networkLabel,
        configuration: {
          method: 'GET',
          url,
          onSuccess: (data: IssueDto[]) => {
            setIssueList(data)
          },
          onError: (message) => {
            ErrorMessage('progress', message)
          },
        },
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setValidationHightlight = (rowId) => {
    const bomItemToOpen = { id: rowId, type: BomItemType.partType }
    // dispatch(oldProjectActions.RemoveAllValidationHightlight())
    dispatch(
      newProjectActions.setBoMItemOpen({
        bomItemPointer: bomItemToOpen,
        isOpen: true,
      })
    )

    dispatch(newProjectActions.setBomItemInSearch(bomItemToOpen))
    props.onClose()
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="xl">
      <DialogContent style={{ height: '60vh', padding: 0 }}>
        <MaterialTable
          isLoading={isLoading}
          title={t('project:progress-modal--title', 'issues')}
          options={{
            emptyRowsWhenPaging: false,
            paging: issueList?.length > 10,
            pageSize: 10,
            pageSizeOptions: [10, 20, 30],
            showFirstLastPageButtons: true,
            paginationType: 'stepped',
            exportAllData: false,
            actionsColumnIndex: -1,
            draggable: true,
            grouping: true,
            maxBodyHeight: '44.5vh',
            headerStyle: {
              fontWeight: 'bold',
            },
            showTitle: true,
            defaultOrderByCollection: [],
          }}
          localization={materialTableLocalization}
          columns={[
            {
              title: t('common:row-number', 'row number'),
              grouping: true,
              cellStyle: { textAlign: 'center', paddingRight: '40px' },
              headerStyle: {
                textAlign: 'center',
                width: '2%',
              },
              render: (data) => partTypes[data.itemId]?.rowNumber,
            },
            {
              title: t('common:severity'),
              field: 'severityDescription',
              grouping: true,
            },
            {
              title: t('common:category'),
              field: 'categoryDescription',
              grouping: true,
            },
            {
              title: t('common:description'),
              field: 'description',
              grouping: false,
            },
            {
              title: t('common:type'),
              field: 'typeDescription',
              grouping: true,
            },
            {
              title: t('common:date'),
              field: 'timestamp',
              grouping: false,
              type: 'date',
            },
          ]}
          data={issueList}
          onRowClick={(ev, rowData) => {
            setValidationHightlight(rowData.itemId)
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
        />
      </DialogContent>
      <DialogActions>
        <LocalizedButton
          translationKey="common:close"
          onClick={props.onClose}
        />
      </DialogActions>
    </Dialog>
  )
}
