import { ReplayOutlined } from '@mui/icons-material'
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { fetchProjectSummaries } from 'store/ProjectList/asyncActions/fetchProjectSummaries'
import { useAppDispatch } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles({ name: 'ProjectListPage' })((theme) => ({
  helpers: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: theme.spacing(4),
  },
}))

export function ProjectsPageHelpers(props: {
  onlyMyProjects: boolean
  handleOnlyMyProjects: (filterMyProjects: boolean) => void
  handleReload?: () => void
  showCanceledProjects: boolean
  handleShowCanceledProjects: (showCanceledProjects: boolean) => void
  reloading?: boolean
}) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { classes } = useStyles()

  return (
    <div className={classes.helpers}>
      <IconButton
        size="small"
        onClick={() =>
          props.handleReload
            ? props.handleReload()
            : dispatch(fetchProjectSummaries({ subscribeToUpdates: false }))
        }
        disabled={props.reloading}
      >
        {props.reloading ? <CircularProgress size={16} /> : <ReplayOutlined />}
      </IconButton>
      <div>
        <Tooltip
          title={
            <Typography variant="caption">
              {t(
                'common:my-projects-filter-tooltip',
                'show projects where my user is the author or the sales representative'
              )}
            </Typography>
          }
          arrow
        >
          <FormControlLabel
            onClick={(e) => e.stopPropagation()}
            control={
              <Checkbox
                checked={props.onlyMyProjects}
                onChange={(e) => {
                  e.preventDefault()
                  props.handleOnlyMyProjects(e.target.checked)
                }}
                size="small"
              />
            }
            label={
              <Typography variant="caption">
                {t('common:my-projects', 'my projects')}
              </Typography>
            }
          />
        </Tooltip>
        <Tooltip
          title={
            <Typography variant="caption">
              {t(
                'common:show-canceled-projects-tooltip',
                'show canceled projects'
              )}
            </Typography>
          }
          arrow
        >
          <FormControlLabel
            onClick={(e) => e.stopPropagation()}
            control={
              <Checkbox
                checked={props.showCanceledProjects}
                onChange={(e) => {
                  e.preventDefault()
                  props.handleShowCanceledProjects(e.target.checked)
                }}
                size="small"
              />
            }
            label={
              <Typography variant="caption">
                {t(
                  'common:show-canceled-projects-label',
                  'canceled or expired projects'
                )}
              </Typography>
            }
          />
        </Tooltip>
      </div>
    </div>
  )
}
