import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import _ from 'lodash'
import { useAppSelector } from 'store/configureStore'
import { useAppDispatch } from 'store/configureStore'
import { RootAppState } from 'store/configureStore'

export const useArticleIdFilter = () => {
  const dispatch = useAppDispatch()

  const articleIdFilter = useAppSelector(
    (state: RootAppState) => state.project.filters?.byArticleId,
    _.isEqual
  )

  const handleChangeFilter = (articleId: string) => {
    const newFilter = { ...articleIdFilter.filter }

    if (newFilter.enabledFilters.includes(articleId)) {
      newFilter.enabledFilters = newFilter.enabledFilters.filter(
        (x) => x !== articleId
      )
    } else {
      newFilter.enabledFilters = [articleId]
    }

    if (!articleId || newFilter.enabledFilters.length === 0) {
      dispatch(
        newProjectActions.toggleActiveFilter({
          filterType: 'ArticleIdFilter',
          open: false,
        })
      )
    } else {
      dispatch(
        newProjectActions.setFilter({
          filter: {
            type: 'ArticleIdFilter',
            enabledFilters: newFilter.enabledFilters,
          },
        })
      )
    }
  }

  const filterIsActive = (articleId: string) => {
    return (
      articleIdFilter.active &&
      articleIdFilter.filter.enabledFilters.some((x) => x === articleId)
    )
  }

  return {
    handleChangeFilter,
    articleIdFilter,
    filterIsActive,
  }
}
