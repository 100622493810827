import { MenuItem, Select, Typography } from '@mui/material'
import { BomItemController } from 'controllers/Project/BomItemController'
import { useAppController } from 'customHooks/useAppController'
import { fetchCurrentProject } from 'features/BillOfMaterials/store/asyncActions/fetchProject'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import { ProjectEditableStateSelector } from 'features/BillOfMaterials/store/selectors/ProjectEditableStateSelector'
import { bomItemActivitiesSelectors } from 'features/BillOfMaterials/store/selectors/activities/bomItemActivitiesSelectors'
import { isEqual } from 'lodash'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BoMItemActivityDto,
  ResourceMatchType,
  ResourceSummaryDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { useOrganizationAndProjectContext } from 'utils/useOrganizationContext'

type Props = {
  bomItemPointers: BomItemPointer[]
  bomItemActivity: BoMItemActivityDto
  onFocus?: () => void
  onBlur?: () => void
  disableLabel?: boolean
  disableUnderline?: boolean
  disableMatchDescription?: boolean
  disableSelectMaterialError?: boolean
}

export const ActivityResourceSelector = ({
  bomItemPointers,
  bomItemActivity: activity,
  onFocus,
  onBlur,
  disableLabel,
  disableUnderline,
  disableMatchDescription,
  disableSelectMaterialError,
}: Props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const materialSummary = useAppSelector(
    bomItemActivitiesSelectors.byActivityId(bomItemPointers[0], activity?.id),
    isEqual
  )?.article

  const [open, setOpen] = useState(false)

  // const operationStatus = useAppSelector(
  //   projectOperationSelector(saveBomItemResource.typePrefix),
  //   isEqual
  // )

  const projectEditable = useAppSelector(ProjectEditableStateSelector)

  const { partyId, projectId } = useOrganizationAndProjectContext()
  const { controller, loading, error } = useAppController(
    () => new BomItemController(partyId, projectId)
  )

  const [resourceOptions, setResourceOptions] =
    useState<Array<ResourceSummaryDto>>()

  const handleChange = async (e) => {
    try {
      bomItemPointers.forEach((bomItemPointer) => {
        dispatch(
          newProjectActions.setBomItemActivity({
            bomItemPointer: bomItemPointer,
            activityId: activity?.id,
            activity: {
              resource: {
                id: e.target.value,
                name: resourceOptions.find((x) => x.id === e.target.value).name,
              },
            },
          })
        )
      })

      await controller.SetBomItemResource(
        bomItemPointers,
        activity?.primaryWorkingStep,
        e.target.value
      )
    } catch (err) {
      ShowException('project', err)
      dispatch(fetchCurrentProject())
    }
  }

  const getHelperText = useCallback(() => {
    let helperText =
      error[`get-selectable-resources-${bomItemPointers[0]}`]?.message

    if (
      bomItemPointers[0]?.type === BomItemType.partType &&
      !materialSummary?.id &&
      !disableSelectMaterialError
    ) {
      helperText = t(
        'project:select-material-before-selecting-resource',
        'material group or quality must be selected before selecting a resource'
      )
    }

    if (!helperText && !disableMatchDescription) {
      helperText = t(activity?.resource?.matchDescription)
    }

    return helperText
  }, [
    bomItemPointers,
    disableMatchDescription,
    disableSelectMaterialError,
    error,
    materialSummary?.id,
    t,
    activity?.resource?.matchDescription,
  ])

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()

      if (!projectEditable) {
        return
      }

      if (!open) {
        controller
          .GetBomItemSelectableResources(
            bomItemPointers[0],
            activity?.primaryWorkingStep
          )
          .then((summaries) => {
            setResourceOptions(
              summaries.filter((x) => x.id !== activity?.resource?.id)
            )
            setOpen(true)
          })
      }
    },
    [
      projectEditable,
      open,
      controller,
      bomItemPointers,
      activity?.primaryWorkingStep,
      activity?.resource?.id,
    ]
  )

  return (
    <span>
      {activity?.resource?.id && (
        <Select
          value={activity?.resource?.id}
          required
          onChange={handleChange}
          label={disableLabel ? null : t('project:resource')}
          open={open}
          fullWidth
          onClick={handleClick}
          onClose={() => {
            setOpen(false)
            setResourceOptions([])
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          disableUnderline={disableUnderline}
          disabled={
            activity?.resource?.matchType ===
              ResourceMatchType.OneCapableResource || !projectEditable
          }
          renderValue={() => {
            return (
              <Typography
                variant={activity?.resource?.id ? 'caption' : 'caption'}
                color={activity?.resource?.id ? 'textPrimary' : 'GrayText'}
                style={{
                  whiteSpace: 'nowrap',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                {activity?.resource?.name ??
                  t('project:select-resource', 'select a resource')}
              </Typography>
            )
          }}
        >
          {loading[
            `get-selectable-resources-${bomItemPointers[0]}-${activity?.primaryWorkingStep}`
          ] && (
            <MenuItem value="">
              <Typography variant="caption">{`${t(
                'common:loading'
              )}...`}</Typography>
            </MenuItem>
          )}
          {
            // this one is here to not have the @mui runtime warning
            // about current value not being in the options.
            // notice the display: none
            activity?.resource?.id && (
              // activity?.resource?.id !==
              //   '00000000-0000-0000-0000-000000000000' && (
              <MenuItem
                value={activity?.resource?.id}
                style={{ display: 'none' }}
                sx={{
                  borderBottom: (theme) => `2px solid ${theme.palette.divider}`,
                  gap: '8px',
                }}
              >
                <Typography variant="caption">
                  {activity?.resource?.name}
                </Typography>
              </MenuItem>
            )
          }
          {resourceOptions
            ?.filter((x) => x.id !== activity?.resource?.id)
            .map((x) => {
              return (
                <MenuItem key={x.id} value={x.id}>
                  <Typography variant="caption">{x.name}</Typography>
                </MenuItem>
              )
            })}
        </Select>
      )}
      <Typography variant="caption" color="GrayText">
        {getHelperText()}
      </Typography>
    </span>
  )
}
