import MaterialTable from '@material-table/core'
import Spinning from 'components/Common/Spinner/Spinning'
import { Container } from 'components/Layout/Container'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { useClientStorage } from 'customHooks/useClientStorage'
import queryString from 'query-string'
import { HTMLProps } from 'react'
import { Navigate } from 'react-router-dom'
import { Debugger } from 'utils/debugger'
import { NoAvailablePortals } from './NoAvailablePortals'
import { ProjectNotAllowedInWorkspace } from './ProjectNotAllowedInWorkspace'
import { useWorkspaces } from './useWorkspaces'

const PageTitle = (props: HTMLProps<HTMLDivElement>) => (
  <div {...props}>
    <LocalizedTypography
      translationKey="common:my-workspaces"
      variant="h4"
      color="primary"
    >
      workspaces
    </LocalizedTypography>
    <LocalizedTypography
      translationKey="common:my-workspaces--subtitle"
      variant="subtitle1"
      color="textSecondary"
      component="p"
    >
      select a workspace to start
    </LocalizedTypography>
  </div>
)

const Workspaces = () => {
  const {
    auth0,
    loading,
    location,
    organizations,
    currentPartyId,
    currentPartyName,
    availablePortals,
    classes,
    t,
    getProfileData,
    controller,
    withinIframe,
    materialTableLocalization,
    selectWorkspace,
  } = useWorkspaces()

  const { returnTo } = queryString.parse(window.location.search)

  const [storageReturnUrl] = useClientStorage(
    'loginWithRedirectReturnUrl',
    location.state?.returnUrl || ''
  )

  if (auth0.isLoading || !organizations) {
    return <Spinning fixed />
  }

  const returnUrl = returnTo || storageReturnUrl || location.state?.returnUrl

  if (currentPartyId && returnUrl && returnUrl !== '/app/workspaces') {
    Debugger.Info('routes: workspaces page, redirecting to', {
      returnUrl,
      currentPartyId,
    })
    return <Navigate to={returnUrl} state={location.state} replace />
  }

  if (
    organizations?.length === 0 &&
    (!availablePortals || availablePortals?.length === 0)
  ) {
    return (
      <NoAvailablePortals
        controller={controller}
        getProfileData={getProfileData}
        loading={loading}
      />
    )
  }

  Debugger.Info('routes: workingspaces page, no redirect', {
    currentPartyId,
    returnUrl,
  })

  return (
    <div className={classes.workspacesRoot}>
      <Container>
        <PageTitle className={classes.pageTitle} />
        {/* <Typography>Last visited workspace {lastVisitedWorkspace}</Typography> */}

        {location.state?.projectNotAllowedId && currentPartyName && (
          <ProjectNotAllowedInWorkspace
            projectId={location.state?.projectNotAllowedId}
            currentPartyName={currentPartyName}
          />
        )}

        <div className={classes.workspaces}>
          {organizations?.length ? (
            <div className={classes.workspaceTable}>
              <MaterialTable
                title={t('common:factories')}
                options={{
                  showTitle: !withinIframe,
                  paging: false,
                  header: false,
                  maxBodyHeight: 'calc(100vh - 280px)',
                }}
                data={organizations?.map((o) => ({ ...o }))}
                localization={materialTableLocalization}
                isLoading={loading['get-profile']}
                columns={[
                  {
                    field: 'name',
                  },
                ]}
                actions={[
                  {
                    icon: 'refresh_outlined',
                    onClick: () => getProfileData(),
                    disabled: loading['get-profile'],
                    isFreeAction: true,
                  },
                ]}
                onRowClick={(e, row) => {
                  selectWorkspace(row.id)
                }}
              />
            </div>
          ) : null}
          {availablePortals?.length ? (
            <div className={classes.workspaceTable}>
              <MaterialTable
                title={t('common:self-service-portal', 'self service portal')}
                isLoading={loading['get-profile']}
                options={{
                  showTitle: true,
                  paging: false,
                  header: false,
                  defaultOrderByCollection: [],
                }}
                data={availablePortals.map((x) => ({ ...x, id: x.url }))}
                localization={materialTableLocalization}
                columns={[
                  {
                    field: 'companyName',
                  },
                  {
                    field: 'url',
                  },
                ]}
                actions={[
                  {
                    icon: 'refresh_outlined',
                    onClick: () => getProfileData(),
                    isFreeAction: true,
                  },
                ]}
                onRowClick={(_, row) => window.location.replace(row.url)}
              />
            </div>
          ) : null}
        </div>
      </Container>
    </div>
  )
}

export default Workspaces
