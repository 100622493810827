import { Box, Grid, TextField, Typography } from '@mui/material'
import { CountrySelector } from 'components/Common/CountrySelector/CountrySelector'
import { RegionSelector } from 'components/Common/CountrySelector/RegionSelector'
import { AutoSaveWrapper } from 'components/Common/Form/Autosave'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import _ from 'lodash'
// import { TextField } from 'mui-rff'
import { HTMLProps } from 'react'
import { Field, FieldMetaState, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import {
  AddressDto,
  CountryDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { showFieldErrors } from 'utils/react-final-form-utils'
import './AddressForm.scss'

type Props = {
  address: AddressDto
  formTitle?: string
  variant: 'outlined' | 'filled' | 'standard'
  loading: boolean
  onChangeAddress: (address: AddressDto) => void | Promise<void>
  meta?: FieldMetaState<unknown>
} & HTMLProps<HTMLDivElement>

export const AddressForm = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { address, onChangeAddress, loading, formTitle, ...divProps } = props

  const { t } = useTranslation()

  const handleSubmit = (address: AddressDto) => {
    if (!_.isEqual(address, props.address)) {
      onChangeAddress(address)
    }
  }

  return (
    <div {...divProps} className={`${props.className}`}>
      {formTitle && (
        <Box marginBottom={2}>
          <Typography variant="h6">{formTitle}</Typography>
        </Box>
      )}
      <Form
        initialValues={address}
        onSubmit={handleSubmit}
        // validate={validateForm}
      >
        {(formProps) => {
          return (
            <div className="">
              <AutoSaveWrapper debounce={0} save={formProps.handleSubmit} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    onFocus={(e) => e.target.select()}
                    name="street"
                    label={
                      <LocalizedTypography translationKey="common:address-street">
                        street
                      </LocalizedTypography>
                    }
                    disabled={props.loading || props.disabled}
                    variant={props.variant}
                    // required={props.required}
                    autoComplete="street"
                    value={formProps?.values?.street || ''}
                    onChange={(e) =>
                      formProps.form.change('street', e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    onFocus={(e) => e.target.select()}
                    fullWidth
                    name="houseNumber"
                    value={formProps?.values?.houseNumber || ''}
                    onChange={(e) =>
                      formProps.form.change('houseNumber', e.target.value)
                    }
                    label={
                      <LocalizedTypography translationKey="common:address-house-number">
                        house number
                      </LocalizedTypography>
                    }
                    disabled={props.loading || props.disabled}
                    variant={props.variant}
                    autoComplete="houseNumber"
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    onFocus={(e) => e.target.select()}
                    fullWidth
                    name="houseNumberAddition"
                    value={formProps?.values?.houseNumberAddition || ''}
                    onChange={(e) =>
                      formProps.form.change(
                        'houseNumberAddition',
                        e.target.value
                      )
                    }
                    label={
                      <LocalizedTypography translationKey="common:address-house-number-addition">
                        addition
                      </LocalizedTypography>
                    }
                    disabled={props.loading || props.disabled}
                    variant={props.variant}
                    autoComplete="houseNumberAddition"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    onFocus={(e) => e.target.select()}
                    fullWidth
                    name="zipCode"
                    value={formProps?.values?.zipCode || ''}
                    onChange={(e) =>
                      formProps.form.change('zipCode', e.target.value)
                    }
                    label={
                      <LocalizedTypography translationKey="common:address-zipcode">
                        zip code
                      </LocalizedTypography>
                    }
                    variant={props.variant}
                    disabled={props.loading || props.disabled}
                    autoComplete="zipCode"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field<CountryDto> name="country">
                    {(fieldProps) => (
                      <CountrySelector
                        selectedCountryCode={fieldProps.input.value.code || ''}
                        onChange={(country) => {
                          fieldProps.input.onChange({
                            name: country.countryName,
                            code: country.countryShortCode,
                          })
                          formProps.form.change('stateOrProvince', undefined)
                        }}
                        onBlur={fieldProps.input.onBlur}
                        onFocus={fieldProps.input.onFocus}
                        disabled={props.loading || props.disabled}
                        TextFieldProps={{
                          variant: props.variant,
                          required: props.required,
                          helperText: showFieldErrors(fieldProps.meta)
                            ? ''
                            : fieldProps.meta.error,
                          error: Boolean(fieldProps.meta.error),
                          label: t('common:country'),
                          disabled: props.loading,
                          name: 'country',
                          inputProps: {
                            autoComplete: 'country',
                          },
                        }}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field<string> name="stateOrProvince">
                    {(fieldProps) => {
                      return (
                        <RegionSelector
                          countryCode={formProps?.values?.country?.code || ''}
                          selectedRegionName={fieldProps.input.value}
                          onRegionChange={(region) =>
                            fieldProps.input.onChange(region.name)
                          }
                          disabled={props.loading || props.disabled}
                          onFocus={fieldProps.input.onFocus}
                          onBlur={fieldProps.input.onBlur}
                          TextFieldProps={{
                            helperText: showFieldErrors(fieldProps.meta)
                              ? fieldProps.meta.error
                              : '',
                            error: showFieldErrors(fieldProps.meta),
                            label: t('common:state', 'state or province'),
                            variant: props.variant,
                            disabled: props.loading,
                            InputProps: {},
                            InputLabelProps: {
                              shrink: true,
                              disabled: props.loading,
                            },
                            inputProps: {
                              autoComplete: 'stateOrProvince',
                            },
                            name: 'stateOrProvince',
                          }}
                        />
                      )
                    }}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    onFocus={(e) => e.target.select()}
                    fullWidth
                    name="city"
                    value={formProps?.values?.city || ''}
                    onChange={(e) =>
                      formProps.form.change('city', e.target.value)
                    }
                    label={
                      <LocalizedTypography translationKey="common:city">
                        city
                      </LocalizedTypography>
                    }
                    variant={props.variant}
                    disabled={props.loading || props.disabled}
                    autoComplete="city"
                  />
                </Grid>
              </Grid>
            </div>
          )
        }}
      </Form>
    </div>
  )
}
