import { BomItemController } from 'controllers/Project/BomItemController'
import { useAppController } from 'customHooks/useAppController'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useEffect, useState } from 'react'
import { FeatureDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { useAppSelector } from 'store/configureStore'

export const useBomItemFeatures = (
  bomItemPointer: BomItemPointer
): [FeatureDto[], boolean] => {
  const { controller, loading } = useAppController(
    () => new BomItemController()
  )

  const [features, setFeatures] = useState<FeatureDto[]>(undefined)

  const typeId = useAppSelector(
    projectSelectors.partInstancePartTypeSelector(bomItemPointer),
    isEqual
  )

  useEffect(() => {
    controller
      .GetItemFeaturesDetails(typeId)
      .then((features) => {
        if (features?.features?.length === 0) {
          return null
        }
        setFeatures(features.features)
      })
      .catch((err) => ShowException('Item features', err))
  }, [bomItemPointer, controller, typeId])

  return [features, loading[`get-features-${bomItemPointer.id}`]]
}
