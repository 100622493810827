import { ChevronRightOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import MaterialPriceSummaryItem from 'features/BillOfMaterials/ProjectViews/DashboardView/ProjectOverview/components/MaterialPriceSummaryItem'
import { useEffect, useState } from 'react'
import { PriceSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { makeStyles } from 'tss-react/mui'

type Props = {
  materials: PriceSummaryDto[]
  forceOpen: boolean
}

const useStyles = makeStyles({ name: 'ProjectTotalsMaterialsRows' })(
  (theme) => {
    return {
      header: {
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.action.hover,
        cursor: 'pointer',
      },
    }
  }
)

export const ProjectTotalsMaterialsRows = (props: Props) => {
  const [open, setOpen] = useState(props.forceOpen)
  const { classes } = useStyles()

  useEffect(() => {
    setOpen(props.forceOpen)
  }, [props.forceOpen])

  return (
    <div>
      <div className={classes.header} onClick={() => setOpen((x) => !x)}>
        <IconButton>
          <ChevronRightOutlined
            style={{
              transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 0.2s ease-in-out',
            }}
          />
        </IconButton>
        <LocalizedTypography translationKey="common:materials" variant="body1">
          materials
        </LocalizedTypography>
      </div>
      {open &&
        props.materials.map((priceSummary) => (
          <div key={priceSummary.materialId}>
            <MaterialPriceSummaryItem
              materialPriceSummary={priceSummary}
              defaultMoneyObj={priceSummary.totalCostPrice}
            />
          </div>
        ))}
    </div>
  )
}
