import { lazy, Suspense, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import Spinning from './components/Common/Spinner/Spinning'

import { OnlyAuthenticated } from 'components/OnlyAuthenticated'
import { OnlyNOTAuthenticated } from 'components/OnlyNOTAuthenticated'
import { OnlyWithOrganization } from 'components/OnlyWithOrganization'
import AppError from './AppError'
import { Logout } from './features/Auth/Logout'

import HandleAuthentication from 'components/Common/HandleAuthentication'

const ForgotPassword = lazy(() => import('./features/Auth/ForgotPassword'))

const SettingsPage = lazy(
  () => import('./components/OrganizationSettings/SettingsPage')
)

import { CheckAppVersionRoute } from 'components/CheckAppVersion/CheckAppVersionRoute'
import { NewLoginPage } from 'features/Auth/NewLoginPage'
import GettingStartedPage from 'features/GettingStarted/GettingStartedPage'
import AppPage from 'pages/AppPage/AppPage'
import { NewPartiesPage } from 'pages/SystemManagementPage/ManagementPages/PartiesPages/NewPartiesPage'
import { Debugger } from 'utils/debugger'
import LandingPage from './components/LandingPage/LandingPage'
import Workspaces from './components/Organizations/WorkspacesPage'
// import ProjectContainer from './pages/ProjectPage/ProjectPage'

const ProjectContainer = lazy(() => import('./pages/ProjectPage/ProjectPage'))

const AcceptInvitePage = lazy(
  () => import('features/CustomerPortal/ConnectedParties/AcceptInvitePage')
)
const SystemManagementPage = lazy(
  () => import('pages/SystemManagementPage/SystemManagementPage')
)

const UserProfile = lazy(
  () => import('./components/OrganizationSettings/General/UserProfilePage')
)

const PartiesPage = lazy(
  () =>
    import(
      'pages/SystemManagementPage/ManagementPages/PartiesPages/PartiesPages'
    )
)

const InviteNotFoundPage = lazy(
  () =>
    import('features/CustomerPortal/ConnectedParties/InvitationNotFoundPage')
)

export const AppRoutes = () => {
  const location = useLocation()
  // const navigate = useNavigate()

  useEffect(() => {
    // checkAppVersion(navigate)

    window.parent?.postMessage(
      {
        type: 'RH24_EMBEDDED_LOCATION_CHANGE',
        payload: { ...location },
      },
      '*'
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  Debugger.Info('routes: AppRoutes', {
    location,
  })

  return (
    <Suspense fallback={<Spinning />}>
      <Routes>
        <Route path="/" element={<CheckAppVersionRoute />}>
          <Route path="/auth/callback/*" element={<HandleAuthentication />} />
          <Route path="/app/acceptinvite" element={<AcceptInvitePage />} />
          <Route path="/getting-started/*" element={<GettingStartedPage />} />
          {/* ONLY AUTHENTICATED ROUTES */}
          <Route path="/app" element={<OnlyAuthenticated />}>
            {/* ONLY ROUTES WITH ORGANIZATION_ID SETTED */}
            <Route path="" element={<OnlyWithOrganization />}>
              <Route index element={<AppPage />} />
              <Route path="settings/*" element={<SettingsPage />} />
              <Route path="project/:projectId" element={<ProjectContainer />} />
              <Route
                path="project/:projectId/bomItem/:bomItemId/:component?"
                element={<ProjectContainer />}
              />
              <Route
                path="system-management"
                element={<SystemManagementPage />}
              >
                <Route index element={<h3>System Management</h3>} />
                <Route path="parties" element={<NewPartiesPage />} />
                <Route path="old-parties-page" element={<PartiesPage />} />
              </Route>
            </Route>
            {/* END --- ONLY ROUTES WITH ORGANIZATION_ID SETTED */}

            <Route path="workspaces" element={<Workspaces />} />
            <Route path="invite-not-found" element={<InviteNotFoundPage />} />

            <Route
              path="user"
              element={<UserProfile containerMaxWidth={'lg'} />}
            />
          </Route>
          {/* END --- ONLY AUTHENTICATED ROUTES */}

          {/* ONLY NOT AUTHENTICATED ROUTES */}
          <Route element={<OnlyNOTAuthenticated />}>
            {/* <Route path="/login" element={<LoginWithErrorPage />} /> */}
            <Route path="/login" element={<NewLoginPage />} />
          </Route>
          {/* END --- ONLY NOT AUTHENTICATED ROUTES */}

          <Route path="/" element={<LandingPage />} />
          <Route path="/app/error" element={<AppError />} />

          {/* <Route path="/auth/callback/*" element={<HandleAuthentication />} /> */}
          <Route path="/logout" element={<Logout />} />

          <Route path="/auth/forgot-password" element={<ForgotPassword />} />

          <Route
            path="*"
            element={
              <Navigate
                replace
                to="/"
                state={{
                  returnUrl:
                    location.pathname + location.search + location.hash,
                }}
              />
            }
          />
        </Route>
      </Routes>
    </Suspense>
  )
}

export default AppRoutes
