import { Divider, Typography } from '@mui/material'
import { MoneyString } from 'model/Money'
import { QuantityString } from 'model/Quantity'
import { useTranslation } from 'react-i18next'
import { PriceSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { makeStyles } from 'tss-react/mui'
import { TargetPriceTooltipListItemText } from './TargetPriceTooltipListItemText'

const useStyles = makeStyles()((theme) => ({
  header: { padding: theme.spacing(1), textAlign: 'left' },
  // fields: {
  //   textAlign: 'right',
  // },
}))

export const ProjectLevelMaterialTooltip = (props: {
  data: PriceSummaryDto
}) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <div className={classes.header}>
      <Typography variant="body2">
        {props.data.description || t('project:materials', 'materials')}
      </Typography>
      <Typography variant="caption">{props.data.materialId}</Typography>

      <div style={{ textAlign: 'right' }}>
        <TargetPriceTooltipListItemText
          secondary={
            <Typography variant="body2" color="GrayText">
              {`${MoneyString(props.data.totalCostPrice)}`}
            </Typography>
          }
          primary={
            <Typography variant="caption">{t('project:cost-price')}</Typography>
          }
          color="secondary"
        />
        <TargetPriceTooltipListItemText
          secondary={
            <div>
              <Typography variant="body2">
                {`${MoneyString(props.data.surchargeValue)}`}
              </Typography>
              <Typography variant="caption" color="GrayText">
                {`(${QuantityString(props.data.surchargeRatio)})`}
              </Typography>
            </div>
          }
          primary={
            <Typography variant="caption">{t('project:surcharge')}</Typography>
          }
          color="secondary"
        />
        <TargetPriceTooltipListItemText
          secondary={
            <div>
              <Typography variant="body2">
                {`${MoneyString(props.data.discountValue)}`}
              </Typography>
              <Typography variant="caption" color="GrayText">
                {`(${QuantityString(props.data.discountRatio)})`}
              </Typography>
            </div>
          }
          primary={
            <Typography variant="caption">{t('project:discount')}</Typography>
          }
          color="secondary"
        />
        <Divider style={{ margin: '0 -24px' }} />
        <TargetPriceTooltipListItemText
          secondary={
            <Typography variant="body2">
              {`${MoneyString(props.data.totalSalesPrice)}`}
            </Typography>
          }
          primary={
            <Typography variant="caption">
              {t('project:sales-price')}
            </Typography>
          }
          color="secondary"
        />
      </div>
    </div>
  )
}
