import { isEqual } from 'lodash'
import { MoneyString } from 'model/Money'
import { memo } from 'react'
import { Text } from 'recharts'
import { MoneyDto } from 'services/APIs/InternalAPI/internal-api.contracts'

export const MoneyAxisTick = (props: {
  payload?: { value: number }
  defaultMoney: MoneyDto
}): JSX.Element => {
  if (props.payload.value === 0) return null

  if (!props.defaultMoney) return null

  return (
    <g>
      <Text {...props} fill="currentColor">
        {MoneyString({ ...props.defaultMoney, value: props.payload.value }, 0)}
      </Text>
    </g>
  )
}

export default memo(MoneyAxisTick, isEqual)
