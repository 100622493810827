import {
  Badge,
  Button,
  CircularProgress,
  DialogActions,
  Paper,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import { WorkingStepIconSvg } from 'components/Common/WorkingSteps/WorkingStepIcon'
import { useLocalizedWorkingStepLabel } from 'components/Localization/useLocalizedWorkingStepLabel'
import { CustomPopper } from 'components/Popper/CustomPopper'
import { WorkingStepsController } from 'controllers/WorkingStepsController'
import { BomItemActivityComment } from 'features/BillOfMaterials/WorkingSteps/BomItemActivityComment'
import { SolveWorkingStepNotAvailableButton } from 'features/BillOfMaterials/WorkingSteps/SolveNotAvailableWorkingStepButton'
import { useNotAllowedWorkingStep } from 'features/BillOfMaterials/WorkingSteps/useNotAllowedWorkingStep'
import { applyWorkingStepToAllParentFilteredRows } from 'features/BillOfMaterials/store/asyncActions/applyWorkingStepToAllParentFilteredRows'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import { bomItemSelector } from 'features/BillOfMaterials/store/selectors/bomItemSelector'
import { projectInBuyerViewSelector } from 'features/BillOfMaterials/store/selectors/projectInBuyerViewSelector'
import { projectOperationIsPendingSelector } from 'features/BillOfMaterials/store/selectors/projectOperationPendingSelector'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import { PurchasingPartDialogProps } from 'features/PurchasingPartDetails/PurchasingPartDialog'
import { AdditionalWorkingStepsDialogPropsV2 } from 'features/WorkingSteps/AdditionalWorkingStepsV2/DialogV2/AdditionalWorkingStepsDialogPropsV2'
import { isEqual } from 'lodash'
import { AssemblyHeaderRow, BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import React, { SVGAttributes, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BoMItemActivityDto,
  IssueCode,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import {
  RootAppState,
  useAppDispatch,
  useAppSelector,
} from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { ActivityResourceSelector } from './ActivityResourceSelector'

const useStyles = makeStyles({ name: 'WorkingStepResourceToolip' })(
  (theme: Theme) => ({
    tooltipRoot: {
      padding: theme.spacing(2, 2, 0),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
    },
    tooltipHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
    },
    workingStepNotAvailableError: {
      display: 'block',
      textAlign: 'left',
      width: '100%',
    },
    workingStepNotAllowedButton: {
      '&:after': {
        content: '" "',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '2px',
        backgroundColor: theme.palette.error.main,
      },
    },
    tooltipBuyerView: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1),
      color: theme.palette.text.primary,
    },
  })
)

/**
 * The apply to others are visible if the parent is an assemblyHeader and there are more than one partType in the filtered list
 * @param bomItemPointer
 * @returns
 */
const shallShowApplyToOthersSelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    const parent = bomItemSelector(state, bomItemPointer).parentBomItemPointer

    if (parent?.type === BomItemType.assemblyType) {
      const assemblyHeader = bomItemSelector(state, parent) as AssemblyHeaderRow
      return assemblyHeader.filteredPartTypePointers?.length > 1
    }

    return false
  }

type Props = {
  bomItemActivity: BoMItemActivityDto
  bomItemPointer: BomItemPointer
  attributes?: SVGAttributes<SVGElement>
}

const ActivityResourceTooltip = (props: Props) => {
  const getLocalizedWorkingStep = useLocalizedWorkingStepLabel()
  const { classes } = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { openDialog } = useDialogManager()

  const operationPending = useAppSelector(
    projectOperationIsPendingSelector(
      applyWorkingStepToAllParentFilteredRows.typePrefix.concat(
        '/',
        props.bomItemPointer.id
      )
    ),
    isEqual
  )

  const { workingStepNotAllowed } = useNotAllowedWorkingStep(
    props.bomItemPointer,
    props.bomItemActivity.primaryWorkingStep
  )

  const shallShowApplyToOthers = useAppSelector(
    shallShowApplyToOthersSelector(props.bomItemPointer)
  )

  const handleApplyToAll = useCallback(() => {
    dispatch(
      applyWorkingStepToAllParentFilteredRows({
        bomItemPointer: props.bomItemPointer,
        activityId: props.bomItemActivity.id,
        workingStep: props.bomItemActivity,
      })
    )
  }, [dispatch, props.bomItemActivity, props.bomItemPointer])

  const isBuyerView = useAppSelector(projectInBuyerViewSelector)
  const projectIsEditable = useAppSelector(
    projectSelectors.ProjectEditableStateSelector
  )

  const handleRemoveWorkingStep = useCallback(() => {
    if (projectIsEditable) {
      openDialog('ConfirmationDialog', {
        title: t(
          'project:remove-working-step',
          'remove working step {{workingStep}}',
          {
            replace: {
              workingStep: getLocalizedWorkingStep(
                props.bomItemActivity.primaryWorkingStep
              ),
            },
          }
        ),
        onConfirm: () => {
          const controller = new WorkingStepsController()

          try {
            controller.handleSaveAndRemoveWorkingSteps(
              [props.bomItemPointer],
              [],
              [props.bomItemActivity]
            )
          } catch (error) {
            ShowException('unable to remove workingstep', error)
          }
        },
      })
    }
  }, [
    getLocalizedWorkingStep,
    openDialog,
    projectIsEditable,
    props.bomItemActivity,
    props.bomItemPointer,
    t,
  ])

  const handleOpenWorkingStepDetails = useCallback(() => {
    if (
      props.bomItemActivity.primaryWorkingStep === WorkingStepType.Purchasing &&
      !isBuyerView
    ) {
      dispatch(newProjectActions.setBomItemInSearch(props.bomItemPointer))

      openDialog('PurchasingPartDialog', {
        bomItemPointer: props.bomItemPointer,
        isOpen: true,
      } as PurchasingPartDialogProps)

      return
    }

    switch (props.bomItemPointer.type) {
      case BomItemType.partInstance:
        return
      case BomItemType.project:
        dispatch(newProjectActions.setBomItemInSearch(props.bomItemPointer))
        openDialog('AdditionalWorkingSteps', {
          bomItemPointers: [props.bomItemPointer],
          activeWorkingStep: props.bomItemActivity,
        } as AdditionalWorkingStepsDialogPropsV2)
        break
      default: {
        dispatch(newProjectActions.setBomItemInSearch(props.bomItemPointer))
        openDialog('AdditionalWorkingSteps', {
          bomItemPointers: [props.bomItemPointer],
          activeWorkingStep: props.bomItemActivity,
        } as AdditionalWorkingStepsDialogPropsV2)
      }
    }
  }, [
    dispatch,
    isBuyerView,
    openDialog,
    props.bomItemActivity,
    props.bomItemPointer,
  ])

  const AnchorComponent = useMemo(() => {
    return (
      <Badge
        badgeContent={props.bomItemActivity.resource?.id ? 0 : 1}
        color="error"
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        overlap="circular"
        variant="dot"
        showZero={false}
      >
        <Button
          size="small"
          style={{ minWidth: 0 }}
          variant={'text'}
          color={workingStepNotAllowed ? 'secondary' : 'inherit'}
          className={
            workingStepNotAllowed ? classes.workingStepNotAllowedButton : ''
          }
          onClick={handleOpenWorkingStepDetails}
        >
          <WorkingStepIconSvg
            disableTitle
            workingStepType={props.bomItemActivity.primaryWorkingStep}
            attributes={{
              ...(props.attributes || {
                width: '26px',
              }),
            }}
          />
        </Button>
      </Badge>
    )
  }, [
    classes.workingStepNotAllowedButton,
    handleOpenWorkingStepDetails,
    props.attributes,
    props.bomItemActivity.primaryWorkingStep,
    props.bomItemActivity.resource?.id,
    workingStepNotAllowed,
  ])

  const TooltipComponent = useMemo(() => {
    return (
      <Paper elevation={4} className={classes.tooltipRoot}>
        <div className={classes.tooltipHeader}>
          <Typography variant="h6">
            {getLocalizedWorkingStep(props.bomItemActivity.primaryWorkingStep)}
            {workingStepNotAllowed && (
              <Typography
                variant="caption"
                color="error"
                className={classes.workingStepNotAvailableError}
              >
                {t(`errors:${IssueCode.WorkingStepNotAvailable}`)}
              </Typography>
            )}
          </Typography>
        </div>
        <ActivityResourceSelector
          bomItemActivity={props.bomItemActivity}
          bomItemPointers={[props.bomItemPointer]}
        />
        <BomItemActivityComment
          bomItemPointer={props.bomItemPointer}
          bomItemActivityId={props.bomItemActivity.id}
        />
        <DialogActions>
          {projectIsEditable && (
            <SolveWorkingStepNotAvailableButton
              bomItemPointer={props.bomItemPointer}
              workingStepType={props.bomItemActivity.primaryWorkingStep}
            />
          )}
          {projectIsEditable &&
            props.bomItemPointer.type !== BomItemType.partInstance && (
              <Button
                color="secondary"
                variant="text"
                onClick={handleRemoveWorkingStep}
              >
                {t(
                  'project:remove-working-step-tooltip',
                  'remove working step'
                )}
              </Button>
            )}

          {shallShowApplyToOthers && (
            <Button
              color="primary"
              variant="text"
              onClick={handleApplyToAll}
              startIcon={
                operationPending ? <CircularProgress size={20} /> : null
              }
            >
              {t(
                'project:apply-ws-to-other-parts',
                'add working step to other parts'
              )}
            </Button>
          )}
        </DialogActions>
      </Paper>
    )
  }, [
    classes.tooltipHeader,
    classes.tooltipRoot,
    classes.workingStepNotAvailableError,
    getLocalizedWorkingStep,
    handleApplyToAll,
    handleRemoveWorkingStep,
    operationPending,
    projectIsEditable,
    props.bomItemActivity,
    props.bomItemPointer,
    shallShowApplyToOthers,
    t,
    workingStepNotAllowed,
  ])

  if (isBuyerView) {
    return (
      <Tooltip
        title={
          workingStepNotAllowed ? (
            <Typography variant="body1">
              {t(
                'project:portal-not-allowed-working-step-remove',
                'working step not available in the self-service portal. click to remove or contact the supplier'
              )}
            </Typography>
          ) : (
            ''
          )
        }
        classes={{
          tooltip: classes.tooltipBuyerView,
        }}
      >
        <Button
          size="small"
          style={{
            minWidth: 0,
          }}
          variant={'text'}
          color={workingStepNotAllowed ? 'secondary' : 'inherit'}
          className={
            workingStepNotAllowed ? classes.workingStepNotAllowedButton : ''
          }
          onClick={() =>
            workingStepNotAllowed
              ? handleRemoveWorkingStep()
              : handleOpenWorkingStepDetails()
          }
        >
          <WorkingStepIconSvg
            disableTitle
            workingStepType={props.bomItemActivity.primaryWorkingStep}
            attributes={{
              ...(props.attributes || {
                width: '26px',
              }),
            }}
          />
        </Button>
      </Tooltip>
    )
  }

  return (
    <CustomPopper
      overflowElementId={props.bomItemPointer.id}
      AnchorComponent={AnchorComponent}
      TooltipComponent={TooltipComponent}
      tooltipProps={{
        disableFocusListener: true,
      }}
    />
  )
}

export default React.memo(ActivityResourceTooltip, isEqual)
