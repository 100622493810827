import { Button, Card, CardContent, Fade, Typography } from '@mui/material'
import { LoadingContainer } from 'components/Common/LoadingContainer'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { CustomPopper } from 'components/Popper/CustomPopper'
import { OrganizationProfileController } from 'controllers/Settings/OrganizationProfile/OrganizationProfileController'
import { OrganizationProfileOperations } from 'controllers/Settings/OrganizationProfile/OrganizationProfileOperations'
import { useAppController } from 'customHooks/useAppController'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import { ContactInfoLabel } from 'features/ProjectDetailsInfo/components/ContactInfoLabel'
import { isEqual } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useAppSelector } from 'store/configureStore'
import { formatName } from 'utils/nameFormatter'
import { projectSalesRepresentativeSelector } from '../store/selectors/projectSalesRepresentativeSelector'
import { projectSellingPartySelector } from '../store/selectors/projectSellingPartySelector'

// type Props = {
//   sellingParty: PartyDto
//   salesPerson?: SalesPersonDto
// }

type Props = {
  classes?: {
    container?: string
    img?: string
    supplierTextField?: string
  }
  disableClick?: boolean
}

export const ProjectSupplierLogo = (props: Props) => {
  const { controller, loading } = useAppController(
    () => new OrganizationProfileController()
  )

  const { openDialog } = useDialogManager()

  const handleClick = () => {
    openDialog('SalesRepresentative')
  }

  // const projectReferece = useAppSelector(projectReferenceSelector)
  const seller = useAppSelector(projectSellingPartySelector, isEqual)
  const salesPerson = useAppSelector(
    projectSalesRepresentativeSelector,
    isEqual
  )

  const isBuyer = useAppSelector(
    (state) => state.user?.organizationContext?.isBuyingParty
  )

  const [logo, setLogo] = React.useState(undefined)

  useEffect(() => {
    const getLogo = async () => {
      try {
        if (seller?.partyId) {
          const logo = await controller.getOrganizationLogo(seller?.partyId)
          setLogo(logo)
        }
      } catch (ex) {
        // console.warn(ex)
      }
    }

    getLogo()
  }, [controller, seller?.partyId])

  const TooltipComponent = useMemo(() => {
    return (
      <Card>
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <div>
            <LocalizedTypography
              translationKey={'common:supplier'}
              component="p"
              variant="caption"
              color="textSecondary"
            />
            <Typography variant="body1">{seller.legalName}</Typography>
          </div>
          {salesPerson && (
            <div>
              <LocalizedTypography
                translationKey={'common:sales-representative'}
                component="p"
                variant="caption"
                color="textSecondary"
              />
              <ContactInfoLabel
                personInfo={salesPerson}
                disableTitle
                emailTypographyProps={{
                  style: { display: 'block' },
                }}
              />
              {/* </a> */}
            </div>
          )}
        </CardContent>
      </Card>
    )
  }, [salesPerson, seller.legalName])

  if (!seller?.partyId) {
    return null
  }

  return (
    <div className={props.classes?.container}>
      <LoadingContainer
        loading={loading[OrganizationProfileOperations.GetLogo]}
        noMessage
        variant="loading-text"
      >
        <CustomPopper
          placement="right"
          AnchorComponent={
            <Fade in={true}>
              {logo ? (
                <img
                  src={`data:image/png;base64, ${logo}`}
                  alt={seller.legalName}
                  style={{
                    maxWidth: '140px',
                    maxHeight: '32px',
                    cursor:
                      !props.disableClick && !isBuyer ? 'pointer' : 'default',
                  }}
                  className={props.classes?.img}
                  loading="lazy"
                  onClick={() => {
                    !props.disableClick && !isBuyer && handleClick()
                  }}
                />
              ) : (
                <Button
                  onClick={handleClick}
                  color="inherit"
                  style={{
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    overflow: 'hidden',
                  }}
                >
                  <Typography variant="body2">{seller.legalName}</Typography>
                  <Typography variant="caption" color="textSecondary">
                    {formatName(salesPerson)}
                  </Typography>
                </Button>
              )}
            </Fade>
          }
          TooltipComponent={TooltipComponent}
        />
      </LoadingContainer>
    </div>
  )
}
