import { QuantityField } from 'components/Common/UnitEditor/QuantityField'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { QuantityDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'
import { projectSelectors } from '../store/selectors/projectSelectors'

type Props = {
  bomItemPointer: BomItemPointer
  activityId: string
  onChange: (value: QuantityDto) => void
  disabled?: boolean
}
export const BomItemMaterialDiscountPercentage = (props: Props) => {
  const { t } = useTranslation()

  const bomItemDiscount = useAppSelector(
    projectSelectors.bomItemMaterialFinancialSelector(
      props.bomItemPointer,
      props.activityId,
      'discountRatio'
    )
  ) as QuantityDto

  const [discountRatio, setDiscountRatio] = useState<QuantityDto>({
    value: bomItemDiscount?.value ?? 0,
    unit: '%',
    selectableAbbreviations: ['%'],
  })

  useEffect(() => {
    setDiscountRatio(bomItemDiscount)
  }, [bomItemDiscount])

  const [validations, setValidations] = useState<string | undefined>()

  const handleChange = (newValue: QuantityDto) => {
    if (newValue.value > 100) {
      setValidations(t('common:max-value', { maxValue: 100 }))
      return
    }

    if (newValue.value < 0) {
      setValidations(t('common:min-value', { minValue: 0 }))
      return
    }

    setValidations(undefined)
    setDiscountRatio(newValue)

    props.onChange(newValue)
  }

  return (
    <QuantityField
      name="discountRatio"
      label={`${t('common:discount')}`}
      value={discountRatio}
      onChange={handleChange}
      disabled={props.disabled}
      helperText={validations}
      error={Boolean(validations)}
    />
  )
}
