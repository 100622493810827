import { ChevronRightOutlined } from '@mui/icons-material'
import { IconButton, Tooltip, Typography } from '@mui/material'
import FilterAltOffOutlined from '_icons/filterAltOffOutlined.svg?react'
import FilterAltOutlined from '_icons/filterAltOutlined.svg?react'
import { useResourceIdFilter } from 'features/BillOfMaterials/BoMItemFilter/Components/WorkingStepsFilter/useResourceIdFilter'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { PriceSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { makeStyles } from 'tss-react/mui'
import { ProjectWorkingStepPriceSummary } from '../ProjectPriceSummaries/WorkingStepPriceSummary'

type Props = {
  resources: Record<string, PriceSummaryDto[]>
  forceOpen: boolean
}

const useStyles = makeStyles({ name: 'ProjectTotalsResourcesRow' })((theme) => {
  return {
    root: {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    header: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer',
    },
    filterButton: {
      marginLeft: 'auto',
      paddingRight: theme.spacing(1),
    },
  }
})

const ResourceRow = (props: {
  priceSummaries: PriceSummaryDto[]
  forceOpen: boolean
}) => {
  const { classes } = useStyles()

  const [open, setOpen] = useState(props.forceOpen)
  useEffect(() => {
    setOpen(props.forceOpen)
  }, [props.forceOpen])

  const { handleChangeFilter, filterIsActive, resourceIdFilter } =
    useResourceIdFilter()

  const resource = props.priceSummaries?.[0]?.workingStep?.resource

  if (
    resourceIdFilter.active &&
    !resourceIdFilter.filter.enabledFilters.includes(resource.id)
  ) {
    return null
  }

  if (props.priceSummaries.length === 0) {
    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.header} onClick={() => setOpen((x) => !x)}>
        <IconButton>
          <ChevronRightOutlined
            style={{
              transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 0.2s ease-in-out',
            }}
          />
        </IconButton>
        <Typography variant="body1">{resource?.name}</Typography>
        <div className={classes.filterButton}>
          <Tooltip
            title={t('project:filter-by-resource-id', 'filter by resource')}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                handleChangeFilter(resource.id)
                setOpen(true)
              }}
              style={{ alignSelf: 'center' }}
              sx={{
                '& svg': {
                  fill: (theme) => theme.palette.text.primary,
                  width: '1em',
                },
              }}
              size="small"
              color="primary"
            >
              {filterIsActive(resource.id) ? (
                <FilterAltOffOutlined />
              ) : (
                <FilterAltOutlined />
              )}
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {open && (
        <div>
          {props.priceSummaries.map((priceSummary) => (
            <div key={priceSummary.workingStep.key}>
              <ProjectWorkingStepPriceSummary priceSummary={priceSummary} />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export const ProjectTotalsResourcesRows = (props: Props) => {
  return (
    <div>
      {Object.entries(props.resources).map(([resourceId, priceSummaries]) => (
        <div key={resourceId}>
          <ResourceRow
            priceSummaries={priceSummaries}
            forceOpen={props.forceOpen}
          />
        </div>
      ))}
    </div>
  )
}
