import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { isEmpty } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BoMItemActivityDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { makeStyles } from 'tss-react/mui'
import { BomItemPrecisions } from '../BomItemPrecisions'
import { ActivityArticleSelectorButton } from './ActivityArticleSelectorButton'
import ActivityTooltip from './ActivityTooltip'
import { BomItemActivityKeywordsInput } from './BomItemActivityKeywordsInput'
import { useActivityArticleSelectorDialog } from './useActivityArticleSelectorDialog'

export type ActivityWithArticleProps = {
  bomItemPointer: BomItemPointer
  activity: BoMItemActivityDto
  keywordsInputVariant?: 'input' | 'button'
}

const useStyles = makeStyles({ name: 'AcitivityWithArticle' })((theme) => ({
  root: {
    width: '100%',
    paddingTop: '3px',
  },
  sameLine: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(1),
  },
}))

export function ActivityWithArticle(props: ActivityWithArticleProps) {
  const { classes } = useStyles()

  const { setArticleDialogOpen } = useActivityArticleSelectorDialog(
    props.bomItemPointer,
    props.activity?.primaryWorkingStep
  )

  if (!props.activity) return null

  return (
    <div className={classes.root}>
      <div className={classes.sameLine}>
        {props.activity?.primaryWorkingStep && (
          <ActivityTooltip
            bomItemPointer={props.bomItemPointer}
            bomItemActivity={props.activity}
            attributes={{
              width: '26px',
            }}
          />
        )}

        {props.keywordsInputVariant === 'button' ? (
          <ActivityArticleSelectorButton
            variant="materialView"
            activityId={props.activity.id}
            bomItemPointer={props.bomItemPointer}
            setArticleDialogOpen={setArticleDialogOpen}
            workingStepType={props.activity?.primaryWorkingStep}
          />
        ) : (
          <>
            {/* {props.activity?.keywords && ( */}
            <BomItemActivityKeywordsInput
              bomItemPointer={props.bomItemPointer}
              activityId={props.activity.id}
            />
            {/* )} */}
            {props.activity?.usesRawMaterial && (
              <BomItemPrecisions bomItemPointer={props.bomItemPointer} />
            )}
            <ActivityArticleSelectorButton
              bomItemPointer={props.bomItemPointer}
              activityId={props.activity?.id}
              workingStepType={props.activity?.primaryWorkingStep}
              variant="partTypeView"
              setArticleDialogOpen={setArticleDialogOpen}
            />
          </>
        )}
      </div>

    </div>
  )
}
