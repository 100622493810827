import { Box, Chip, ChipProps, Tooltip } from '@mui/material'
import { useKeywordsTranslation } from 'components/Common/ArticleSelector/useKeywordsTranslation'
import { useRALColorAndTranslation } from 'components/RALSelector/useRALColorData'
import { KeywordDto } from 'services/APIs/InternalAPI/internal-api.contracts'

type KeywordTagsListProps = {
  keywords: Record<string, KeywordDto[]>
  onClick?: (keywordDto: KeywordDto) => void
}

export function KeywordTagsList(props: KeywordTagsListProps) {
  const { translateKeyword } = useKeywordsTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {Object.values(props.keywords)
        .flat()
        .map((keyword) => (
          <KeywordTag
            key={keyword.originalKeyword}
            token={keyword}
            translateFn={translateKeyword}
            onClick={props.onClick}
          />
        ))}
    </Box>
  )
}

type Props = {
  token: KeywordDto
  translateFn: (token: KeywordDto) => string
  onClick?: (keywordDto: KeywordDto) => void
} & ChipProps

export function KeywordTag(props: Props) {
  const colorInfo = useRALColorAndTranslation(props.token.originalKeyword)
  const { token, translateFn, onClick, ...chipProps } = props

  const TagLabel = () => {
    return (
      <Box
        component="span"
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {translateFn(token)}
      </Box>
    )
  }

  return (
    <Tooltip
      key={token.originalKeyword}
      title={colorInfo?.translatedName || ''}
      placement="top"
    >
      <Chip
        label={<TagLabel />}
        variant="outlined"
        size="small"
        sx={{
          margin: '0px 2px 4px 0',
          backgroundColor: colorInfo?.color.hex,
          color: (theme) =>
            colorInfo && theme.palette.getContrastText(colorInfo?.color.hex),
          '& :hover': {
            color: (theme) => props.onClick && theme.palette.text.primary,
          },
          ...(chipProps.sx || {}),
        }}
        onClick={props.onClick && (() => onClick?.(token))}
        {...chipProps}
      />
    </Tooltip>
  )
}
