/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui'

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useRoutingHeaderStyles = makeStyles()((theme) => ({
  routingHeaderCard: {
    marginBottom: theme.spacing(2),
  },
  routingHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 2,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  routingData: {
    padding: theme.spacing(1, 3, 0.5, 1),
    display: 'flex',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
  },
  routingRow: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
  },
  routingSteps: {
    display: 'flex',
    marginLeft: theme.spacing(2),
    columnGap: theme.spacing(3),
    rowGap: theme.spacing(7),
    width: '100% ',
    flexWrap: 'wrap',
  },
  filterCount: {
    paddingLeft: theme.spacing(2),
  },
  workingStepWithResource: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: theme.spacing(25),
    justifyContent: 'space-between',

    '& .resource-selector-input': {
      '& .MuiTypography-root': {
        whiteSpace: 'pre-line',
      },
      fontSize: theme.typography.caption.fontSize,
      textAlign: 'center',
      whiteSpace: 'pre-line',
    },
    '& .working-step-icon': {
      width: theme.spacing(5),
    },
  },
}))
