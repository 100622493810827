import { useAuth0 } from '@auth0/auth0-react'
import { Box, Container, Tab, Tabs } from '@mui/material'
import KanbanSvg from '_icons/ui/kanban.svg?react'
import ProjectsSvg from '_icons/ui/my-projects.svg?react'
import { HorizontalScrollContainer } from 'components/Common/HorizontalScrollContainer'
import { ProjectsListPage } from 'components/ProjectsPage/ProjectListPage'
import { ProjectsKanban } from 'components/ProjectsPage/kanban/ProjectsKanban'
import { useClientStorage } from 'customHooks/useClientStorage'
import { EnterpriseModule } from 'features/AppModules/EstimationFactoryModule'
import { useAppModule } from 'features/AppModules/useAppModule'
import { fullUnloadActiveProject } from 'features/BillOfMaterials/store/asyncActions/fullUnloadActiveProject'
import { useAppRunningContext } from 'features/EmbeddedRh24/useAppRunningContext'
import { MixPanelDashboardDrawer } from 'features/MixPanelDashboards/MixPanelDashboardsDrawer'
import React, { HTMLAttributes, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppModule } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { useIsFirstRenderRef } from 'utils/useIsFirstRender'
import { usePortalHomeStyles } from './PortalHome.styles'

const TabPanel = (
  props: HTMLAttributes<HTMLDivElement> & {
    children: React.ReactChild
    tab: string
    currentTab: string
  }
) => {
  const { children, currentTab, tab, ...other } = props

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (!loaded && currentTab === tab) {
      requestIdleCallback(() => setLoaded(true))
    }
  }, [loaded, currentTab, tab])

  if (tab !== currentTab) {
    return null
  }

  return (
    <div
      role="tabpanel"
      hidden={tab !== currentTab}
      id={`home-tabpanel-${tab}`}
      aria-labelledby={`home-tabpanel-${tab}`}
      {...other}
    >
      {loaded ? children : null}
    </div>
  )
}

export const PortalHome = () => {
  const [currentTab, setCurrentTab] = useClientStorage(
    'home-page:current-tab',
    'project-list'
  )
  const { classes } = usePortalHomeStyles()
  const { t } = useTranslation('common')

  const isFirstRender = useIsFirstRenderRef()

  const handleTabChange = (_event, newValue: string) => {
    if (newValue === null || newValue === undefined) {
      return
    }

    setCurrentTab(newValue)
  }

  const { withinIframe } = useAppRunningContext()

  const { isAuthenticated } = useAuth0()
  const userProfile = useAppSelector((state) => state.user.profile)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fullUnloadActiveProject())
  }, [dispatch])

  const hasEnterpriseModule = useAppModule(AppModule.Enterprise)

  if (!isAuthenticated || !userProfile) {
    return null
  }

  return (
    <>
      <Box style={{ display: 'flex' }}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          variant="fullWidth"
          style={{
            flexGrow: 1,
          }}
          centered
        >
          <Tab
            icon={
              <ProjectsSvg
                className="MuiSvgIcon-root"
                style={{ width: '1.5rem', height: '1.5rem' }}
              />
            }
            label={t('projects')}
            value="project-list"
          />
          {hasEnterpriseModule && (
            <Tab
              icon={
                <KanbanSvg
                  className="MuiSvgIcon-root"
                  style={{ width: '1.5rem', height: '1.5rem' }}
                />
              }
              label={t('kanban')}
              value="kanban"
            />
          )}
        </Tabs>
        {withinIframe || !hasEnterpriseModule ? null : (
          <MixPanelDashboardDrawer />
        )}
      </Box>
      <TabPanel currentTab={currentTab} tab={'project-list'}>
        <Container maxWidth="xxxxl">
          <ProjectsListPage isFirstRender={isFirstRender} />
        </Container>
      </TabPanel>
      <EnterpriseModule>
        <TabPanel currentTab={currentTab} tab={'kanban'}>
          <HorizontalScrollContainer className={classes.kanbanContainer}>
            <ProjectsKanban isFirstRender={isFirstRender} />
          </HorizontalScrollContainer>
        </TabPanel>
      </EnterpriseModule>
    </>
  )
}
