import MaterialTable from '@material-table/core'
import { useMaterialTableLocalization } from 'customHooks/useMaterialTableLocalization'
import { BomItemDimensionSummary } from 'features/BillOfMaterials/PartType/PartTypeDimensionSummary'
import { RowThumbnailButton } from 'features/BillOfMaterials/components/BomItemThumbnailButton'
import _ from 'lodash'
import { BomItemType } from 'model/Project/BoMItemRow'
import React, { useState } from 'react'
import { RowDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { HashGroup } from './AssemblySimplificationDialog'

export const HashGroupTable = (props: {
  rowDtos: RowDto[]
  selectedAssemblyId: string
  hashGroupKey: string
  setSelectedRows: React.Dispatch<React.SetStateAction<HashGroup>>
  selectedRows: HashGroup
  isClosing: boolean
}) => {
  const localization = useMaterialTableLocalization()
  const [rowDtosWithTableData, setRowDtosWithTableData] = useState<
    Array<RowDto & { tableData: { checked: boolean } }>
  >([])

  React.useEffect(() => {
    if (!props.selectedAssemblyId) {
      setRowDtosWithTableData([])
    } else {
      setRowDtosWithTableData(
        props.rowDtos.map((row) => {
          return {
            ...row,
            tableData: {
              checked:
                !_.isEmpty(props.selectedRows) &&
                props.selectedRows[props.selectedAssemblyId] &&
                props.selectedRows[props.selectedAssemblyId][
                  props.hashGroupKey
                ]?.partTypeIds?.includes(row.id),
            },
          }
        })
      )
    }
  }, [
    props.hashGroupKey,
    props.rowDtos,
    props.selectedAssemblyId,
    props.selectedRows,
  ])

  return (
    <>
      <MaterialTable
        options={{
          emptyRowsWhenPaging: false,
          paging: false,
          search: false,
          showSelectAllCheckbox: true,
          selection: true,
          toolbar: false,
          padding: 'dense',
          tableLayout: 'auto',
        }}
        localization={localization}
        columns={[
          {
            title: 'row number',
            field: 'rowNumber',
            width: '5%',
          },
          {
            title: 'thumbnail',
            render: (partType) => (
              <RowThumbnailButton
                bomItemPointer={{
                  id: partType.id,
                  type: BomItemType.partType,
                }}
                disableClick
                showTooltip
              />

              //   <img
              //     src={projectImageUrl(
              //       organizationId,
              //       projectId,
              //       partType.thumbnail3d
              //     )}
              //   />
            ),
            sorting: false,
            width: '10%',
          },
          {
            title: 'name',
            field: 'name',
            width: '60%',
          },
          {
            title: 'dimensions',
            field: 'dimensionsSummary',
            width: '55%',
            // cellStyle: {
            //   width: '350',
            //   minWidth: '350px',
            //   maxWidth: '350px',
            // },
            headerStyle: {
              // width: '10px',
              // minWidth: '10px',
              // maxWidth: '10px',
            },
            render: (data) => {
              return (
                <span style={{ textWrap: 'nowrap', textAlign: 'right' }}>
                  <BomItemDimensionSummary
                    bomItemPointer={{
                      id: data.partTypeId,
                      type: BomItemType.partType,
                    }}
                    rootStyle={{ justifyContent: 'flex-end' }}
                  />
                </span>
              )
            },
          },
          // {
          //     field: 'id'
          // }
        ]}
        data={rowDtosWithTableData}
        onSelectionChange={(rows) => {
          setTimeout(() => {
            props.setSelectedRows((current) => {
              const newSelectedRows = { ...current }

              newSelectedRows[props.selectedAssemblyId] = {
                ...newSelectedRows[props.selectedAssemblyId],
                [props.hashGroupKey]: {
                  partTypeIds: rows.map((x) => x.id),
                },
              }

              return newSelectedRows
            })
          })
        }}
      />
    </>
  )
}
