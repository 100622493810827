/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseController } from 'controllers/BaseController'
import { fetchProject } from 'features/BillOfMaterials/store/asyncActions/fetchProject'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import { isEqual } from 'lodash'
import {
  PriceSummaryDto,
  QuantityDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ProjectsAPI } from 'controllers/Projects/ProjectsAPI'
import { TelemetryService } from 'services/Telemetry'
import { store } from 'store/configureStore'
import { getPriceSummaryKey } from 'store/Project/ProjectStateUtils'
import { ProjectState } from 'store/Project/ProjectTypes'

const notEqual = (a, b) => !isEqual(a, b)

class PriceSummariesController extends BaseController<ProjectsAPI> {
  constructor(partyId: string, private projectId: string) {
    super((onRequestChanged) => {
      return new ProjectsAPI(partyId, onRequestChanged)
    })
  }

  public SaveArticleIdSurchargeRatio = async (
    articleId: string,
    surchargeRatio: QuantityDto
  ) => {
    try {
      await this.api.SetArticleSurchargeRatio(
        this.projectId,
        articleId,
        surchargeRatio
      )
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public SaveArticleIdSurchargeValue = async (
    articleId: string,
    surchargeValue: QuantityDto
  ) => {
    try {
      await this.api.SetArticleSurchargeValue(
        this.projectId,
        articleId,
        surchargeValue
      )
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public SaveArticleIdDiscountRatio = async (
    articleId: string,
    discountRatio: QuantityDto
  ) => {
    try {
      await this.api.SetArticleDiscountRatio(
        this.projectId,
        articleId,
        discountRatio
      )
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async SavePriceSummary(
    changedData: Partial<PriceSummaryDto>,
    initialData: PriceSummaryDto
  ) {
    try {
      store.dispatch(
        newProjectActions.setProjectStateProperties({
          priceSummaries: {
            [getPriceSummaryKey(changedData)]: {
              ...changedData,
              totalSalesPrice: { value: null },
            },
          },
        })
      )

      const apiCalls = this.getUpdatesCalls(changedData, initialData)
      await Promise.all(apiCalls.map((updateAction) => updateAction()))
    } catch (err) {
      // this.dispatch(oldProjectActions.ReplacePriceSummary(initialData))
      TelemetryService.getInstance().logError(err)
      throw err
    }
  }

  private getUpdatesCalls(
    changedData: Partial<PriceSummaryDto>,
    initialData: PriceSummaryDto
  ) {
    const isMaterial = initialData?.isMaterial
    const isPurchasePart = initialData.isPurchasePart
    const isPurchasePartWithoutArticle =
      initialData?.isPurchasePartWithoutArticle
    const isWorkingStep = initialData?.isWorkingStep

    const updates = []

    if (isMaterial || isPurchasePart || isPurchasePartWithoutArticle) {
      const materialAPICall = (method, changedValue) => async () => {
        return await method(
          this.projectId,
          initialData.materialId,
          changedValue
        )
      }

      if (notEqual(changedData.rate, initialData.rate)) {
        updates.push(
          materialAPICall(this.api.SetArticleCostPrice, changedData.rate)
        )
      }

      if (notEqual(changedData.surchargeRatio, initialData.surchargeRatio)) {
        updates.push(
          materialAPICall(
            this.api.SetArticleSurchargeRatio,
            changedData.surchargeRatio
          )
        )
      }
      if (notEqual(changedData.surchargeValue, initialData.surchargeValue)) {
        updates.push(
          materialAPICall(
            this.api.SetArticleSurchargeValue,
            changedData.surchargeValue
          )
        )
      }

      if (notEqual(changedData.discountRatio, initialData.discountRatio)) {
        updates.push(
          materialAPICall(
            this.api.SetArticleDiscountRatio,
            changedData.discountRatio
          )
        )
      }
      if (notEqual(changedData.discountValue, initialData.discountValue)) {
        updates.push(
          materialAPICall(
            this.api.SetArticleDiscountValue,
            changedData.discountValue
          )
        )
      }
    }

    if (isWorkingStep) {
      const workingStepCall = (method, changedValue) => async () =>
        await method(
          this.projectId,
          initialData.workingStep.resource.id,
          initialData.workingStep.primaryWorkingStep,
          initialData.workingStep.secondaryWorkingStep,
          changedValue
        )

      if (notEqual(changedData.rate, initialData.rate)) {
        updates.push(
          workingStepCall(this.api.SetResourceCostPrice, changedData.rate)
        )
      }

      if (notEqual(changedData.surchargeRatio, initialData.surchargeRatio)) {
        updates.push(
          workingStepCall(
            this.api.SetResourceSurchargeRatio,
            changedData.surchargeRatio
          )
        )
      }
      if (notEqual(changedData.surchargeValue, initialData.surchargeValue)) {
        updates.push(
          workingStepCall(
            this.api.SetResourceSurchargeValue,
            changedData.surchargeValue
          )
        )
      }

      if (notEqual(changedData.discountRatio, initialData.discountRatio)) {
        updates.push(
          workingStepCall(
            this.api.SetResourceDiscountRatio,
            changedData.discountRatio
          )
        )
      }
      if (notEqual(changedData.discountValue, initialData.discountValue)) {
        updates.push(
          workingStepCall(
            this.api.SetResourceDiscountValue,
            changedData.discountValue
          )
        )
      }
    }
    return updates
  }

  public async ResetToDefault(
    valueType: 'costPrice' | 'discount' | 'surcharge',
    data: PriceSummaryDto
  ) {
    try {
      const apiCalls = this.getResetToDefaultCalls(valueType, data)

      store.dispatch(
        newProjectActions.setProjectStateProperties({
          priceSummaries: {
            [getPriceSummaryKey(data)]: {
              [valueType === 'surcharge' ? 'surchargeValue' : 'discountValue']:
                {
                  value: null,
                },
              totalSalesPrice: { value: null },
            },
          },
        } as ProjectState)
      )

      await Promise.all(apiCalls.map((updateAction) => updateAction()))
    } catch (err) {
      TelemetryService.getInstance().logError(err)
      store.dispatch(fetchProject({ projectId: this.projectId }))
      throw err
    }
  }

  private getResetToDefaultCalls(
    valueType: 'costPrice' | 'discount' | 'surcharge',
    data: PriceSummaryDto
  ) {
    const isMaterial = data?.isMaterial
    const isPurchasePart = data.isPurchasePart
    const isWorkingStep = data?.isWorkingStep

    const updates = []

    if (isMaterial || isPurchasePart) {
      const materialAPICall = (method) => async () => {
        return await method(this.projectId, data.materialId)
      }

      if (valueType === 'costPrice') {
        updates.push(materialAPICall(this.api.DeleteArticleCostPrice))
      }
      if (valueType === 'discount') {
        updates.push(materialAPICall(this.api.DeleteArticleDiscount))
      }
      if (valueType === 'surcharge') {
        updates.push(materialAPICall(this.api.DeleteArticleSurcharge))
      }
    }

    if (isWorkingStep) {
      const workingStepCall = (method) => async () =>
        await method(
          this.projectId,
          data.workingStep.resource.id,
          data.workingStep
        )

      if (valueType === 'costPrice') {
        updates.push(workingStepCall(this.api.DeleteResourceCostPrice))
      }
      if (valueType === 'discount') {
        updates.push(workingStepCall(this.api.DeleteResourceDiscount))
      }
      if (valueType === 'surcharge') {
        updates.push(workingStepCall(this.api.DeleteResourceSurcharge))
      }
    }

    return updates
  }
}

export { PriceSummariesController }
